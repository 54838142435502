import { AnyAction, createSlice } from "@reduxjs/toolkit";
import { IGetJobState } from "./interface";
import { doGetJobAction } from "./get.actions";
import { ReduxStatus } from "types/types";

const initialState: IGetJobState = {
  status: ReduxStatus.empty,
  response: {},
  error: {},
};

export const getJob = createSlice({
  name: "job/get",
  initialState,
  reducers: {
    increment: () => {
      // state.candidateList = [1, 2];
      // ✅ CORRECT: logs a plain JS copy of the current data
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(doGetJobAction.pending, (state: IGetJobState) => {
        state.status = ReduxStatus.pending;
      })
      .addCase(
        doGetJobAction.fulfilled,
        (state: IGetJobState, action: AnyAction) => {
          state.status = ReduxStatus.fulfilled;
          state.error = {};
          state.response = action.payload;
        }
      )
      .addCase(
        doGetJobAction.rejected,
        (state: IGetJobState, action: AnyAction) => {
          state.status = ReduxStatus.rejected;
          state.response = {};
          state.error = action.error;
        }
      );
  },
});

export const { increment } = getJob.actions;
export default getJob.reducer;
