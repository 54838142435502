import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ICreateCompanyState } from "./interface";
import { doCreateCompanyAction } from "./create.actions";
import { ICompany, IRoleType, IWorkType, ReduxStatus } from "types/types";

const initialCompany: ICompany = {
  billingAddress: "",
  billingEmailAddress: "",
  company_id: "",
  objectID: "",
  created_at: null,
  industries: [],
  jobs_without_criteria: [],
  location: "",
  name: "",
  original_avatar_url: "",
  role: IRoleType.RECRUITER,
  thumbnail_avatar_url: "",
  total_candidates: 0,
  total_jobs: 0,
  uid: "",
  vatID: "",
  work_type: IWorkType.OFFICE,
  recruiters_attached: [],
};

const initialState: ICreateCompanyState = {
  status: ReduxStatus.empty,
  response: initialCompany,
  error: {},
};

export const createCompany = createSlice({
  name: "company/create",
  initialState,
  reducers: {
    increment: () => {
      // state.candidateList = [1, 2];
      // ✅ CORRECT: logs a plain JS copy of the current data
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(doCreateCompanyAction.pending, (state: ICreateCompanyState) => {
        state.status = ReduxStatus.pending;
      })
      .addCase(
        doCreateCompanyAction.fulfilled,
        (state: ICreateCompanyState, action: PayloadAction<ICompany>) => {
          state.status = ReduxStatus.fulfilled;
          state.error = {};
          state.response = action.payload;
        }
      )
      .addCase(
        doCreateCompanyAction.rejected,
        (state: ICreateCompanyState, action: PayloadAction<unknown>) => {
          state.status = ReduxStatus.rejected;
          state.response = initialCompany;
          state.error = action.payload;
        }
      );
  },
});

export const { increment } = createCompany.actions;
export default createCompany.reducer;
