import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IGetCandidatesPayload, IGetCandidatesState } from "./interface";
import { doGetCandidatesAction } from "./get.actions";
import { IInterviewStatus, ReduxStatus } from "types/types";

const initialState: IGetCandidatesState = {
  status: ReduxStatus.empty,
  response: { job_id: "" },
  error: {},
};

const sortCandidates = (payload: IGetCandidatesPayload) => {
  const unsorted_candidates = payload.candidates || [];

  const archived = unsorted_candidates.filter(
    (candidate) => candidate.interview_status === IInterviewStatus.ARCHIVED
  );
  const unarchived = unsorted_candidates.filter(
    (candidate) => candidate.interview_status !== IInterviewStatus.ARCHIVED
  );

  const candidates = unarchived.concat(archived);
  const newPayload: IGetCandidatesPayload = {
    ...payload,
    candidates,
  };
  return newPayload;
};

export const getCandidates = createSlice({
  name: "candidates/get",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(doGetCandidatesAction.pending, (state: IGetCandidatesState) => {
        state.status = ReduxStatus.pending;
      })
      .addCase(
        doGetCandidatesAction.fulfilled,
        (
          state: IGetCandidatesState,
          action: PayloadAction<IGetCandidatesPayload>
        ) => {
          state.status = ReduxStatus.fulfilled;
          state.error = {};
          state.response = sortCandidates(action.payload);
        }
      )
      .addCase(
        doGetCandidatesAction.rejected,
        (state: IGetCandidatesState, action: PayloadAction<unknown>) => {
          state.status = ReduxStatus.rejected;
          state.response = { job_id: "" };
          state.error = action.payload;
        }
      );
  },
});

export default getCandidates.reducer;
