import * as React from "react";
import { ICompaniesState } from "./interface";
import { ICompany } from "types/types";
import InfiniteScroll from "react-infinite-scroll-component";
import { Box } from "@mui/material";
import CardWithCompany from "./card-with-company";
import { styled } from "@mui/material/styles";
import Loader from "assets/images/spinner.svg";
import "./styles.scss";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "redux/store";
import { _uniqBy } from "utils/lodash";
import { toast } from "react-toastify";
import ToastMessage from "components/toast-message";
import { doUpdateCompanyAction } from "redux/services/company/update/update-company.actions";
import { restructureCompanies, updateCompanies } from "redux/reselect";
import CreateCompanyCard from "./create-company-card";
import clientSearch from "utils/algolia";
import { doGetCompaniesAction } from "redux/services/companies/get/get.actions";
import { arrayRemove, arrayUnion } from "firebase/firestore";

const Companies = styled(Box)`
  column-gap: 1rem;
  row-gap: 2rem;
  grid-auto-rows: minmax(min-content, max-content);
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(335px, 1fr));
`;

interface IProps {
  companiesState: ICompaniesState;
  setCompaniesState: Function;
}

const CompaniesContainer = ({ setCompaniesState }: IProps) => {
  const dispatch = useAppDispatch();
  const jobsGetState = useSelector(
    (reduxState: RootState) => reduxState.jobsGet.response
  );
  const userState = useSelector(
    (reduxState: RootState) => reduxState.user.response
  );
  const companiesGetState = useSelector(
    (reduxState: RootState) => reduxState.getCompanies.response
  );

  const fetchCompanies = async (initialFetch = false) => {
    const searchIndex = clientSearch.initIndex("v2_company");
    let filters = "";

    if (companiesGetState?.companyStatus === "MY_COMPANIES") {
      filters += `recruiters_attached:'${userState?.uid}'`;
    }

    if (companiesGetState?.period?.start && companiesGetState?.period?.end) {
      const start = Math.round(
        new Date(companiesGetState.period?.start).getTime() / 1000
      );
      const end = Math.round(
        new Date(companiesGetState.period?.end).getTime() / 1000
      );

      if (companiesGetState?.companyStatus === "MY_COMPANIES") {
        filters += ` AND `;
      }
      filters += `created_at._seconds:${start} TO ${end}`;
    }

    const searchQuery = companiesGetState?.searchQuery
      ? companiesGetState?.searchQuery
      : "";

    const companyList: ICompany[] = [];

    try {
      if (initialFetch) {
        await clientSearch.clearCache();
      }

      const pageToFetch = initialFetch ? 0 : companiesGetState.page;
      const results = await searchIndex.search(searchQuery, {
        filters,
        page: pageToFetch,
      });

      const companies = results.hits;
      const companies_result = [...companies] as unknown as ICompany[];

      companies_result.forEach(async (companyData) => {
        companyList.push({
          company_id: companyData.objectID,
          objectID: companyData.objectID,
          created_at: companyData.created_at
            ? new Date(companyData.created_at._seconds * 1000).toISOString()
            : undefined,
          industries: companyData.industries || [],
          location: companyData.location || "",
          name: companyData.name || "",
          role: companyData.role,
          uid: companyData.uid || "",
          work_type: companyData.work_type || "",
          original_avatar_url: companyData.original_avatar_url || "",
          thumbnail_avatar_url: companyData.thumbnail_avatar_url || "",
          recruiters_attached: companyData.recruiters_attached || [],
          total_candidates: companyData.total_candidates || 0,
          total_jobs: companyData.total_jobs || 0,
          billingAddress: companyData.billingAddress || "",
          billingEmailAddress: companyData.billingEmailAddress || "",
          vatID: companyData.vatID || "",
          jobs_without_criteria: companyData.jobs_without_criteria,
        });
      });

      const list = results.page === 0 ? [] : [...companiesGetState.companies];
      if (companiesGetState.companyAdded) {
        list.unshift(companiesGetState.companyAdded);
      }
      const new_list = list.concat(companyList);
      let unique_list = _uniqBy(new_list, "company_id");

      if (companiesGetState?.companyUpdated) {
        const updatedCompany = companiesGetState?.companyUpdated;
        const isInCompanyList = unique_list.some(
          (company) => company.company_id === updatedCompany.company_id
        );

        if (isInCompanyList) {
          unique_list = updateCompanies({
            updatedCompany,
            companyList: unique_list,
          });
        }
      }

      const hasMore = results.page < results.nbPages - 1;

      setCompaniesState((st: ICompaniesState) => ({
        ...st,
        hasMore,
        companies_list: unique_list,
        page: results.page + 1,
        totalPages: results.nbPages,
        totalCompanies: results.nbHits,
      }));

      await dispatch(
        doGetCompaniesAction({
          ...companiesGetState,
          page: results?.page + 1,
          totalPages: results.nbPages,
          totalCompanies: results.nbHits,
          hasMore,
          companyAdded: undefined,
          companyUpdated: undefined,
          companies: unique_list,
        })
      );

      return null;
    } catch (error) {
      toast(
        <ToastMessage
          title="Companies"
          body="An error occurred while fetching companies"
        />,
        {
          type: "error",
          position: "top-right",
        }
      );
    }
  };

  React.useEffect(() => {
    fetchCompanies(true);
  }, [
    companiesGetState?.companyStatus,
    companiesGetState?.searchQuery,
    companiesGetState?.period?.start,
    companiesGetState?.period?.end,
  ]);

  const updateCompany = async (updatedCompany: ICompany) => {
    const companies_list = companiesGetState.companies.map((company) => {
      if (company.company_id === updatedCompany.company_id) {
        return updatedCompany;
      }
      return company;
    });
    const restructured_list = restructureCompanies({
      companies_list,
      companyStatus: companiesGetState.companyStatus,
      uid: userState.uid,
    });

    await dispatch(
      doGetCompaniesAction({
        ...companiesGetState,
        companies: restructured_list,
      })
    );

    setCompaniesState((st: ICompaniesState) => ({
      ...st,
      companies_list: restructured_list,
    }));
  };

  const handleUpdateMyCompany = async ({ company }: { company: ICompany }) => {
    if (!company?.company_id || !userState.uid) {
      toast(<ToastMessage title="Info" body="Missing information" />, {
        type: "error",
        position: "top-right",
      });
      return;
    }

    const isMyCompany = company.recruiters_attached?.includes(userState.uid);
    const toastMsg = isMyCompany
      ? "Removed from my companies"
      : "Added to my companies";
    let recruiters_attached = company.recruiters_attached || [];
    let recruiters_attached_update;

    if (isMyCompany) {
      recruiters_attached = recruiters_attached.filter(
        (uid) => uid !== userState.uid
      );
      recruiters_attached_update = arrayRemove(userState.uid);
    } else {
      recruiters_attached = [...recruiters_attached, userState.uid];
      recruiters_attached_update = arrayUnion(userState.uid);
    }

    const updatedCompany: ICompany = {
      ...company,
      recruiters_attached,
    };

    await dispatch(
      doUpdateCompanyAction({
        company_id: company.company_id,
        company: {
          recruiters_attached: recruiters_attached_update,
        },
      })
    );

    updateCompany(updatedCompany);

    toast(<ToastMessage title="My Companies" body={toastMsg} />, {
      type: "success",
      position: "top-right",
    });
  };

  return (
    <Box
      id="scrollableDiv"
      sx={[
        { overflow: "auto" },
        (theme) => ({
          [theme.breakpoints.down("xl")]: {
            height: "66vh",
          },
          [theme.breakpoints.up("xl")]: {
            height: "75vh",
          },
        }),
      ]}
    >
      <InfiniteScroll
        dataLength={companiesGetState?.companies?.length || 0}
        next={fetchCompanies}
        hasMore={companiesGetState?.hasMore}
        scrollableTarget="scrollableDiv"
        loader={
          companiesGetState.hasMore && (
            <div className="companies-loader__container">
              <div className="companies-loader-item">
                <img
                  className="companies-loader__spinner"
                  src={Loader}
                  alt="spinner"
                />
                <div className="companies-loader__text">
                  {!companiesGetState?.companies?.length
                    ? "Loading..."
                    : "Load more"}
                </div>
              </div>
            </div>
          )
        }
      >
        <Companies>
          <CreateCompanyCard />
          {companiesGetState.companies.map((company: ICompany) => {
            return (
              <CardWithCompany
                key={company.company_id}
                company={company}
                uid={userState.uid}
                handleUpdateMyCompany={handleUpdateMyCompany}
                jobState={jobsGetState}
              />
            );
          })}
        </Companies>
      </InfiniteScroll>
    </Box>
  );
};

export default CompaniesContainer;
