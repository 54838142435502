import React, { useEffect } from "react";
import Text from "components/shared/text/text";
import { Avatar, Box, Divider, Stack, styled, useTheme } from "@mui/material";
import SharedButton from "components/shared/button/button";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../redux/store";
import { profileSchema } from "utils/form-schema";
import { toast } from "react-toastify";
import ToastMessage from "components/toast-message";
import SharedInput from "components/shared/input/input";
import PrivateLayout from "layouts/drawer";
import { ITheme } from "theme";
import { _get } from "utils/lodash";
import { auth } from "utils/firebase";
import { doUpdateUserAction } from "redux/services/user/update/update.actions";
import { scrollSectionIntoView } from "utils/helpers";

interface IState {
  email: string;
  newPassword?: string;
  confirmNewPassword?: string;
  oldPassword: string;
  firstName: string;
  lastName: string;
  file?: File[];
}

interface ErrorProps {
  error?: number;
}

export const FormLabel = styled("span")<ErrorProps>`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: -0.01em;
  color: ${({ theme, error }) =>
    error
      ? _get(theme, "palette.colors.red")
      : _get(theme, "custom.colors.orange")};
`;

const Container = styled(Box)`
  display: flex;
  height: 88%;
  width: 71vw;
  position: fixed;
  background: ${({ theme }) => theme.palette.grey[900]};
  padding-bottom: 50px;
  margin-top: 40px;
`;

const Settings: React.FC = () => {
  const [currentSection, setCurrentSection] = React.useState(
    "Profile Information"
  );

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<IState>({
    resolver: yupResolver(profileSchema),
  });
  const theme: ITheme = useTheme();
  const dispatch = useAppDispatch();
  const _auth = auth.getAuth();
  const _user = _auth.currentUser as auth.User;
  const userState = useSelector(
    (reduxState: RootState) => reduxState.user.response
  );

  const stringAvatar = (name: string) => {
    return {
      children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
    };
  };

  const onSubmit = async (data: IState) => {
    if (data.newPassword) {
      if (!data.oldPassword) {
        return toast(
          <ToastMessage
            title="Password Error"
            body="Please add Old password to update your password"
          />,
          {
            type: "error",
            position: "top-right",
          }
        );
      }

      const credentials = auth.EmailAuthProvider.credential(
        data.email,
        data.oldPassword
      );

      try {
        await auth.reauthenticateWithCredential(_user, credentials);
        await auth.updatePassword(_user, data.newPassword);
      } catch (error: any) {
        const body =
          error?.code === "auth/wrong-password"
            ? "Invalid old password"
            : "There was an error updating the information";
        return toast(<ToastMessage title="Profile Information" body={body} />, {
          type: "error",
          position: "top-right",
        });
      }
    }

    const updateResponse = await dispatch(
      doUpdateUserAction({
        uid: userState.uid,
        user: {
          firstName: data.firstName,
          lastName: data.lastName,
        },
      })
    );

    if (updateResponse.meta.requestStatus === "rejected") {
      return toast(
        <ToastMessage
          title="Profile Information"
          body="There was an error updating the information"
        />,
        {
          type: "error",
          position: "top-right",
        }
      );
    }

    setValue("oldPassword", "");
    setValue("newPassword", "");
    setValue("confirmNewPassword", "");
    return toast(
      <ToastMessage
        title="Profile Information"
        body="Profile information has been updated"
      />,
      {
        type: "success",
        position: "top-right",
      }
    );
  };

  const viewSection = (el: string) => {
    const elem = el
      .replace(/[^a-zA-Z0-9]/g, " ")
      .replace(/\s+/g, "-")
      .toLocaleLowerCase();
    scrollSectionIntoView(`#${elem}`);
    setCurrentSection(el);
  };

  useEffect(() => {
    setValue("firstName", userState.firstName);
    setValue("lastName", userState.lastName);
    setValue("email", userState.email);
  }, [userState?.firstName, userState?.lastName, userState?.email]);

  return (
    <PrivateLayout menu="User Settings">
      <Container>
        <Box
          display="flex"
          flex="1"
          flexDirection="column"
          sx={{
            background: theme.palette.grey[100],
            borderRadius: "8px",
            "&::-webkit-scrollbar": { display: "none" },
            overflow: "scroll",
            marginBottom: "24px",
          }}
        >
          <Box
            id="profile-information"
            sx={{
              padding: "24px",
            }}
          >
            <Box sx={{ display: "flex" }}>
              <Box
                sx={{
                  width: "16px",
                  height: "32px",
                  margin: "0px 16px 0px 0px",
                  background: theme?.custom.colors.green,
                  borderRadius: "4px",
                }}
              />
              <Text
                variant="h2"
                fontSize="20px"
                fontWeight={600}
                lineHeight="32px"
                letterSpacing="-0.02em"
                sx={{
                  ml: 0,
                  textAlign: "left",
                }}
              >
                Profile Information
              </Text>
            </Box>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Stack
                direction="row"
                sx={{
                  mt: "34px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Avatar
                  sx={{
                    height: "96px",
                    width: "96px",
                  }}
                  alt={userState.firstName}
                  src={
                    userState.original_avatar_url
                      ? userState.thumbnail_avatar_url
                      : ""
                  }
                  {...stringAvatar(
                    `${userState.firstName} ${userState.lastName}`
                  )}
                />
              </Stack>
              <Stack direction="column" sx={{ width: "100%", mt: "34px" }}>
                <Box
                  sx={{
                    mt: "34px",
                    display: "flex",
                    flexFlow: "row",
                  }}
                >
                  <Box sx={{ width: "47.5%", mr: "5%" }}>
                    <Box sx={{ marginBottom: "10px" }}>
                      <FormLabel>First Name</FormLabel>
                    </Box>
                    <Box>
                      <SharedInput
                        type="text"
                        register={register}
                        id="firstName"
                        errors={errors?.firstName?.message}
                        errorSx={{
                          top: "20px",
                        }}
                        variant="filled"
                      />
                    </Box>
                  </Box>
                  <Box sx={{ width: "47.5%" }}>
                    <Box sx={{ marginBottom: "10px" }}>
                      <FormLabel>Last Name</FormLabel>
                    </Box>
                    <Box>
                      <SharedInput
                        type="text"
                        register={register}
                        id="lastName"
                        errors={errors?.lastName?.message}
                        variant="filled"
                        errorSx={{
                          top: "20px",
                        }}
                      />
                    </Box>
                  </Box>
                </Box>
                <Box sx={{ mt: "10px" }}>
                  <Box sx={{ marginBottom: "10px" }}>
                    <FormLabel>Email</FormLabel>
                  </Box>
                  <Box>
                    <SharedInput
                      register={register}
                      id="email"
                      errors={errors?.email?.message}
                      variant="filled"
                      errorSx={{
                        top: "20px",
                      }}
                      disabled
                    />
                  </Box>
                </Box>
                <Divider
                  sx={{
                    mt: "27px",
                    mb: "27px",
                    height: "1px",
                    background: theme.palette.grey[300],
                  }}
                />
                <Box id="login" sx={{ display: "flex", widht: "100%" }}>
                  <Box
                    sx={{
                      width: "16px",
                      height: "32px",
                      margin: "0px 16px 0px 0px",
                      background: theme.custom.colors.purple,
                      borderRadius: "4px",
                    }}
                  />
                  <Text
                    variant="h2"
                    fontSize="20px"
                    fontWeight={600}
                    lineHeight="32px"
                    letterSpacing="-0.02em"
                    sx={{
                      ml: 0,
                      textAlign: "left",
                      mb: "38px",
                    }}
                  >
                    Login
                  </Text>
                </Box>
                <Box sx={{ width: "100%" }}>
                  <Box sx={{ marginBottom: "10px" }}>
                    <FormLabel>Old Password</FormLabel>
                  </Box>
                  <Box>
                    <SharedInput
                      type="password"
                      register={register}
                      id="oldPassword"
                      errors={errors?.oldPassword?.message}
                      variant="filled"
                      errorSx={{
                        top: "20px",
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      mt: "34px",
                      display: "flex",
                      flexFlow: "row",
                    }}
                  >
                    <Box sx={{ width: "47.5%", mr: "5%" }}>
                      <Box sx={{ marginBottom: "10px" }}>
                        <FormLabel>New Password</FormLabel>
                      </Box>
                      <Box>
                        <SharedInput
                          type="password"
                          register={register}
                          id="newPassword"
                          errors={errors?.newPassword?.message}
                          variant="filled"
                          errorSx={{
                            top: "20px",
                          }}
                        />
                      </Box>
                    </Box>
                    <Box sx={{ width: "47.5%" }}>
                      <Box sx={{ marginBottom: "10px" }}>
                        <FormLabel>Confirm new password</FormLabel>
                      </Box>
                      <SharedInput
                        type="password"
                        register={register}
                        id="confirmNewPassword"
                        errors={errors?.confirmNewPassword?.message}
                        variant="filled"
                        errorSx={{
                          top: "20px",
                        }}
                      />
                    </Box>
                  </Box>
                  {/*<SharedButton
                    variant="contained"
                    sx={{
                      mt: "34px",
                      width: "auto",
                      color: theme.palette.text.secondary,
                      backgroundColor: theme.palette.grey[100],
                      textTransform: "none",
                      borderBottomRightRadius: "20px",
                      borderTopLeftRadius: "20px",
                      boxShadow: "none",
                      border: `2px solid ${theme.palette.grey[300]}`,
                      "&:hover": {
                        backgroundColor: theme.palette.grey[100],
                        cursor: "pointer",
                        boxShadow: "none",
                      },
                    }}
                  >
                    Update password
				</SharedButton>*/}
                </Box>
                {/*<Divider
                sx={{
                  mt: "27px",
                  mb: "27px",
                  height: "1px",
                  background: theme.palette.grey[300],
                }}
              />
              <Box sx={{ display: "flex", widht: "100%" }}>
                <Box
                  sx={{
                    width: "16px",
                    height: "32px",
                    margin: "0px 16px 0px 0px",
                    background: theme.custom.colors.redFaded,
                    borderRadius: "4px",
                  }}
                />
                <Text
                  variant="h2"
                  fontSize="20px"
                  fontWeight={600}
                  lineHeight="32px"
                  letterSpacing="-0.02em"
                  sx={{
                    ml: 0,
                    textAlign: "left",
                  }}
                >
                  Notifications
                </Text>
              </Box>*/}

                <Stack>
                  {/*<Text
                  variant="h2"
                  fontSize="14px"
                  fontWeight={400}
                  lineHeight="32px"
                  letterSpacing="-0.02em"
                  sx={{
                    ml: 0,
                    display: "flex",
                    alignItems: "center",
                    mt: "34px",
                    justifyContent: "space-between",
                    color: theme.palette.grey[600],
                  }}
                >
                  Turn on email notifications for new candidates added to my
                  jobs
                  <Switch
                    {...register("slackNotifications")}
                    sx={{ float: "end" }}
                  />
                </Text>*/}
                  {/*<Text
                  variant="h2"
                  fontSize="14px"
                  fontWeight={400}
                  lineHeight="32px"
                  letterSpacing="-0.02em"
                  sx={{
                    textAlign: "left",
                    ml: 0,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    mt: "34px",
                    color: theme.palette.grey[600],
                  }}
                >
                  Turn on Slack notifications for new candidates added to my
                  jobs
                  <Switch
                    color={"secondary"}
                    sx={{ float: "right" }}
                    {...register("emailNotifications")}
                  />
                </Text>*/}
                  <SharedButton
                    type="submit"
                    variant="contained"
                    color="secondary"
                    // onClick={handleSubmit(onSubmit)}
                    sx={{
                      mt: "34px",
                      width: "212px",
                      height: "48px",
                      padding: "12px 20px",
                      boxShadow: "none",
                    }}
                  >
                    save
                  </SharedButton>
                </Stack>
              </Stack>
            </form>
          </Box>
        </Box>
        <Box
          display="flex"
          flex="0.4"
          flexDirection="column"
          sx={{
            marginLeft: "8px",
            background: theme.palette.grey[100],
            padding: "24px",
            borderRadius: "8px",
            maxHeight: "20vh",
          }}
        >
          {["Profile Information", "Login"].map((el: string) => (
            <Box
              key={el}
              sx={{
                width: "100%",
                float: "right",
                borderRadius: "8px",
                marginBottom: "4px",
                cursor: "pointer",
                background:
                  el === currentSection
                    ? theme.palette.grey[300]
                    : theme.palette.grey[100],
              }}
              onClick={() => viewSection(el)}
            >
              <Text
                variant="body1"
                fontWeight={600}
                fontSize={"15px"}
                lineHeight={"24px"}
                sx={{
                  color:
                    el === currentSection
                      ? theme.palette.text.secondary
                      : theme.palette.grey[400],
                  textAlign: "left",
                  marginLeft: 0,
                  padding: "8px 16px",
                  borderRadius: "8px",
                  cursor: "pointer",
                  "&:hover": {
                    color: theme.palette.text.secondary,
                  },
                }}
              >
                {el}
              </Text>
            </Box>
          ))}
        </Box>
      </Container>
    </PrivateLayout>
  );
};

export default Settings;
