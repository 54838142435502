/* eslint-disable comma-dangle */
import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { styled } from "@mui/material/styles";
import { StepIconProps } from "@mui/material/StepIcon";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import { useAppDispatch, RootState } from "redux/store";
import { useSelector } from "react-redux";
import { isEmpty, _get } from "utils/lodash";
import { useNavigate, useLocation } from "react-router-dom";

import JobOnboardingForm from "components/new-job";
import SkipIconImage from "../../assets/images/skip-icon.svg";
import { doCreateJobAction } from "redux/services/job/create/create.actions";
import { doUpdateJobAction } from "redux/services/job/update/update.actions";
import {
  ROUTE_ACTIVE_JOBS,
  ROUTE_COMPANY_PROFILE,
  ROUTE_EDIT_JOB,
  ROUTE_INTEGRATIONS,
  ROUTE_JOB_ONBOARDING_COMPLETE,
  ROUTE_SETTINGS,
  ROUTE_WELCOME,
} from "routes/route-items";
import { toast } from "react-toastify";
import ToastMessage from "components/toast-message";
import { doGetJobsAction } from "redux/services/job/getJob/get.actions";
import { ICreateJobPayload } from "redux/services/job/create/interface";

const steps = ["Title & description", "Ideal candidate profile", "Pitch"];

const BorderLinearProgress = styled(LinearProgress)(() => ({
  height: 2,
  borderRadius: 5,
  width: "100%",
  display: "inline-block",
  marginTop: "22px",
  marginBottom: "40px",
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#fff",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: "#1a90ff",
  },
}));

const ModifiedStep = styled(Step)(() => ({
  display: "flex",
  flex: "1 1 auto",
  ["& .MuiStepLabel-label"]: {
    fontFamily: "Inter",
    fontWeight: 600,
    fontSize: "18px",
    lineHeight: "32px",
  },
  ["& .MuiStepLabel-label.Mui-completed, & .MuiStepLabel-label.Mui-active"]: {
    color: "#111315",
  },
  ["& .MuiStepLabel-label.Mui-disabled"]: {
    color: "#6F767E",
  },
}));

const ColorlibStepIconRoot = styled("div")<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ ownerState }) => ({
  display: "flex",
  alignItems: "center",
  padding: "6px",
  width: "36px",
  height: "36px",
  backgroundColor: "#EFEFEF",
  boxShadow:
    "inset 0px -2px 1px rgba(0, 0, 0, 0.05), inset 0px 1px 1px #FFFFFF",
  borderRadius: "8px",
  justifyContent: "center",
  color: "#1A1D1F",
  fontWeight: 600,
  ...(ownerState.active && {
    backgroundColor: "#3785F9",
    color: "#FFFFFF",
  }),
  ...(ownerState.completed && {
    backgroundColor: "#3785F9",
    color: "#FFFFFF",
  }),
}));

const SkipButton = styled(Button)`
  font-family: "Inter";
  font-weight: 400;
  font-size: 14px;
  letter-spacing: -0.01em;
  color: ${({ theme }) => theme.palette.grey[400]};
  text-transform: none;
`;

const SkipIcon = styled("img")`
  margin-left: 10px;
`;

interface IState {
  step_1: {
    checkboxChecked: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    editorState: any;
    selected_seniority_level: string;
    selected_job_function: string;
    custom_job_function?: string;
    job_description_link: string;
    job_title: string;
  };
  step_2: {
    skills_must_have: { [key: string]: string };
    skills_nice_to_have: { [key: string]: string };
    skills_not_to_have: { [key: string]: string };
  };
  step_3: {
    checkbox_share_salary_range: boolean;
    checkbox_pay_unknown: boolean;
    editorState: any;
    salaryRange: number[];
    selected_currency: { label: string; value: string };
    job_exciting_things: { [key: string]: string };
    recruiter_notes: string;
  };
}

const initialState = {
  step_1: {
    checkboxChecked: false,
    editorState: EditorState.createEmpty(),
    selected_seniority_level: "",
    selected_job_function: "",
    custom_job_function: "",
    job_description_link: "",
    job_title: "",
  },
  step_2: {
    skills_must_have: {},
    skills_nice_to_have: {},
    skills_not_to_have: {},
  },
  step_3: {
    checkbox_share_salary_range: false,
    checkbox_pay_unknown: false,
    editorState: EditorState.createEmpty(),
    salaryRange: [25000, 100000],
    selected_currency: { value: "eur", label: "€ EUR" },
    job_exciting_things: {},
    recruiter_notes: "",
  },
};

export default function JobPageOnboarding() {
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState<{
    [k: number]: boolean;
  }>({});
  const [state, setState] = React.useState<IState>(initialState);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const reduxCreateJobState = useSelector(
    (reduxState: RootState) => reduxState.jobCreate
  );
  const reduxUpdateState = useSelector(
    (reduxState: RootState) => reduxState.jobUpdate
  );
  const userState = useSelector(
    (reduxState: RootState) => reduxState.user.response
  );
  const jobsGetState = useSelector(
    (reduxState: RootState) => reduxState.jobsGet.response
  );

  React.useEffect(() => {
    const job = reduxCreateJobState.response.job;

    if (job.seniority_level || job.seniority_level || job.job_description) {
      setState((st: IState) => ({
        ...st,
        step_1: {
          ...st.step_1,
          selected_job_function: job.job_function || "",
          custom_job_function: job.custom_job_function || "",
          selected_seniority_level: job.seniority_level || "",
          job_description_link: job.job_description_link || "",
          job_title: job.job_title || "",
          checkboxChecked: !isEmpty(job.job_description)
            ? convertFromRaw(JSON.parse(job?.job_description || "")).hasText()
            : false,
          editorState: !isEmpty(job.job_description)
            ? EditorState.createWithContent(
                convertFromRaw(JSON.parse(job.job_description || ""))
              )
            : EditorState.createEmpty(),
        },
      }));
      if (
        job.skills_must_have ||
        job.skills_nice_to_have ||
        job.skills_not_to_have
      ) {
        setState((st: IState) => ({
          ...st,
          step_2: {
            ...st.step_2,
            skills_must_have: job.skills_must_have || {},
            skills_nice_to_have: job.skills_nice_to_have || {},
            skills_not_to_have: job.skills_not_to_have || {},
          },
        }));
      }
      if (job.job_exciting_things) {
        setState((st: IState) => ({
          ...st,
          step_3: {
            ...st.step_3,
            job_exciting_things: job.job_exciting_things || {},
            checkbox_share_salary_range:
              job.checkbox_share_salary_range || false,
            checkbox_pay_unknown: job.checkbox_pay_unknown || false,
            editorState: !isEmpty(job.recruiter_notes)
              ? EditorState.createWithContent(
                  convertFromRaw(JSON.parse(job.recruiter_notes || ""))
                )
              : EditorState.createEmpty(),
            salaryRange: job.salary_range || [25000, 100000],
            selected_currency: job.selected_currency || {
              value: "eur",
              label: "€ EUR",
            },
            recruiter_notes: job.recruiter_notes || "",
          },
        }));
      }
    }
  }, [reduxCreateJobState]);

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const stepsProgress = () => {
    if (activeStep === 1) {
      return 74.5;
    }
    return Math.round(((activeStep + 1) / totalSteps()) * 100);
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  //   const handleStep = (step: number) => () => {
  //     setActiveStep(step);
  //   };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  function ColorlibStepIcon(props: StepIconProps) {
    const { active, completed, className } = props;

    const icons: { [index: string]: React.ReactElement } = {
      1: <span>1</span>,
      2: <span>2</span>,
      3: <span>3</span>,
    };

    return (
      <ColorlibStepIconRoot
        ownerState={{ completed, active }}
        className={className}
      >
        {icons[String(props.icon)]}
      </ColorlibStepIconRoot>
    );
  }

  const handleSetState = ({
    key,
    value,
    step,
  }: {
    key: string;
    step: string;
    value: string | boolean | Object;
  }) => {
    const curr_step = state[step as keyof typeof state];

    setState((st) => ({
      ...st,
      [step]: {
        ...curr_step,
        [key]: value,
      },
    }));
  };

  const handleSaveDescription = async (data: {
    jobDescriptionLink: string;
    jobTitle: string;
    customJobFunction: string;
  }) => {
    const location_state = _get(location, "state.company_options") || {};
    const { step_1 } = state;

    if (userState.uid && location_state.company_id) {
      await dispatch(
        doCreateJobAction({
          job: {
            seniority_level: step_1.selected_seniority_level,
            job_function: step_1.selected_job_function,
            custom_job_function:
              step_1.selected_job_function === data.customJobFunction
                ? null
                : data.customJobFunction,
            job_description_link: data.jobDescriptionLink,
            job_title: data.jobTitle,
            job_description: JSON.stringify(
              convertToRaw(step_1.editorState.getCurrentContent()),
              null,
              4
            ),
            company_id: location_state.company_id,
            company_name: location_state.company_name,
            created_by: userState.uid,
          },
        })
      );
      handleNext();
    } else {
      toast(
        <ToastMessage title="Authentication" body="Missing data or user" />,
        { type: "error", position: "top-right" }
      );
    }
  };

  const handleSaveProfile = async (data: any) => {
    const jobState = reduxCreateJobState.response.job;
    const keys = Object.keys(data) || [];
    const expectedKeys: string[] = [
      "skills_nice_to_have",
      "skills_not_to_have",
      "skills_must_have",
    ];
    let payload_obj: { [key: string]: { [key: string]: string } } = {
      skills_nice_to_have: {},
      skills_not_to_have: {},
      skills_must_have: {},
    };
    if (keys.length) {
      keys.map((el: string) => {
        if (!data[el]) return;
        const payload_str: string = el.slice(el.indexOf("_") + 1) || "";
        const payload_str_0: string = el.slice(0, el.indexOf("_")) || "";
        if (expectedKeys.includes(payload_str)) {
          payload_obj = {
            ...payload_obj,
            [payload_str]: {
              ...payload_obj[payload_str],
              [payload_str_0]: data[el],
            },
          };
        }
      });
    }

    await dispatch(
      doCreateJobAction({
        job: {
          ...jobState,
          skills_nice_to_have: payload_obj.skills_nice_to_have,
          skills_not_to_have: payload_obj.skills_not_to_have,
          skills_must_have: payload_obj.skills_must_have,
        },
      })
    );
    handleNext();
  };

  const handleSavePitch = async (data: any) => {
    const jobState = reduxCreateJobState.response.job;
    const { step_3 } = state;

    const keys = Object.keys(data) || [];
    const expectedKeys: string[] = ["job_exciting_things"];
    let payload_obj: { [key: string]: { [key: string]: string } } = {
      job_exciting_things: {},
    };
    if (keys.length) {
      keys.map((el: string) => {
        if (!data[el]) return;
        const payload_str: string = el.slice(el.indexOf("_") + 1) || "";
        const payload_str_0: string = el.slice(0, el.indexOf("_")) || "";
        if (expectedKeys.includes(payload_str)) {
          payload_obj = {
            ...payload_obj,
            [payload_str]: {
              ...payload_obj[payload_str],
              [payload_str_0]: data[el],
            },
          };
        }
      });
    }

    if (location.pathname === `/${ROUTE_EDIT_JOB}`) {
      const location_state = _get(location, "state");
      const updateResponse = await dispatch(
        doUpdateJobAction({
          job_id: location_state.job_id,
          job: {
            ...jobState,
            job_exciting_things: payload_obj.job_exciting_things,
            salary_range: step_3.salaryRange,
            selected_currency: step_3.selected_currency,
            checkbox_pay_unknown: step_3.checkbox_pay_unknown,
            checkbox_share_salary_range: step_3.checkbox_share_salary_range,
            recruiter_notes: JSON.stringify(
              convertToRaw(step_3.editorState.getCurrentContent()),
              null,
              4
            ),
          },
        })
      );
      const jobInState = jobsGetState.jobs?.find(
        (job) => job.job_id === location_state.job_id
      );

      if (updateResponse.meta.requestStatus === "rejected" || !jobInState) {
        return toast(
          <ToastMessage title="Job Update" body="Error updating job" />,
          {
            type: "error",
            position: "top-right",
          }
        );
      }

      const job_updated = {
        ...jobInState,
        ...jobState,
        job_exciting_things: payload_obj.job_exciting_things,
        salary_range: step_3.salaryRange,
        selected_currency: step_3.selected_currency,
        checkbox_pay_unknown: step_3.checkbox_pay_unknown,
        checkbox_share_salary_range: step_3.checkbox_share_salary_range,
        uid: location_state.uid,
        job_id: location_state.job_id,
        recruiter_notes: JSON.stringify(
          convertToRaw(step_3.editorState.getCurrentContent()),
          null,
          4
        ),
      };

      await dispatch(
        doGetJobsAction({
          ...jobsGetState,
          job_updated,
          jobs: [],
          page: 0,
          hasMore: true,
        })
      );

      const locationFrom = _get(location, "state.from");
      const job_id = locationFrom.split("/")[1];

      if (!locationFrom) {
        return navigate(-1);
      }

      if (job_id) {
        return navigate(`/${ROUTE_ACTIVE_JOBS}/${job_id}`);
      }

      return navigate(`/${ROUTE_ACTIVE_JOBS}`);
    }

    const createResponse = await dispatch(
      doCreateJobAction({
        step: "step_3",
        job: {
          ...jobState,
          job_exciting_things: payload_obj.job_exciting_things,
          salary_range: step_3.salaryRange,
          selected_currency: step_3.selected_currency,
          checkbox_pay_unknown: step_3.checkbox_pay_unknown,
          checkbox_share_salary_range: step_3.checkbox_share_salary_range,
          recruiter_notes: JSON.stringify(
            convertToRaw(step_3.editorState.getCurrentContent()),
            null,
            4
          ),
          recruiters_attached: [userState.uid],
        },
      })
    );

    if (createResponse.meta.requestStatus === "rejected") {
      return toast(
        <ToastMessage title="New Job" body="Error adding new job" />,
        {
          type: "error",
          position: "top-right",
        }
      );
    }

    const payload = createResponse.payload as ICreateJobPayload;
    const jobData = payload.job;

    const job_added = {
      seniority_level: jobData.seniority_level,
      job_function: jobData.job_function,
      custom_job_function: jobData.custom_job_function,
      job_description: jobData.job_description,
      recruiter_notes: jobData.recruiter_notes,
      job_description_link: jobData.job_description_link,
      job_title: jobData.job_title,
      skills_must_have: jobData.skills_must_have,
      skills_nice_to_have: jobData.skills_nice_to_have,
      skills_not_to_have: jobData.skills_not_to_have,
      job_exciting_things: jobData.job_exciting_things,
      salary_range: jobData.salary_range,
      selected_currency: jobData.selected_currency,
      checkbox_pay_unknown: jobData.checkbox_pay_unknown,
      checkbox_share_salary_range: jobData.checkbox_share_salary_range,
      company_id: jobData.company_id,
      company_name: jobData.company_name,
      created_by: jobData.created_by,
      status: jobData.status,
      job_id: jobData.job_id,
      created_at: jobData.created_at
        ? new Date(jobData.created_at._seconds * 1000).toISOString()
        : new Date().toISOString(),
      attached_candidates: {
        total: jobData.attached_candidates?.total,
        PENDING: jobData.attached_candidates?.PENDING,
        SHORTLISTED: jobData.attached_candidates?.SHORTLISTED,
        REJECTED: jobData.attached_candidates?.REJECTED,
        archived: jobData.attached_candidates?.archived,
      },
      recruiters_attached: jobData.recruiters_attached,
    };
    await dispatch(
      doGetJobsAction({
        ...jobsGetState,
        job_added,
        jobs: [],
        page: 0,
        hasMore: true,
      })
    );

    const locationFrom = _get(location, "state.from");

    if (!locationFrom) {
      return navigate(-1);
    }

    if (locationFrom === ROUTE_WELCOME) {
      return navigate(`/${ROUTE_JOB_ONBOARDING_COMPLETE}`);
    }

    return navigate(`/${ROUTE_ACTIVE_JOBS}`);
  };

  const skipOnboarding = () => {
    const location_state = _get(location, "state.from");

    if (!location_state) {
      return navigate(-1);
    }

    const job_id = location_state.split("/")[1];

    if (job_id && location_state === `${ROUTE_ACTIVE_JOBS}/${job_id}`) {
      return navigate(`/${ROUTE_ACTIVE_JOBS}/${job_id}`);
    }

    if (
      location.pathname === `/${ROUTE_EDIT_JOB}` ||
      location_state === ROUTE_ACTIVE_JOBS
    ) {
      return navigate(`/${ROUTE_ACTIVE_JOBS}`);
    }

    if (location_state === `${ROUTE_COMPANY_PROFILE}`) {
      return navigate(`/${ROUTE_COMPANY_PROFILE}`);
    }

    if (location_state === `${ROUTE_INTEGRATIONS}`) {
      return navigate(`/${ROUTE_INTEGRATIONS}`);
    }

    if (location_state === `${ROUTE_SETTINGS}`) {
      return navigate(`/${ROUTE_SETTINGS}`);
    }

    return navigate(`/${ROUTE_JOB_ONBOARDING_COMPLETE}`);
  };

  return (
    <Box
      sx={{
        backgroundColor: "#EFEFEF",
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Box sx={{ width: "50%", mb: "60px" }}>
        <Box
          sx={{
            mt: "60px",
            mb: "40px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography
            variant="h2"
            sx={{
              fontFamily: "Inter",
              fontWeight: 600,
              fontSize: "32px",
              lineHeight: "40px",
            }}
          >
            {location.pathname === `/${ROUTE_EDIT_JOB}`
              ? "Edit Job Posting"
              : "Kick off a new job"}
          </Typography>
          <SkipButton onClick={skipOnboarding}>
            Close <SkipIcon src={SkipIconImage} />
          </SkipButton>
        </Box>
        <Stepper activeStep={activeStep} connector={<div />}>
          {steps.map((label) => (
            <ModifiedStep key={label}>
              <StepLabel StepIconComponent={ColorlibStepIcon}>
                {label}
              </StepLabel>
            </ModifiedStep>
          ))}
        </Stepper>
        <BorderLinearProgress variant="determinate" value={stepsProgress()} />
        <JobOnboardingForm
          allStepsCompleted={allStepsCompleted}
          handleReset={handleReset}
          activeStep={activeStep}
          completed={completed}
          handleSetState={handleSetState}
          handleSaveProfile={handleSaveProfile}
          handleSavePitch={handleSavePitch}
          state={state}
          handleNext={handleNext}
          handleComplete={handleComplete}
          steps={steps}
          completedSteps={completedSteps}
          totalSteps={totalSteps}
          handleBack={handleBack}
          handleSaveDescription={handleSaveDescription}
          reduxStateStatus={
            location.pathname === `/${ROUTE_EDIT_JOB}`
              ? reduxUpdateState.status
              : reduxCreateJobState.status
          }
        />
      </Box>
    </Box>
  );
}
