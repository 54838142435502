import * as React from "react";
import { styled } from "@mui/material/styles";
import Clock from "assets/images/clock.svg";
import Text from "components/shared/text/text";

const ApprovalBox = styled("div")`
  background: ${({ theme }) => theme.palette.grey[100]};
  padding: 24px;
  margin-top: 8px;
  border-radius: 8px;
  align-items: center;
  font-family: "Inter";
`;

const Approval = () => {
  return (
    <ApprovalBox
      sx={{
        height: "80vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <img width={100} height={100} src={Clock} alt="clock image" />
      <Text
        sx={{
          marginTop: "2rem",
          fontFamily: "Inter",
          fontStyle: "normal",
          fontWeight: 600,
          fontSize: "32px",
          lineHeight: "40px",
          letterSpacing: "-0.03em",
        }}
      >
        Pending Approval
      </Text>
      <Text
        sx={{
          marginTop: "2rem",
          fontFamily: "Inter",
          fontStyle: "normal",
          color: "#6F767e",
          fontWeight: 400,
          fontSize: "14px",
          lineHeight: "150%",
          letterSpacing: "-0.01em",
        }}
      >
        Your account needs to be approved by an admin before you can proceed.
      </Text>
      <Text
        sx={{
          fontFamily: "Inter",
          fontStyle: "normal",
          color: "#6F767e",
          fontWeight: 400,
          fontSize: "14px",
          lineHeight: "150%",
          letterSpacing: "-0.01em",
        }}
      >
        If you have any questions please contact Cátia Barbosa{" "}
        <a
          target="_blank"
          href="https://calendly.com/michael-79/30min"
          rel="noreferrer"
        >
          (catia@acelr8.com)
        </a>
      </Text>
    </ApprovalBox>
  );
};

export default Approval;
