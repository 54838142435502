/* eslint-disable  @typescript-eslint/no-explicit-any */
import React, { useState, useEffect, useCallback } from "react";
import Span from "components/shared/span/span";
import Form from "components/shared/form/form";
import Text from "components/shared/text/text";
import { FormLabel } from "components/new-job/shared";
import Input from "components/shared/input/input";
import { Asterisk } from "components/shared/asterisk";
import CheckBox from "components/shared/checkBox";
import SharedEditor from "components/shared/editor";
import FileUpload from "components/shared/file-upload";
import SharedButton from "components/shared/button/button";
import ToastMessage from "components/toast-message";

import CandidateArrow from "assets/images/candidate-arrow.svg";
import CandidateClose from "assets/images/candidate-close.svg";

import Grid from "@mui/material/Unstable_Grid2";
import { styled } from "@mui/system";
import CircularProgress from "@mui/material/CircularProgress";
import { useTheme } from "@mui/styles";
import Box from "@mui/material/Box";

import {
  Modal,
  Backdrop,
  ModalBody,
  ContentContainer,
  ModalHeader,
  FlexSpan,
  BackButton,
  ModalContentBody,
  ModalFooter,
} from "./styles";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { CandidateSchema } from "utils/form-schema";
import { _first, _get, isEmpty, _sample } from "utils/lodash";
import formFields from "./form";
import {
  ICandidate,
  IConnectedCandidate,
  IInterviewStatus,
  ISelectedSkill,
} from "types/types";
import { RootState, useAppDispatch } from "redux/store";
import { useSelector } from "react-redux";
import { doCreateCandidateAction } from "redux/services/candidates/create/create.actions";
import { doUpdateCandidateAction } from "redux/services/candidates/update/update-candidate.actions";
import { toast } from "react-toastify";
import {
  EditorState,
  convertToRaw,
  convertFromRaw,
  ContentState,
} from "draft-js";
import {
  getDownloadURL,
  ref,
  storage,
  uploadBytesResumable,
  deleteObject,
} from "utils/firebase";

import { nanoid } from "nanoid";
import { getRecruiterNotes } from "utils/helpers";
import { ICreateCandidatePayload } from "redux/services/candidates/create/interface";
import { doGetCandidatesAction } from "redux/services/candidates/getCandidates/get.actions";
import { IUpdateCandidatePayload } from "redux/services/candidates/update/interface";

interface IProps {
  handleClose: () => void;
  open: boolean;
  skills_must_have?: ISelectedSkill[] | any;
  skills_nice_to_have?: ISelectedSkill[] | any;
  candidate?: ICandidate;
  job_id: string;
}
const FormContainer = styled(Box)`
  margin-bottom: 12px;
`;

const CheckBoxContainer = styled(Box)`
  display: flex;
  align-items: center;
  background: ${({ theme }) => theme.palette.grey[900]};
  border-radius: 12px;
  margin-bottom: 10px;
  padding-left: 10px;
  &:hover {
    cursor: pointer;
  }
  :last-child {
    margin-bottom: 0;
  }
  label {
    margin-right: 0;
  }
`;

const CustomCheckBox = styled(CheckBox)`
  & .MuiFormControlLabel-root {
    margin-right: 0;
  }
`;

const CandidateOnboardingForm = ({
  open,
  handleClose,
  skills_nice_to_have,
  skills_must_have,
  job_id,
  candidate,
}: IProps) => {
  const {
    register,
    handleSubmit,
    reset,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(CandidateSchema),
  });
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [recruiterNotes, setRecruiterNotes] = useState(() =>
    EditorState.createEmpty()
  );
  const [mustHaveSkills, setMustHaveSkills] = useState<{
    [key: string]: boolean;
  }>({});
  const [niceToHaveSkills, setNiceToHaveSkills] = useState<{
    [key: string]: boolean;
  }>({});
  const [file, setFile] = useState<File | null>(null);
  const [progress, setProgress] = useState(0);
  const [fileUrl, setFileUrl] = useState("");
  const [uploading, setUploading] = useState(false);
  const [uploadTask, setUploadTask] = useState();
  const [avatarURL, setAvatarURL] = useState("");

  const candidatesReduxState = useSelector(
    (reduxState: RootState) => reduxState.candidatesGet
  );

  const getSkills = (
    skills: ISelectedSkill[],
    fn: Function,
    selected?: boolean
  ) => {
    const skills_ = Object.keys(skills).reduce(
      (acc: { [x: string]: boolean }, value: string) => {
        if (!acc) acc = {};
        const name = _get(skills, `${value}.name`) || _get(skills, value);
        acc[name] = candidate
          ? _get(skills, `${value}.selected`, selected)
          : false;
        return acc;
      },
      {}
    );
    return fn(skills_);
  };

  useEffect(() => {
    const isCreateCandidate = !candidate?.candidate_id;
    if (isCreateCandidate) {
      if (skills_must_have) {
        getSkills(skills_must_have, setMustHaveSkills, false);
      }
      if (skills_nice_to_have) {
        getSkills(skills_nice_to_have, setNiceToHaveSkills, false);
      }
    }
  }, [skills_nice_to_have, skills_must_have, candidate]);

  useEffect(() => {
    setFileUrl("");
    setFile(null);

    if (candidate && open) {
      setValue("firstName", candidate.firstName);
      setValue("lastName", candidate.lastName);
      setValue("email", candidate.email);
      setValue("phone", candidate.phone);
      setValue("portfolio", candidate.portfolio);
      setValue("pronouns", candidate.pronouns);
      setValue("jobTitle", candidate.jobTitle);
      setValue("currentCompany", candidate.currentCompany);
      setValue("location", candidate.location);
      if (candidate.connected_links) {
        const { link: linkedIn } =
          _first(
            candidate.connected_links.filter(
              (link) => _get(link, "key") === "linkedin"
            )
          ) || {};
        setValue("linkedInProfile", linkedIn);
      }
      if (candidate.skills_must_have) {
        getSkills(candidate.skills_must_have, setMustHaveSkills);
      }
      if (candidate.skills_nice_to_have) {
        getSkills(candidate.skills_nice_to_have, setNiceToHaveSkills);
      }

      if (candidate.recruiter_notes) {
        const blocks = getRecruiterNotes(candidate.recruiter_notes, true);
        let notes = blocks;
        try {
          if (notes.blocks) {
            notes = EditorState.createWithContent(convertFromRaw(blocks));
          } else {
            notes = EditorState.createWithContent(
              ContentState.createFromText(candidate.recruiter_notes)
            );
          }
        } catch {
          // ? handle error getting notes
        } finally {
          setRecruiterNotes(notes);
        }
      }

      if (candidate.cv_file_name) {
        let contentType;
        let last_dot = candidate.cv_file_name.lastIndexOf(".");
        let ext = candidate.cv_file_name.slice(last_dot + 1);
        if (ext === "doc" || ext === "docx") {
          contentType = "application/vnd";
        } else {
          contentType = "application/pdf";
        }
        let file = new File([], candidate.cv_file_name, {
          type: contentType,
        });
        setFile(file);
      }

      if (candidate.cv_url) {
        setFileUrl(candidate.cv_url);
      }
      setAvatarURL(candidate.candidate_avatar_url);
    }
  }, [candidate, open]);

  const removeFile = () => {
    const fileRef = ref(storage, fileUrl);
    setFileUrl("");
    return deleteObject(fileRef);
  };

  const closeModal = () => {
    reset();
    setRecruiterNotes(() => EditorState.createEmpty());
    handleClose();
  };

  const getImageURL = useCallback(async () => {
    if (open && !candidate?.candidate_id) {
      const random = _sample(["man", "woman"]);
      const index = Math.floor(Math.random() * 6) + 1;
      const storageRef = ref(storage, `/toy_faces/${random}_${index}.jpg`);
      const avatarURL = await getDownloadURL(storageRef);
      setAvatarURL(avatarURL);
    }
  }, [open, candidate]);

  useEffect(() => {
    getImageURL();
  }, [getImageURL]);

  const onSubmit = async () => {
    setLoading(true);
    const values = getValues();
    let linkedIn = {};
    if (values.linkedInProfile) {
      linkedIn = {
        key: "linkedin",
        link: values.linkedInProfile,
      };
    }

    const serializeSkills = (skill: { [key: string]: boolean }) => {
      if (!isEmpty(skill)) {
        return Object.keys(skill).map((name) => ({
          name,
          selected: skill[name],
        }));
      }
      return [];
    };

    let candidateData: Partial<IConnectedCandidate> = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      phone: values.phone,
      portfolio: values.portfolio,
      location: values.location,
      pronouns: values.pronouns,
      jobTitle: values.jobTitle,
      job_id,
      currentCompany: values.currentCompany,
      connected_links: [linkedIn],
      interview_status: IInterviewStatus.PENDING,
      skills_nice_to_have: serializeSkills(niceToHaveSkills),
      skills_must_have: serializeSkills(mustHaveSkills),
      recruiter_notes: JSON.stringify(
        convertToRaw(recruiterNotes.getCurrentContent()),
        null,
        4
      ),
      hasAttachedCV: !!file,
      cv_file_name: _get(file || {}, "name") || "",
      cv_url: fileUrl,
      candidate_id: _get(candidate, "candidate_id"),
      candidate_avatar_url: avatarURL,
    };

    let isSuccessful = false;
    if (candidate?.candidate_id) {
      const updateResponse = await dispatch(
        doUpdateCandidateAction({
          job_id: candidate.job_id,
          candidate_id: candidate?.candidate_id as string,
          candidate: candidateData,
        })
      );

      isSuccessful = updateResponse.meta.requestStatus === "fulfilled";

      if (isSuccessful) {
        const updateCandidatePayload =
          updateResponse.payload as IUpdateCandidatePayload;
        const candidateUpdated =
          updateCandidatePayload.candidate as IConnectedCandidate;
        const currentCandidates =
          candidatesReduxState.response.candidates || [];
        const candidates = currentCandidates.map((currentCandidate) => {
          if (currentCandidate.candidate_id === candidateUpdated.candidate_id) {
            return { ...currentCandidate, ...candidateUpdated };
          }
          return currentCandidate;
        });

        await dispatch(
          doGetCandidatesAction({
            job_id,
            candidates,
          })
        );
      }
    } else {
      const createRepsonse = await dispatch(
        doCreateCandidateAction({
          candidate: candidateData,
        })
      );

      isSuccessful = createRepsonse.meta.requestStatus === "fulfilled";

      if (isSuccessful) {
        const createCandidatePayload =
          createRepsonse.payload as ICreateCandidatePayload;
        const candidateCreated =
          createCandidatePayload.candidate as IConnectedCandidate;
        const currentCandidates =
          candidatesReduxState.response.candidates || [];
        const candidates = [candidateCreated, ...currentCandidates];

        await dispatch(
          doGetCandidatesAction({
            job_id,
            candidates,
          })
        );
      }
    }

    if (isSuccessful) {
      const text = candidate?.candidate_id ? "updated" : "added";
      toast.success(
        <ToastMessage
          title={`Candidate ${text}!`}
          body={`${values.firstName} ${values.lastName} has been ${text} successfully`}
        />,
        { type: "success", position: "top-right" }
      );
    } else {
      const text = candidate?.candidate_id ? "updating" : "adding";
      toast.error(
        <ToastMessage
          title={`Error ${text} candidate!`}
          body={`Error ${text} candidate`}
        />,
        { type: "error", position: "top-right" }
      );
    }

    closeModal();
    setLoading(false);
  };

  const handleCheckboxChange = (
    checked: boolean,
    skill: string,
    setFn: Function
  ) => {
    setFn((prev: { [key: string]: boolean }) => ({
      ...prev,
      [skill]: checked,
    }));
  };

  const cancelUpload = () => {
    try {
      // @ts-ignore
      uploadTask && uploadTask?.cancel();
    } catch (e) {
      toast(
        <ToastMessage title="Cancel Upload" body="Unable to cancel upload" />,
        {
          type: "warning",
          position: "top-right",
        }
      );
    }
  };

  const uploadFile = (file: File | null) => {
    if (!file) return setFile(null);
    setUploading(true);
    const storageRef = ref(storage, `candidates/attachments/cv/cv-${nanoid()}`);

    const uploadTask = uploadBytesResumable(storageRef, file, {
      customMetadata: {
        file_name: _get(file || {}, "name"),
      },
    });
    // @ts-ignore
    setUploadTask(uploadTask);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgress(progress);
      },
      () => {
        setUploading(false);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setFileUrl(downloadURL);
          setFile(file);
          setUploading(false);
        });
      }
    );
  };

  const handleSetFile = (file: File | null) => {
    uploadFile(file);
  };

  return (
    <Modal
      aria-labelledby="unstyled-modal-title"
      aria-describedby="unstyled-modal-description"
      open={open}
      onClose={closeModal}
      components={{ Backdrop }}
      sx={{
        zIndex: 2000,
        background: candidate?.candidate_id ? "white" : "inherit",
      }}
    >
      <ModalBody>
        <ContentContainer>
          <ModalHeader>
            <BackButton onClick={closeModal} sx={{ left: "-25px" }}>
              <FlexSpan>
                <img src={CandidateArrow} alt="" />
                <Span
                  fontWeight={500}
                  fontSize="13px"
                  color={"#ffffff"}
                  sx={{
                    lineHeight: "1",
                    paddingLeft: "10px",
                  }}
                >
                  {candidate?.candidate_id
                    ? "Candidate Profile"
                    : "Add a candidate"}
                </Span>
              </FlexSpan>
            </BackButton>
            <BackButton onClick={closeModal} sx={{ right: "-10px" }}>
              <FlexSpan>
                <Span
                  fontWeight={400}
                  fontSize="14px"
                  color={"#dddddd"}
                  sx={{
                    lineHeight: "1",
                    display: "inline-block",
                    marginRight: "10px",
                  }}
                >
                  Cancel
                </Span>
                <img src={CandidateClose} alt="" />
              </FlexSpan>
            </BackButton>
          </ModalHeader>
          <ModalContentBody>
            <Box sx={{ display: "flex" }}>
              <Box
                sx={{
                  width: "16px",
                  height: "32px",
                  margin: "0px 16px 32px 0px",
                  background: _get(theme, "custom.colors.green"),
                  borderRadius: "4px",
                }}
              />
              <Text
                variant="h2"
                fontSize="20px"
                fontWeight={600}
                lineHeight="32px"
                letterSpacing="-0.02em"
                sx={{
                  ml: 0,
                  textAlign: "left",
                }}
              >
                Profile Information
              </Text>
            </Box>

            <Form onSubmit={handleSubmit(onSubmit)}>
              <Grid container rowSpacing={8} columnSpacing={6}>
                {formFields.map((field) => (
                  <Grid xs={6} key={field.id}>
                    <FormContainer>
                      <FormLabel>{field.formLabel}</FormLabel>
                      {field.required && <Asterisk />}
                    </FormContainer>
                    <Input
                      id={field.id}
                      register={register}
                      variant="filled"
                      placeholder={field.placeholder}
                      errors={_get(errors, `${field.id}.message`, null)}
                      errorSx={{
                        top: "20px",
                      }}
                    />
                  </Grid>
                ))}
                {!isEmpty(mustHaveSkills) && (
                  <Grid xs={12}>
                    <FormContainer>
                      <FormLabel>
                        This candidate matches the following "must have"
                        criteria
                      </FormLabel>
                    </FormContainer>

                    {Object.keys(mustHaveSkills).map((skill, index) => (
                      <CheckBoxContainer
                        key={`${skill}-${index}`}
                        onClick={() =>
                          handleCheckboxChange(
                            !mustHaveSkills[skill],
                            skill,
                            setMustHaveSkills
                          )
                        }
                      >
                        <CustomCheckBox
                          sx={{ margin: 0 }}
                          checked={mustHaveSkills[skill]}
                          onChange={(e) =>
                            handleCheckboxChange(
                              e.target.checked,
                              skill,
                              setMustHaveSkills
                            )
                          }
                        />
                        {skill}
                      </CheckBoxContainer>
                    ))}
                  </Grid>
                )}

                {!isEmpty(niceToHaveSkills) && (
                  <Grid xs={12}>
                    <FormContainer>
                      <FormLabel>
                        This candidate matches the following "nice to have"
                        criteria
                      </FormLabel>
                    </FormContainer>

                    <Box>
                      {Object.keys(niceToHaveSkills).map((skill, index) => (
                        <CheckBoxContainer
                          key={`${skill}-${index}`}
                          onClick={() =>
                            handleCheckboxChange(
                              !niceToHaveSkills[skill],
                              skill,
                              setNiceToHaveSkills
                            )
                          }
                        >
                          <CustomCheckBox
                            sx={{ margin: 0 }}
                            checked={niceToHaveSkills[skill]}
                            onChange={(e) =>
                              handleCheckboxChange(
                                e.target.checked,
                                skill,
                                setNiceToHaveSkills
                              )
                            }
                          />
                          {skill}
                        </CheckBoxContainer>
                      ))}
                    </Box>
                  </Grid>
                )}

                <Grid xs={12}>
                  <FormContainer>
                    <FormLabel>
                      Recruiter notes (visible to the client)
                    </FormLabel>
                  </FormContainer>
                  <SharedEditor
                    editorState={recruiterNotes}
                    onEditorStateChange={setRecruiterNotes}
                  />
                </Grid>
                <Grid xs={12}>
                  <FormContainer>
                    <FormLabel>Upload a CV</FormLabel>
                  </FormContainer>

                  <FileUpload
                    handleSetFile={handleSetFile}
                    handleRemoveFile={removeFile}
                    uploading={uploading}
                    file={file}
                    progress={progress}
                    handleCancel={cancelUpload}
                  />
                </Grid>
                <Grid xs={12}>
                  <FormContainer>
                    <FormLabel>
                      When you submit, the client will be notified of this
                      candidate.
                    </FormLabel>
                  </FormContainer>
                </Grid>
                <Grid xs={4}>
                  <SharedButton
                    sx={{ width: "120px", height: "48px" }}
                    variant="contained"
                    type="submit"
                  >
                    <Box
                      sx={{
                        marginLeft: "6px",
                        textTransform: "initial",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {loading ? (
                        <>
                          <CircularProgress
                            sx={{ marginRight: "10px" }}
                            color="inherit"
                            size="20px"
                          />
                        </>
                      ) : (
                        "Submit"
                      )}
                    </Box>
                  </SharedButton>
                </Grid>
              </Grid>
            </Form>
          </ModalContentBody>
          <ModalFooter />
        </ContentContainer>
      </ModalBody>
    </Modal>
  );
};

export default CandidateOnboardingForm;
