import React, { useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "redux/store";
import { doForgotPasswordAction } from "redux/services/forgot-password/forgot-password.actions";
import Form from "components/shared/form/form";
import Input from "components/shared/input/input";
import Text from "components/shared/text/text";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { useTheme } from "@mui/material/styles";
import AuthForm from "components/auth-form/auth-form";
import { ROUTE_SIGN_IN } from "routes/route-items";
import { ForgotPassSchema } from "utils/form-schema";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ToastMessage from "components/toast-message";
import Box from "@mui/material/Box";
import { _get } from "utils/lodash";
import { useForm, SubmitHandler } from "react-hook-form";
import { ReactComponent as Warning } from "assets/images/warning.svg";
import SharedButton from "components/shared/button/button";

interface IState {
  email: string;
}

interface IEState {
  isLoading: boolean;
}

const initialState: IEState = {
  isLoading: false,
};

const ForgotPassword = () => {
  const [state, setState] = useState(initialState);
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IState>({
    resolver: yupResolver(ForgotPassSchema),
  });

  const reduxState = useSelector(
    (reduxState: RootState) => reduxState.forgotPassword || {}
  );

  const handleNavToSignin = () => {
    navigate(`/${ROUTE_SIGN_IN}`);
  };

  const renderErrors = {
    email: _get(errors, "email.message"),
  };

  const onSubmit: SubmitHandler<IState> = async (data: IState) => {
    setState((st: IEState) => ({ ...st, isLoading: true }));
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const forgotPassResp: any = await dispatch(
      doForgotPasswordAction({
        email: data.email,
      })
    );

    if (forgotPassResp?.payload?.error) {
      setState((st: IEState) => ({ ...st, isLoading: false }));
      toast.error(
        <ToastMessage
          title="Email reset error"
          body={
            "It seems like the user email is not registered, try signing up!"
          }
        />,
        { type: "error", position: "top-right" }
      );
      return;
    }

    if (forgotPassResp?.payload?.success) {
      setState((st: IEState) => ({ ...st, isLoading: false }));
      toast.success(
        <ToastMessage
          title="Email reset success"
          body="Email has been sent successfully!  Remember to check your spam folder."
        />,
        { type: "success", position: "top-right" }
      );
      return;
    }
  };

  return (
    <AuthForm
      id="forgot-password"
      title="Forgot Password"
      sx={{ width: "330px", paddingTop: "2rem" }}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Input
          iconStart={
            <MailOutlineIcon
              sx={{ color: theme.palette.text.primary, fontSize: 25 }}
            />
          }
          iconEnd={renderErrors.email ? <Warning /> : null}
          sx={{
            marginBottom: "20px",
            position: "relative",
          }}
          placeholder="Your work email"
          variant="filled"
          id="email"
          register={register}
          errors={renderErrors.email}
        />
        <SharedButton type="submit" variant="contained">
          <Box
            sx={{
              marginLeft: "6px",
              textTransform: "initial",
              display: "flex",
              alignItems: "center",
            }}
          >
            {reduxState.status === "pending" || state.isLoading ? (
              <>
                <CircularProgress
                  sx={{ marginRight: "10px" }}
                  color="inherit"
                  size="20px"
                />
                <span>Loading ...</span>
              </>
            ) : (
              "Reset password"
            )}
          </Box>
        </SharedButton>
        <Box display="flex" sx={{ marginTop: "1rem" }}>
          <Text
            fontSize="14px"
            lineHeight="24px"
            fontWeight={600}
            color={theme.palette.text.primary}
            letterSpacing="-0.01em"
            sx={{
              marginLeft: 0,
              textAlign: "left",
            }}
          >
            Back to
          </Text>
          <Box onClick={handleNavToSignin} sx={{ cursor: "pointer" }}>
            <Text
              fontSize="14px"
              lineHeight="24px"
              fontWeight={600}
              letterSpacing="-0.01em"
              sx={{ marginLeft: "5px" }}
            >
              Sign in
            </Text>
          </Box>
        </Box>
      </Form>
    </AuthForm>
  );
};

export default ForgotPassword;
