import { ICompany } from "types/types";
import { AsyncThunk, createAsyncThunk } from "@reduxjs/toolkit";
import { IGetCompanyPayload } from "./interface";
import { db, doc, getDoc } from "utils/firebase";
import { collection_name } from "utils/firebase-refs";

export const doGetCompanyAction: AsyncThunk<ICompany, IGetCompanyPayload, {}> =
  createAsyncThunk("company/get", async (payload, { rejectWithValue }) => {
    if (payload.company) {
      return {
        ...payload.company,
      };
    }

    try {
      const companyRef = doc(
        db,
        `${collection_name.v2_company}/${payload.company_id}`
      );
      const companySnapshot = await getDoc(companyRef);
      const {
        location,
        name,
        company_id,
        industries,
        uid,
        billingAddress,
        billingEmailAddress,
        vatID,
        work_type,
        total_jobs,
        jobs_without_criteria,
        role,
        total_candidates,
        original_avatar_url,
        thumbnail_avatar_url,
        created_at,
        recruiters_attached,
      } = companySnapshot.data() as ICompany;

      const company: ICompany = {
        location,
        name,
        company_id,
        industries,
        uid,
        billingAddress,
        billingEmailAddress,
        vatID,
        work_type,
        total_jobs,
        jobs_without_criteria,
        role,
        total_candidates,
        original_avatar_url,
        thumbnail_avatar_url,
        created_at,
        recruiters_attached,
        objectID: company_id,
      };

      return company;
    } catch (error: unknown) {
      return rejectWithValue(JSON.stringify(error));
    }
  });
