import { createSlice } from "@reduxjs/toolkit";
import { IUpdateState } from "./interface";
import { doUpdateCompanyAction } from "./update-company.actions";
import { ReduxStatus } from "types/types";

const initialState: IUpdateState = {
  status: ReduxStatus.empty,
  response: {},
  error: {},
};

export const updateCompany = createSlice({
  name: "company/update",
  initialState,
  reducers: {
    increment: () => {
      // state.candidateList = [1, 2];
      // ✅ CORRECT: logs a plain JS copy of the current data
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(doUpdateCompanyAction.pending, (state) => {
        state.status = ReduxStatus.pending;
      })
      .addCase(doUpdateCompanyAction.fulfilled, (state, action) => {
        state.status = ReduxStatus.fulfilled;
        state.error = {};
        state.response = action.payload;
      })
      .addCase(doUpdateCompanyAction.rejected, (state, action) => {
        state.status = ReduxStatus.rejected;
        state.response = {};
        state.error = action.error;
      });
  },
});

export const { increment } = updateCompany.actions;
export default updateCompany.reducer;
