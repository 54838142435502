import * as React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import * as ROUTES from "./route-items";
import { IUser } from "types/types";

interface IProps {
  user: IUser;
}

// eslint-disable-next-line no-unused-vars
const ApprovedRoute: React.FC<IProps> = ({ user }) => {
  let location = useLocation();

  if (user?.is_active) {
    return <Outlet />;
  }

  return (
    <Navigate
      to={ROUTES.ROUTE_PENDING_APPROVAL}
      state={{ from: location }}
      replace
    />
  );
};

export default ApprovedRoute;
