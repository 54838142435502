import React, { FC } from "react";
import { styled } from "@mui/material/styles";
import { ISelectedSkill } from "types/types";
import CheckMarkIcon from "assets/images/check-mark-icon.svg";
import ThinkingFaceIcon from "assets/images/thinking-face.svg";

const Skill = styled("div")`
  display: flex;
  align-items: center;
`;

const SkillName = styled("div")`
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: -0.01em;
  color: #9a9fa5;
  margin-left: 10px;
`;

interface IProps {
  skill: ISelectedSkill;
}

const SelectedSkill: FC<IProps> = ({ skill }) => {
  return (
    <Skill key={skill.name}>
      {skill.selected ? (
        <img width={14} height={21} src={CheckMarkIcon} alt="check mark icon" />
      ) : (
        <img
          width={14}
          height={21}
          src={ThinkingFaceIcon}
          alt="thinking face icon"
        />
      )}
      <SkillName>{skill.name}</SkillName>
    </Skill>
  );
};

export default SelectedSkill;
