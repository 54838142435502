import { styled } from "@mui/system";
import { _get } from "utils/lodash";

interface ErrorProps {
  error?: number;
}

export const Icon = styled("div")<ErrorProps>`
  color: ${({ theme, error }) =>
    error ? _get(theme, "palette.colors.red") : theme.palette.text.primary};
  display: inherit;
  padding-left: 6px;
`;

export const boxContainer = {
  marginBottom: "10px",
  display: "flex",
  alignItems: "center",
};

export const iconStyle = { height: "14px", width: "14px" };

export const FormLabel = styled("span")<ErrorProps>`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: -0.01em;
  color: ${({ theme, error }) =>
    error
      ? _get(theme, "palette.colors.red")
      : _get(theme, "custom.colors.orange")};
`;
