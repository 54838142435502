import React, { FC, MouseEventHandler } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
// import SharedButton from "components/shared/button/button";
// import BackArrow from "assets/images/arrow_backward.svg";
import PageOne from "./page-one";
import PageTwo from "./page-two";
import PageThree from "./page-three";

interface IProps {
  allStepsCompleted: Function;
  handleReset: MouseEventHandler;
  activeStep: number;
  reduxStateStatus: string;
  completed: {
    [k: number]: boolean;
  };
  handleNext: MouseEventHandler;
  handleBack: MouseEventHandler;
  steps: Array<string>;
  handleComplete: MouseEventHandler;
  completedSteps: Function;
  totalSteps: Function;
  handleSetState: Function;
  // eslint-disable-next-line
  handleSaveDescription: (data: any) => void;
  // eslint-disable-next-line
  handleSaveProfile: (data: any) => void;
  // eslint-disable-next-line
  handleSavePitch: (data: any) => void;
  state: {
    step_1: {
      checkboxChecked: boolean;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      editorState: any;
      job_description_link: string;
    };
    step_2: {
      skills_must_have: { [key: string]: string };
      skills_nice_to_have: { [key: string]: string };
      skills_not_to_have: { [key: string]: string };
    };
    step_3: {
      checkbox_share_salary_range: boolean;
      checkbox_pay_unknown: boolean;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      editorState: any;
      salaryRange: number[];
      job_exciting_things: { [key: string]: string };
      recruiter_notes: string;
    };
  };
}

const JobOnboardingForm: FC<IProps> = ({
  allStepsCompleted,
  handleReset,
  activeStep,
  // completed,
  // handleNext,
  handleSaveDescription,
  handleSaveProfile,
  handleSavePitch,
  handleBack,
  // handleComplete,
  // steps,
  // completedSteps,
  // totalSteps,
  handleSetState,
  state,
  reduxStateStatus,
}) => {
  return (
    <Box
      sx={{ padding: "24px", backgroundColor: "#FCFCFC", borderRadius: "8px" }}
    >
      {allStepsCompleted() ? (
        <React.Fragment>
          <Typography sx={{ mt: 2, mb: 1 }}>
            All steps completed - you&apos;re finished
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Box sx={{ flex: "1 1 auto" }} />
            <Button onClick={handleReset}>Reset</Button>
          </Box>
        </React.Fragment>
      ) : (
        <React.Fragment>
          {activeStep === 0 && (
            <PageOne
              handleSaveDescription={handleSaveDescription}
              handleSetState={handleSetState}
              state={state}
            />
          )}
          {activeStep === 1 && (
            <PageTwo
              handleBack={handleBack}
              state={state}
              handleSaveProfile={handleSaveProfile}
              handleSetState={handleSetState}
            />
          )}
          {activeStep === 2 && (
            <PageThree
              handleSetState={handleSetState}
              handleSavePitch={handleSavePitch}
              state={state}
              handleBack={handleBack}
              reduxStateStatus={reduxStateStatus}
            />
          )}
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            {/* <SharedButton
              variant="outlined"
              onClick={handleBack}
              sx={{
                width: "auto",
                mr: 1,
                "&.Mui-disabled": {
                  display: "none",
                },
              }}
              disabled={activeStep === 0}
            >
              <img src={BackArrow} alt="back arrow" />
              <Box sx={{ marginLeft: "6px", textTransform: "initial" }}>
                Back
              </Box>
            </SharedButton> */}
            <Box sx={{ flex: "1 1 auto" }} />
            {/* {activeStep !== steps.length &&
              (completed[activeStep] ? (
                <>
                  <SharedButton
                    variant="contained"
                    onClick={handleNext}
                    sx={{ width: "20%", mr: 1 }}
                  >
                    <Box sx={{ marginLeft: "6px", textTransform: "initial" }}>
                      Next
                    </Box>
                  </SharedButton>
                  <Typography
                    variant="caption"
                    sx={{ display: "inline-block" }}
                  >
                    Step {(activeStep as number) + 1} already completed
                  </Typography>
                </>
              ) : (
                <SharedButton
                  variant="contained"
                  onClick={handleComplete}
                  sx={{ width: "35%" }}
                >
                  <Box sx={{ marginLeft: "6px", textTransform: "initial" }}>
                    {completedSteps() === totalSteps() - 1
                      ? "Finish"
                      : "Continue"}
                  </Box>
                </SharedButton>
              ))} */}
          </Box>
        </React.Fragment>
      )}
    </Box>
  );
};

export default JobOnboardingForm;
