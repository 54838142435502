import { AnyAction, createSlice } from "@reduxjs/toolkit";
import { IUpdateUserState } from "./interface";
import { doUpdateUserAction } from "./update.actions";
import { ReduxStatus } from "types/types";

const initialState: IUpdateUserState = {
  status: ReduxStatus.empty,
  response: {},
  error: {},
};

export const updateUser = createSlice({
  name: "user/update",
  initialState,
  reducers: {
    increment: () => {
      // state.candidateList = [1, 2];
      // ✅ CORRECT: logs a plain JS copy of the current data
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(doUpdateUserAction.pending, (state: IUpdateUserState) => {
        state.status = ReduxStatus.pending;
      })
      .addCase(
        doUpdateUserAction.fulfilled,
        (state: IUpdateUserState, action: AnyAction) => {
          state.status = ReduxStatus.fulfilled;
          state.error = {};
          state.response = action.payload;
        }
      )
      .addCase(
        doUpdateUserAction.rejected,
        (state: IUpdateUserState, action: AnyAction) => {
          state.status = ReduxStatus.rejected;
          state.response = {};
          state.error = action.error;
        }
      );
  },
});

export const { increment } = updateUser.actions;
export default updateUser.reducer;
