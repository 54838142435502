import React from "react";
import { toast, TypeOptions } from "react-toastify";

import ToastMessage from "components/toast-message";

const types = ["success", "info", "warning", "error"];
const showNotification = () => {
  // use a random type of notification
  toast(
    <ToastMessage
      title="Candidate Shortlisted"
      body="Now tell us about an open job you have, and receive handpicked candidate profiles."
    />,
    {
      type: types[Math.floor(Math.random() * types.length)] as TypeOptions,
    }
  );
};

function ToastDemo() {
  return <button onClick={showNotification}>Add notification</button>;
}

export default ToastDemo;
