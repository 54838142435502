import React from "react";
// import InfoCard from "components/info-card/info-card";
import SharedButton from "components/shared/button/button";
import Text from "components/shared/text/text";
import Celebrate from "../../assets/images/celebrate.svg";
import Briefcase from "../../assets/images/Subtract.svg";
import Box from "@mui/material/Box";
import { styled, useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import {
  ROUTE_ACTIVE_JOBS,
  ROUTE_NEW_JOB,
  ROUTE_WELCOME,
} from "routes/route-items";
import { firestore, db } from "utils/firebase";
import { collection_name } from "utils/firebase-refs";
import { RootState, useAppDispatch } from "redux/store";
import { useSelector } from "react-redux";
import { doActionFetchUserAction } from "redux/services/user/user.actions";

const Wrapper = styled("div")`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 599px) {
    background: ${({ theme }) => theme.palette.grey[50]};
  }
`;

const Image = styled("img")`
  height: 160px;
  width: 160px;
  margin-bottom: 60px;
`;

const Welcome = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isLoading, setLoading] = React.useState(false);

  const userState = useSelector(
    (reduxState: RootState) => reduxState.user.response
  );

  const handleSkip = async () => {
    setLoading(true);
    if (userState.uid) {
      try {
        const userRef = firestore.doc(
          db,
          collection_name.v2_recruiters,
          userState.uid
        );

        await firestore.setDoc(
          userRef,
          { next_onboarding_stage: ROUTE_ACTIVE_JOBS },
          { merge: true }
        );
        dispatch(
          doActionFetchUserAction({
            uid: userState.uid,
            user: {
              ...userState,
              next_onboarding_stage: ROUTE_ACTIVE_JOBS,
            },
          })
        );
        navigate(`/${ROUTE_ACTIVE_JOBS}`);
      } catch (error) {
        setLoading(false);
        // Show Error here
      }
    } else {
      setLoading(false);
      // Show Error here
    }
  };

  const handleAddJob = () => {
    navigate(`/${ROUTE_NEW_JOB}`, { state: { from: ROUTE_WELCOME } });
  };

  return (
    <Wrapper>
      <Image src={Celebrate} alt="Hi" loading="lazy" />
      <Text
        variant="h2"
        fontSize="40px"
        fontWeight={600}
        lineHeight="48px"
        letterSpacing="-0.03em"
        sx={{
          marginBottom: "1rem",
          marginLeft: 0,
          textAlign: "center",
        }}
      >
        So far so good!
      </Text>
      <Text
        variant="body1"
        color={theme.palette.grey[400]}
        marginBottom="40px"
        sx={{
          marginLeft: 0,
          maxWidth: "30rem",
        }}
      >
        Now tell us about an open job you have, and receive handpicked candidate
        profiles.
      </Text>
      <SharedButton
        sx={{ minWidth: "16rem", width: "auto" }}
        variant="contained"
        onClick={handleAddJob}
      >
        <Box sx={{ marginRight: "20px", textTransform: "initial" }}>
          Create your first job
        </Box>
        <Image
          sx={{ height: 24, width: 24, marginBottom: 0 }}
          src={Briefcase}
          alt="Add"
        />
      </SharedButton>
      <SharedButton
        sx={{ minWidth: "16rem", width: "auto", marginTop: "20px" }}
        variant="outlined"
        onClick={handleSkip}
      >
        <Text
          sx={{
            lineHeight: "24px",
            fontWeight: 700,
            fontSize: "15px",
            letterSpacing: "-0.01em",
          }}
        >
          {isLoading ? "Loading ..." : "Skip"}
        </Text>
      </SharedButton>
    </Wrapper>
  );
};

export default Welcome;
