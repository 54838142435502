import React, { FC } from "react";
import Box from "@mui/material/Box";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import { styled } from "@mui/material/styles";

interface IProps {
  onClick: () => void;
  sx?: {};
}

const Button = styled(Box)`
  width: 140px;
  background: linear-gradient(
      0deg,
      rgba(234, 250, 228, 0.5),
      rgba(234, 250, 228, 0.5)
    ),
    #ffffff;
  border: 1px solid #83bf6e;
  border-radius: 4px;
  text-align: center;
  color: #4d9b32;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  & > span {
    padding-left: 10px;
  }
  &:hover {
    background: #ace1af;
  }
`;

const ShortlistButton: FC<IProps> = ({ sx, onClick }) => {
  return (
    <Button onClick={onClick} sx={sx}>
      <ThumbUpIcon sx={{ height: 20, width: 20 }} />
      <span>Shortlist</span>
    </Button>
  );
};

export default ShortlistButton;
