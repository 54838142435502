import Box from "@mui/material/Box";
import { styled, useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import React, { FC } from "react";
import STYLES from "./styles";
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import { ROUTE_SIGN_IN, ROUTE_SIGN_UP } from "routes/route-items";

interface IProps {}
interface TextProps {
  fontWeight?: number;
  fontSize?: string;
  lineHeight?: string;
  letterSpacing?: string;
  color?: string;
}

const Layout: FC<IProps> = () => {
  const app_theme = useTheme();
  const navigate = useNavigate();
  let location = useLocation();

  const SharedText = styled(Typography)<TextProps>`
    font-family: Inter;
    font-style: normal;
    font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : 600)};
    font-size: ${({ fontSize }) => (fontSize ? fontSize : "18px")};
    line-height: ${({ lineHeight }) => (lineHeight ? lineHeight : "32px")};
    text-align: center;
    letter-spacing: ${({ letterSpacing }) =>
      letterSpacing ? letterSpacing : "-0.02em"};
    margin-left: 10px;
    color: ${({ theme, color }) =>
      color ? color : theme.palette.primary.contrastText};
  `;

  const handleAuthRedirect = () => {
    if (location.pathname === `/${ROUTE_SIGN_IN}`) {
      navigate(`/${ROUTE_SIGN_UP}`);
    }
    if (location.pathname === `/${ROUTE_SIGN_UP}`) {
      navigate(`/${ROUTE_SIGN_IN}`);
    }
  };

  return (
    <Box
      display="flex"
      sx={{
        ...STYLES.layout_container,
      }}
    >
      <Box
        display="flex"
        alignItems="flex-start"
        justifyContent="center"
        flex="1"
        sx={{
          ...STYLES.layout_side,
          "@media (max-width: 760px)": {
            display: "none",
          },
        }}
      >
        <Box mt={18} display="flex" alignItems="center">
          {/* <Box sx={{ ...STYLES.layout_logo }} /> */}
          <SharedText fontSize="4rem">acelr8</SharedText>
        </Box>
      </Box>
      <Box display="flex" flexDirection="column" flex="1">
        {location.pathname === `/${ROUTE_SIGN_IN}` ||
          (location.pathname === `/${ROUTE_SIGN_UP}` && (
            <Box
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
              flex=".1"
              sx={{
                position: "absolute",
                right: "0",
                top: "24px",
                paddingRight: "2rem",
              }}
            >
              <Box display="flex">
                <SharedText
                  fontWeight={400}
                  fontSize="13px"
                  lineHeight="16px"
                  letterSpacing="-0.01em"
                  color={app_theme.palette.text.primary}
                >
                  Already have an account?
                </SharedText>
                <SharedText
                  fontWeight={700}
                  fontSize="12px"
                  lineHeight="16px"
                  letterSpacing="-0.01em"
                  color={app_theme.palette.text.secondary}
                  onClick={handleAuthRedirect}
                  sx={{
                    marginLeft: "5px",
                    cursor: "pointer",
                  }}
                >
                  {location.pathname === `/${ROUTE_SIGN_IN}`
                    ? "Sign up"
                    : location.pathname === `/${ROUTE_SIGN_UP}`
                    ? "Sign in"
                    : ""}
                </SharedText>
              </Box>
            </Box>
          ))}
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flex="1"
        >
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
};

export default Layout;
