/* eslint-disable @typescript-eslint/no-explicit-any */
import * as _ from "lodash";

export const _get = (obj = {}, path = "", defaultValue?: unknown) => {
  return _.get(obj, path, defaultValue);
};

export const sample = (collection: string[]) => {
  return _.sample(collection);
};

export const isEmpty = (value: unknown) => _.isEmpty(value);

export const isBoolean = (value: unknown) => _.isBoolean(value);

export const _findIndex = (arr = [], predicate: any, fromIndex?: number) => {
  return _.findIndex(arr, predicate, fromIndex);
};

export const _uniqBy = (arr: any[], iteratee: string) => {
  return _.uniqBy(arr, iteratee);
};

export const _first = (arr: any[]) => _.head(arr);

export const _sample = (arr: any[]) => _.sample(arr);
