import React, { FC } from "react";
import Text from "components/shared/text/text";
import { useTheme } from "@mui/material/styles";
import { ITheme } from "theme";

interface IProps {
  text: string;
}

const PanelText: FC<IProps> = ({ text }) => {
  const theme: ITheme = useTheme();
  return (
    <Text
      lineHeight="1.5"
      sx={{
        textAlign: "left",
        marginBottom: "20px",
        marginLeft: 0,
      }}
      fontSize="14px"
      fontWeight={400}
      color={theme.palette.grey[400]}
      letterSpacing="-0.01em"
    >
      {text}
    </Text>
  );
};

export default PanelText;
