import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  IUpdateCandidateInterviewPayload,
  IUpdateCandidateInterviewState,
} from "./interface";
import { doUpdateCandidateInterviewAction } from "./update.actions";
import { IInterviewStatus, IRoleType, ReduxStatus } from "types/types";

const initialResponse: IUpdateCandidateInterviewPayload = {
  job_id: "",
  candidate_id: "",
  updated_candidate: {
    interview_status: IInterviewStatus.PENDING,
    reason: "",
    updated_by: {
      firstName: "",
      lastName: "",
      role: IRoleType.RECRUITER,
      uid: "",
      updated_at: null,
    },
  },
};
const initialState: IUpdateCandidateInterviewState = {
  status: ReduxStatus.empty,
  response: initialResponse,
  error: {},
};

export const updateCandidateInterview = createSlice({
  name: "candidate/update-interview",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        doUpdateCandidateInterviewAction.pending,
        (state: IUpdateCandidateInterviewState) => {
          state.status = ReduxStatus.pending;
        }
      )
      .addCase(
        doUpdateCandidateInterviewAction.fulfilled,
        (
          state: IUpdateCandidateInterviewState,
          action: PayloadAction<IUpdateCandidateInterviewPayload>
        ) => {
          state.status = ReduxStatus.fulfilled;
          state.error = {};
          state.response = action.payload;
        }
      )
      .addCase(
        doUpdateCandidateInterviewAction.rejected,
        (
          state: IUpdateCandidateInterviewState,
          action: PayloadAction<unknown>
        ) => {
          state.status = ReduxStatus.rejected;
          state.response = initialResponse;
          state.error = action.payload;
        }
      );
  },
});

export default updateCandidateInterview.reducer;
