import React from "react";
import { styled } from "@mui/material/styles";
import Text from "components/shared/text/text";
import { ROUTE_ACTIVE_JOBS } from "routes/route-items";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import SharedButton from "components/shared/button/button";
import Image404 from "assets/images/404.svg";
import Logo from "components/shared/logo";

const NotFound = styled("div")`
  background: ${({ theme }) => theme.palette.grey[100]};
  padding: 24px;
  margin-top: 8px;
  border-radius: 8px;
  align-items: center;
  font-family: "Inter";
`;

const PageNotFound = () => {
  const navigate = useNavigate();
  let location = useLocation();

  const goBackToConnect = () => {
    const pathFrom = location.pathname.replace("/", "");
    navigate(`/${ROUTE_ACTIVE_JOBS}`, { state: { from: pathFrom } });
  };

  return (
    <NotFound
      sx={{
        height: "99vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Link to={`/${ROUTE_ACTIVE_JOBS}`}>
        <Logo
          sx={{
            position: "absolute",
            left: "12px",
            top: "31px",
          }}
        />
      </Link>
      <Box
        sx={{
          width: "42.49%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "right",
        }}
      >
        <Box
          sx={{
            marginTop: "100px",
          }}
        >
          <img
            width="364.81px"
            height="344px"
            src={Image404}
            alt="error 404 image"
          />
        </Box>
      </Box>
      <Box
        sx={{
          width: "57.51%",
          height: "100%",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            marginLeft: "200px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Text
            sx={{
              marginTop: "2rem",
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: 600,
              fontSize: "40px",
              lineHeight: "48px",
              letterSpacing: "-0.02em",
              // textAlign: "center",
              color: "#302c48",
            }}
          >
            Page Not Found
          </Text>
          <Text
            sx={{
              marginTop: "20px",
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: 500,
              fontSize: "15px",
              lineHeight: "24px",
              // textAlign: "center",
              letterSpacing: "-0.015em",
              color: "#302c48",
              mixBlendMode: "normal",
              opacity: 0.7,
            }}
          >
            The page you are looking for doesn't seem to exist...
          </Text>
          <SharedButton
            sx={{ marginTop: "20px", width: "auto", height: "48px" }}
            type="button"
            variant="contained"
            onClick={goBackToConnect}
          >
            <Box
              sx={{
                marginLeft: "6px",
                textTransform: "initial",
                display: "flex",
                alignItems: "center",
              }}
            >
              Go to homepage
            </Box>
          </SharedButton>
        </Box>
      </Box>
    </NotFound>
  );
};

export default PageNotFound;
