import React, { FC, useState } from "react";
import Box from "@mui/material/Box";
import { styled } from "@mui/system";
import ChoiceButton from "components/shared/button/choiceButton";
import Input from "components/shared/input/input";
import CheckBox from "components/shared/checkBox";
import { seniority_level, job_function } from "constants/constants";
import { _get, isBoolean, isEmpty } from "utils/lodash";
import Form from "components/shared/form/form";
import SharedButton from "components/shared/button/button";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { JobDescriptionSchema } from "utils/form-schema";
import SharedEditor from "components/shared/editor";
import { boxContainer, FormLabel } from "./shared";
import { Asterisk } from "components/shared/asterisk";

const FormContainer = styled(Box)`
  margin-bottom: 12px;
`;

const ModifiedChoiceButton = styled(ChoiceButton)`
  margin-bottom: 10px;
  margin-right: 10px;
`;

interface IProps {
  handleSetState: Function;
  // eslint-disable-next-line
  handleSaveDescription: (data: any) => void;
  state: {
    step_1: {
      checkboxChecked: boolean;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      editorState: any;
      job_description_link: string;
    };
  };
}

const PageOne: FC<IProps> = ({
  state,
  handleSetState,
  handleSaveDescription,
}) => {
  const jobState = _get(state, "step_1");
  const {
    register,
    handleSubmit,
    setValue,
    trigger,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(JobDescriptionSchema),
  });

  React.useEffect(() => {
    setValue("jobDescriptionLink", jobState.job_description_link);
    setValue("jobTitle", jobState.job_title);
    setValue("customJobFunction", jobState.custom_job_function);
  }, [
    jobState.job_description_link,
    jobState.job_title,
    // eslint-disable-next-line prettier/prettier
    jobState.custom_job_function
  ]);

  const [hasErrors, setHasErrors] = useState({
    job_function: jobState.selected_job_function ? false : undefined,
    seniority_level: jobState.selected_seniority_level ? false : undefined,
  });

  const renderSeniority = () => {
    const seniority_level_list = Object.keys(seniority_level) || [];
    if (seniority_level_list.length) {
      return seniority_level_list.map((el: string, i: number) => {
        const selected = jobState.selected_seniority_level === el;
        return (
          <ModifiedChoiceButton
            className={selected ? "selected" : ""}
            {...(selected && register("seniority_level"))}
            onClick={() => {
              handleSetState({
                value: el,
                key: "selected_seniority_level",
                step: "step_1",
              });
              setHasErrors((val) => ({
                ...val,
                seniority_level: false,
              }));
            }}
            key={`${i}-${el}`}
          >
            {el}
          </ModifiedChoiceButton>
        );
      });
    }
  };

  const renderJobFunction = () => {
    const seniority_level_list = Object.keys(job_function) || [];
    if (seniority_level_list.length) {
      return seniority_level_list.map((el: string, i: number) => {
        const selected = jobState.selected_job_function === el;
        return (
          <ModifiedChoiceButton
            className={selected ? "selected" : ""}
            {...(selected && register("job_function"))}
            onClick={() => {
              handleSetState({
                value: el,
                key: "selected_job_function",
                step: "step_1",
              });
              setHasErrors((val) => ({
                ...val,
                job_function: false,
              }));
            }}
            key={`${i}-${el}`}
          >
            {el}
          </ModifiedChoiceButton>
        );
      });
    }
  };

  const getErrorStatus = (level: string) => {
    const _status = _get(hasErrors, level, "");
    if (!isBoolean(_status) || !isEmpty(_status)) return false; // to ensure undefined or empty string returns true
    return _status;
  };

  const checkErrorStatus = () => {
    const values = getValues();
    const entries = Object.keys(values).filter(
      (value) =>
        !value.includes("jobDescriptionLink") ||
        !value.includes("customJobFunction")
    );

    const defaultErrorStates = {
      job_function: true,
      seniority_level: true,
    };

    if (!entries.length) {
      setHasErrors((val) => ({
        ...val,
        ...defaultErrorStates,
      }));
      return true;
    }

    const errors = entries.reduce(
      (acc: { [x: string]: boolean }, value: string) => {
        if (!acc) acc = {};
        acc[value] = false;
        return acc;
      },
      {}
    );

    let state = { ...defaultErrorStates, ...errors };
    setHasErrors((val) => ({
      ...val,
      ...state,
    }));
    return state.job_function || state.seniority_level;
  };

  const onFormSubmit = (e: React.MouseEvent<HTMLFormElement>) => {
    e.preventDefault();
    trigger("jobDescriptionLink").then((e) => e);
    trigger("jobTitle").then((e) => e);

    const values = getValues();

    if (jobState.selected_job_function !== "Other") {
      setValue("customJobFunction", jobState.selected_job_function);
      trigger("customJobFunction").then((e) => e);
      handleSetState({
        value: values.customJobFunction,
        key: "custom_job_function",
        step: "step_1",
      });
    } else {
      handleSetState({
        value: values.customJobFunction,
        key: "custom_job_function",
        step: "step_1",
      });
    }
    const hasErrors = checkErrorStatus();
    if (hasErrors) return;
    handleSubmit(handleSaveDescription)(e);
  };

  return (
    <Box>
      <Form onSubmit={onFormSubmit}>
        <FormContainer sx={{ marginBottom: "12px" }}>
          <Box sx={{ marginBottom: "10px" }}>
            <FormLabel> The job title for the position is</FormLabel>
            <Asterisk />
          </Box>
          <Box sx={{ marginBottom: "22px" }}>
            <Input
              id="jobTitle"
              register={register}
              variant="filled"
              placeholder="UI/UX Designer"
              errors={_get(errors, "jobTitle.message", null)}
              errorSx={{
                top: "20px",
              }}
            />
          </Box>
        </FormContainer>
        <FormContainer sx={{ marginBottom: "12px" }}>
          <Box sx={boxContainer}>
            <FormLabel error={+getErrorStatus("seniority_level")}>
              What seniority level is this role?
            </FormLabel>
            <Asterisk />
          </Box>
          <Box sx={{ display: "flex", flexWrap: "wrap" }}>
            {renderSeniority()}
          </Box>
        </FormContainer>
        <FormContainer sx={{ marginBottom: "12px" }}>
          <Box sx={boxContainer}>
            <FormLabel error={+getErrorStatus("job_function")}>
              What job function does this role belong to?
            </FormLabel>
            <Asterisk />
          </Box>
          <Box sx={{ display: "flex", flexWrap: "wrap" }}>
            {renderJobFunction()}
          </Box>
          {jobState.selected_job_function === "Other" && (
            <Box sx={{ marginTop: "10px", paddingBottom: "12px" }}>
              <Input
                id="customJobFunction"
                register={register}
                variant="filled"
                placeholder="Other Job function"
                errors={_get(errors, "customJobFunction.message", null)}
                errorSx={{
                  top: "20px",
                }}
              />
            </Box>
          )}
        </FormContainer>
        <FormContainer>
          <Box sx={{ marginBottom: "10px" }}>
            <FormLabel>Job description link</FormLabel>
          </Box>
          <Box>
            <Input
              id="jobDescriptionLink"
              register={register}
              variant="filled"
              placeholder="http://www.job_description.com"
              errors={_get(errors, "jobDescriptionLink.message", null)}
              errorSx={{
                top: "20px",
              }}
            />
          </Box>
        </FormContainer>
        <FormContainer sx={{ marginBottom: "14px" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              "& .MuiFormControlLabel-root": {
                marginRight: 0,
              },
            }}
          >
            <CheckBox
              sx={{
                marginLeft: 0,
              }}
              checked={state.step_1.checkboxChecked}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                handleSetState({
                  value: event.target.checked,
                  key: "checkboxChecked",
                  step: "step_1",
                });
              }}
            />
            <FormLabel>
              No link? Paste your job description text instead.
            </FormLabel>
          </Box>
        </FormContainer>
        {_get(state, "step_1.checkboxChecked") && (
          <FormContainer sx={{ marginBottom: "14px" }}>
            <Box sx={{ marginBottom: "10px" }}>
              <FormLabel>Job description text</FormLabel>
            </Box>
            <Box>
              <SharedEditor
                editorState={state.step_1.editorState}
                onEditorStateChange={(editorState) => {
                  handleSetState({
                    value: editorState,
                    key: "editorState",
                    step: "step_1",
                  });
                }}
              />
            </Box>
          </FormContainer>
        )}
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            flexDirection: "row",
            pt: 2,
          }}
        >
          <SharedButton variant="contained" type="submit" sx={{ width: "35%" }}>
            <Box sx={{ marginLeft: "6px", textTransform: "initial" }}>
              Continue
            </Box>
          </SharedButton>
        </Box>
      </Form>
    </Box>
  );
};

export default PageOne;
