import { AsyncThunk, createAsyncThunk } from "@reduxjs/toolkit";
import { IGetCompanyPayload } from "./interface";

export const doGetCompaniesAction: AsyncThunk<
  IGetCompanyPayload,
  IGetCompanyPayload,
  {}
> = createAsyncThunk("companies/get", async (payload, { rejectWithValue }) => {
  try {
    return payload;
  } catch (error: unknown) {
    return rejectWithValue(JSON.stringify(error));
  }
});
