import React from "react";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";

import ForwardArrow from "assets/images/forward-arrow.svg";
import CompanyCard from "./company-card";
import { ROUTE_COMPANIES } from "routes/route-items";

const CreateCompanyCard = () => {
  const navigate = useNavigate();

  const handleCreateNewCompany = async () => {
    navigate(`/${ROUTE_COMPANIES}/new-company`, {
      state: {
        from: `${ROUTE_COMPANIES}`,
        isNewCompany: true,
      },
    });
  };

  return (
    <>
      <CompanyCard
        onClick={() => handleCreateNewCompany()}
        className="new-company"
        sx={{
          display: "block",
        }}
      >
        <Box
          sx={{
            fontFamily: "Inter",
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "24px",
            lineHeight: "32px",
            letterSpacing: "-0.02em",
            color: "#2A2A2A",
          }}
        >
          Add a new company
        </Box>
        <Box sx={{ marginTop: "36px" }}>
          <img src={ForwardArrow} alt="" />
        </Box>
      </CompanyCard>
    </>
  );
};

export default CreateCompanyCard;
