import { AsyncThunk, createAsyncThunk } from "@reduxjs/toolkit";
import { IGetJobsPayload } from "./interface";
import * as axios from "api/axios";
const axiosInstance = axios.default;

export const doGetJobsAction: AsyncThunk<IGetJobsPayload, IGetJobsPayload, {}> =
  createAsyncThunk("jobs/get", async (payload, { rejectWithValue }) => {
    if (payload.jobs) {
      return payload;
    }
    try {
      // ?? Needs to be sorted: Not currently being used
      // ?? Structure set fot http request is set: params needs to be sorted
      await axiosInstance.get("/jobs", {
        params: {
          job_id: "",
          fetchCandidates: false,
        },
      });

      return payload;
    } catch (error: unknown) {
      return rejectWithValue(JSON.stringify(error));
    }
  });
