/* eslint-disable no-unused-vars */
import React from "react";
import Text from "components/shared/text/text";
import { Box, Stack, useTheme } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ErrorIcon from "@mui/icons-material/Error";
import { ITheme } from "theme";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import ToastMessage from "components/toast-message";
import SharedTooltip from "../shared/tooltip/tooltip";
import { IInvitedMember } from "types/types";

interface Props {
  invite_list: IInvitedMember[];
  heading?: string;
  isInvite?: boolean;
  revokeMessage: string;
  tooltip: string;
}
const InvitesAndMembers: React.FC<Props> = ({
  heading,
  isInvite,
  invite_list,
  revokeMessage,
  tooltip,
}) => {
  const theme: ITheme = useTheme();
  const [copiedEmail, setCopiedEmail] = React.useState<string>("");
  const copyToClipboard = (invited_data: IInvitedMember) => {
    setCopiedEmail(invited_data.receiversEmail);
    toast(
      <ToastMessage
        title="Copy to clipboard"
        body="Invite link copied to clipboard"
      />,
      {
        type: "info",
        position: "top-right",
      }
    );
  };

  return (
    <Stack
      sx={{
        width: "100%",
        mt: "15px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          mb: "14px",
          flexDirection: "row",
        }}
      >
        <Text
          variant="body2"
          fontFamily={"Inter"}
          fontStyle={"normal"}
          fontWeight={400}
          fontSize={"14px"}
          lineHeight={"24px"}
          sx={{
            ml: 0,
          }}
        >
          {heading}
        </Text>
        <SharedTooltip title={tooltip}>
          <ErrorIcon
            sx={{
              color: theme.palette.text.primary,
              height: "13.33px",
              ml: "5px",
            }}
          />
        </SharedTooltip>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          width: "100%",
          padding: "12px",
          mb: "32px",
          borderRadius: "12px",
          background: theme.palette.grey[900],
        }}
      >
        {invite_list.map((el) => (
          <Box
            key={el.receiversEmail}
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              width: "100%",
              padding: "12px",
              borderRadius: "12px",
              background: theme.palette.grey[900],
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                width: "100%",
                height: "24px",
                color: theme.custom.colors.orange,
                justifyContent: "space-between",
              }}
            >
              <Text
                variant="body2"
                fontFamily={"Inter"}
                fontStyle={"normal"}
                fontWeight={400}
                fontSize={"14px"}
              >
                {el.receiversEmail}
              </Text>
              {isInvite && (
                <CopyToClipboard
                  text={el.invite_link}
                  onCopy={() => copyToClipboard(el)}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "row",
                      cursor: "pointer",
                      color:
                        copiedEmail === el.receiversEmail
                          ? theme.palette.secondary.main
                          : "inherit",
                    }}
                    // onClick={() => copyToClipboard(el)}
                  >
                    <ContentCopyIcon
                      sx={{
                        color:
                          (copiedEmail === el.receiversEmail &&
                            theme.palette.secondary.main) ||
                          "",
                        height: "13.33px",
                        ml: "5px",
                      }}
                    />
                    <Text
                      variant="body2"
                      fontFamily={"Inter"}
                      fontStyle={"normal"}
                      fontWeight={400}
                      fontSize={"14px"}
                      lineHeight={"24px"}
                      sx={{
                        ml: 0,
                        color:
                          (copiedEmail === el.receiversEmail &&
                            theme.palette.secondary.main) ||
                          "",
                      }}
                    >
                      copy invite link
                    </Text>
                  </Box>
                </CopyToClipboard>
              )}
              {/* <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                  cursor: "pointer",
                }}
              >
                <CloseIcon
                  sx={{
                    color: theme.palette.text.primary,
                    height: "13.33px",
                  }}
                />
                <Text
                  variant="body2"
                  fontFamily={"Inter"}
                  fontStyle={"normal"}
                  fontWeight={400}
                  fontSize={"14px"}
                  lineHeight={"24px"}
                  sx={{
                    ml: 0,
                    color: theme.palette.text.secondary,
                  }}
                >
                  {revokeMessage}
                </Text>
              </Box> */}
            </Box>
          </Box>
        ))}
      </Box>
    </Stack>
  );
};

export default InvitesAndMembers;
