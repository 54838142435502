/* eslint-disable no-undef */
/* eslint-disable prettier/prettier */
import SignIn from "pages/auth/sign-in";
import SignUp from "pages/auth/sign-up";
import ForgotPassword from "pages/auth/forgot-password";

import PrivacyPolicy from "pages/privacy-policy";

import Companies from "pages/companies";

import Jobs from "pages/jobs";
import Job from "pages/job";

import Integrations from "pages/integrations";
import FAQs from "pages/faqs";

import Building from "pages/building";
import Company from "pages/company";
import Welcome from "pages/welcome";
import Home from "pages/home";
import JobPageNewJob from "pages/new-job";
import JobOnboardingComplete from "pages/job-onboarding-complete";
import React from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import PrivateRoute from "./private-routes";
import * as ROUTES from "./route-items";
import PublicRoute from "./public-routes";
import Layout from "layouts/layout";
import { RootState } from "redux/store";
import { useSelector } from "react-redux";
import Settings from "pages/settings";
import CompanyProfile from "pages/company-profile";
import PendingApproval from "pages/pending-approval";
import ApprovedRoute from "./approved-routes";
import DesktopRoute from "./desktop-routes";
import PageNotFound from "pages/page-not-found";
import MobileNotice from "pages/mobile-notice";
import ReactGA from "react-ga";
import { IRoleType } from "types/types";
import { init } from "commandbar";
import { config } from "config";

/*below is not a real route, but a placeholder for the page loader
test while signed out*/
import PageLoader from "components/page-loader";

/*Just a demo route for toasts*/
import ToastDemo from "pages/toast-demo";

const is_prod = config.REACT_APP_IS_PRODUCTION;
const organisationId = config.REACT_APP_COMMANDBAR_ORGANISATION_ID as string;
init(organisationId, { environment: is_prod ? "Production" : "Staging" });

const RouteComponent = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const userState = useSelector(
    (reduxState: RootState) => reduxState.user.response
  );

  const APP_ID = config.REACT_APP_ALGOLIA_APP_ID as string;
  const SEARCH_KEY = config.REACT_APP_ALGOLIA_SEARCH_KEY as string;

  React.useEffect(() => {
    if (userState?.is_active) {
      if (location.pathname === `/${ROUTES.ROUTE_PENDING_APPROVAL}`) {
        return navigate(`/${ROUTES.ROUTE_ACTIVE_JOBS}`);
      }

      return navigate(location.pathname);
    }

    return navigate(`/${ROUTES.ROUTE_PENDING_APPROVAL}`);
  }, [userState?.is_active]);

  const fetchCompanies = () => {
    const requestOptions = {
      method: "POST",
      headers: new Headers({
        "X-Algolia-Application-Id": APP_ID,
        "X-Algolia-API-Key": SEARCH_KEY,
      }),
    };

    window.CommandBar.addRecords("v2_company", [], {
      onInputChange: async (query: unknown) => {
        const response = await fetch(
          `https://${APP_ID}-dsn.algolia.net/1/indexes/v2_company/query`,
          {
            ...requestOptions,
            body: JSON.stringify({ query }),
          }
        );
        const { hits } = await response.json();
        return hits;
      },
    });
  };

  const fetchJobs = () => {
    const requestOptions = {
      method: "POST",
      headers: new Headers({
        "X-Algolia-Application-Id": APP_ID,
        "X-Algolia-API-Key": SEARCH_KEY,
      }),
    };

    window.CommandBar.addRecords("v2_jobs", [], {
      onInputChange: async (query: unknown) => {
        const response = await fetch(
          `https://${APP_ID}-dsn.algolia.net/1/indexes/v2_jobs/query`,
          {
            ...requestOptions,
            body: JSON.stringify({
              query,
            }),
          }
        );
        const { hits } = await response.json();
        return hits;
      },
    });
  };

  React.useEffect(() => {
    if (is_prod) {
      const UA_ID =
        config.REACT_APP_GOOGLE_UNIVERSIAL_ANALYTICS_PROPERTY_ID as string;
      ReactGA.initialize(UA_ID);
    }
  }, []);

  React.useEffect(() => {
    if (userState?.uid && is_prod) {
      ReactGA.set({
        userId: userState.uid,
      });
    }

    if (userState?.uid && userState?.is_active) {
      window.CommandBar?.boot(userState?.uid);

      const routerFunction = (url: string) => navigate(url);
      window.CommandBar.addRouter(routerFunction);

      const isSlackEnabled = userState?.integrations?.Slack?.selected;
      window.CommandBar.addMetadata("role", IRoleType.RECRUITER);
      window.CommandBar.addMetadata("isSlackEnabled", isSlackEnabled);

      fetchJobs();
      fetchCompanies();
    } else {
      window.CommandBar?.shutdown();
    }

    return () => {
      window.CommandBar?.shutdown();
    };
  }, [userState?.uid, userState?.is_active]);

  React.useEffect(() => {
    if (is_prod) {
      ReactGA.pageview(`${location.pathname}${location.search}`);
    }
  }, [location]);

  return (
    <Routes>
      <Route element={<DesktopRoute />}>
        <Route element={<PublicRoute user={userState} />}>
          <Route path="/" element={<Layout />}>
            <Route index element={<SignIn />} />
            <Route path={ROUTES.ROUTE_SIGN_IN} element={<SignIn />} />
            <Route path={ROUTES.ROUTE_SIGN_UP} element={<SignUp />} />
            <Route
              path={ROUTES.ROUTE_FORGOT_PASSWORD}
              element={<ForgotPassword />}
            />
          </Route>
          <Route
            path={ROUTES.ROUTE_LOADER}
            element={<PageLoader text="Connect..." />}
          />
          <Route path={ROUTES.ROUTE_TOAST_DEMO} element={<ToastDemo />} />
          <Route
            path={ROUTES.ROUTE_PRIVACY_POLICY}
            element={<PrivacyPolicy />}
          />
        </Route>
        <Route element={<PrivateRoute user={userState} />}>
          <Route element={<ApprovedRoute user={userState} />}>
            <Route element={<Layout />}>
              <Route path={ROUTES.ROUTE_COMPANY} element={<Company />} />
              <Route path={ROUTES.ROUTE_BUILDING} element={<Building />} />
              <Route path={ROUTES.ROUTE_WELCOME} element={<Welcome />} />
              <Route
                path={ROUTES.ROUTE_JOB_ONBOARDING_COMPLETE}
                element={<JobOnboardingComplete />}
              />
            </Route>
            <Route path={ROUTES.ROUTE_NEW_JOB} element={<JobPageNewJob />} />
            <Route path={ROUTES.ROUTE_EDIT_JOB} element={<JobPageNewJob />} />

            <Route path={ROUTES.ROUTE_HOME} element={<Home />} />
            <Route path={ROUTES.ROUTE_ACTIVE_JOBS} element={<Jobs />} />
            <Route path={ROUTES.ROUTE_ACTIVE_JOB} element={<Job />} />
            <Route path={ROUTES.ROUTE_ACTIVE_JOB_CANDIDATE} element={<Job />} />
            <Route path={ROUTES.ROUTE_CANDIDATE_REJECT} element={<Job />} />
            <Route path={ROUTES.ROUTE_CANDIDATE_SHORTLIST} element={<Job />} />
            <Route path={ROUTES.ROUTE_ADD_JOB_CANDIDATE} element={<Job />} />
            <Route path={ROUTES.ROUTE_EDIT_JOB_CANDIDATE} element={<Job />} />
            <Route path={ROUTES.ROUTE_COMPANIES} element={<Companies />} />
            <Route path={ROUTES.ROUTE_SETTINGS} element={<Settings />} />
            <Route
              path={ROUTES.ROUTE_COMPANY_PROFILE}
              element={<CompanyProfile />}
            />
            <Route
              path={ROUTES.ROUTE_EDIT_COMPANY}
              element={<CompanyProfile />}
            />

            {/* <Route path={ROUTES.ROUTE_SLACK_CONNECT} element={<SlackConnect />} />
        <Route
          path={ROUTES.ROUTE_SLACK_CONNECT_SUCCESS}
          element={<SlackConnected />}
        /> */}
            <Route path={ROUTES.ROUTE_WELCOME} element={<Welcome />} />
            <Route
              path={ROUTES.ROUTE_INTEGRATIONS}
              element={<Integrations />}
            />
            <Route path={ROUTES.ROUTE_FAQS} element={<FAQs />} />
          </Route>
          <Route
            path={ROUTES.ROUTE_PENDING_APPROVAL}
            element={<PendingApproval />}
          />
        </Route>
      </Route>
      <Route path={ROUTES.ROUTE_MOBILE_NOTICE} element={<MobileNotice />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};

export default RouteComponent;
