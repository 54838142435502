/* eslint-disable no-unused-vars */
import * as React from "react";

import Pen from "assets/images/pen_dark.svg";
import Person from "assets/images/person_light.svg";
import Loader from "assets/images/spinner.svg";
import BackArrow from "assets/images/arrow_backward.svg";

import SharedButton from "components/shared/button/button";
import Candidate from "components/job/candidate";
import NoCandidates from "components/job/no-candidates";
import DateRangePicker from "components/shared/daterangepicker";
import SelectButton from "components/job/select-button";
import SharedTooltip from "components/shared/tooltip/tooltip";
import CandidateDetails from "components/candidates/candidate-details";
import CandidateOnboardingForm from "components/candidates/candidate-onboarding";

import Box from "@mui/material/Box";
import { useTheme } from "@mui/styles";
import InfoIcon from "@mui/icons-material/Info";
import CalendarTodayIconOutlined from "@mui/icons-material/CalendarTodayOutlined";

import { _get } from "utils/lodash";
import {
  collection,
  db,
  doc,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
} from "utils/firebase";
import { collection_name } from "utils/firebase-refs";

import { RootState, useAppDispatch } from "redux/store";
import { doGetCandidatesAction } from "redux/services/candidates/getCandidates/get.actions";
import { doCreateJobAction } from "redux/services/job/create/create.actions";

import PrivateLayout from "layouts/drawer";
import {
  IConnectedCandidate,
  IDBVersion,
  IInterviewStatus,
  IJob,
  IRoleType,
  TCandidateUpdate,
} from "types/types";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { ROUTE_ACTIVE_JOBS, ROUTE_EDIT_JOB } from "routes/route-items";
import { ITheme } from "theme";
import { useSelector } from "react-redux";
import {
  Spinner,
  Candidates,
  FilterGroup,
  Value,
  Subtitle,
  Statistics,
  Results,
  ResultsDivider,
  CandidatesArea,
  ButtonGroup,
  Title,
  Header,
} from "./styles";
import EditIcon from "assets/images/edit-pen.svg";
import CandidatesNeededDialog from "components/job/candidates-needed-dialog";
import { doUpdateJobAction } from "redux/services/job/update/update.actions";
import ToastMessage from "components/toast-message";
import { toast } from "react-toastify";
import Span from "components/shared/span/span";

enum Filters {
  PENDING = "PENDING",
  SHORTLISTED = "SHORTLISTED",
  REJECTED = "REJECTED",
  ARCHIVED = "archived",
  ALL = "ALL",
}

const TitleIcon: any = {
  "Software Engineering": "💻 ",
  "Product Design": "🎨 ",
  Marketing: "📢 ",
  Sales: "☎️ ",
  "Product Management": "📈 ",
  "Customer Success": "🤝 ",
  Other: "✍️ ️",
};

const Job = () => {
  // Actions & HOF
  const dispatch = useAppDispatch();
  const theme: ITheme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const { id, candidateID } = useParams();

  // Redux State
  const userState = useSelector(
    (reduxState: RootState) => reduxState.user.response
  );
  const candidatesReduxState = useSelector(
    (reduxState: RootState) => reduxState.candidatesGet
  );
  const jobUpdateReduxState = useSelector(
    (reduxState: RootState) => reduxState.jobUpdate
  );

  const candidates = candidatesReduxState.response.candidates || [];

  const updated_by: TCandidateUpdate = {
    uid: userState.uid,
    role: IRoleType.RECRUITER,
    firstName: userState.firstName,
    lastName: userState.lastName,
    updated_at: serverTimestamp(),
  };

  const initialCandidate: IConnectedCandidate = {
    firstName: "",
    lastName: "",
    email: "",
    created_at: "",
    candidate_id: "",
    candidate_avatar_url: "",
    candidate_experiences: [],
    connected_links: [],
    educations: [],
    experience: [],
    selectedJobs: [],
    skills: [],
    interview_status: IInterviewStatus.PENDING,
    currency: "",
    experience_level: "",
    is_open_to_remote: false,
    is_archived: false,
    creation_type: "",
    jobTitle: "",
    job_function: "",
    job_id: "",
    company_id: "",
    linked_in: "",
    linkedin_id: "",
    original_avatar_url: "",
    recruiter_notes: "",
    location: "",
    portfolio: "",
    thumbnail_avatar_url: "",
    reasonForNotRelevance: "",
    source: "",
    hasAttachedCV: false,
    max_salary_per_year: 0,
    min_salary_per_year: 0,
    file: "",
    reason: "",
    updated_at: null,
    updated_by,
    currentCompany: "",
    IDBVersion: IDBVersion.V2_CANDIDATES,
  };

  // State
  const [job, setJob] = React.useState<IJob>({});
  const [candidatesList, setCandidatesList] = React.useState<
    IConnectedCandidate[]
  >([]);
  const [filter, setFilter] = React.useState<Filters>(Filters.ALL);
  const [startDate, setStartDate] = React.useState<Date | null>(new Date());
  const [endDate, setEndDate] = React.useState<Date | null>(null);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [openCandidateProfileModal, setOpenCandidateProfileModal] =
    React.useState(false);
  const [candidateURL, setCandidateURL] = React.useState<string | null>(null);
  const [selectedCandidate, setSelectedCandidate] =
    React.useState(initialCandidate);
  const [loadingStatus, setLoadingStatus] = React.useState(true);
  const [openCandidateOnboardingModal, setOpenCandidateOnboardingModal] =
    React.useState(false);
  const [openNeededCandidatesDialog, setOpenNeededCandidatesDialog] =
    React.useState(false);
  const [invalidCandidatesNeeded, setInvalidCandidatesNeeded] =
    React.useState(false);
  const [candidatesNeeded, setCandidatesNeeded] = React.useState<number>(0);

  const handleOpenNeededCandidatesDialog = () => {
    setCandidatesNeeded(job?.attached_candidates?.NEEDED || 0);
    setOpenNeededCandidatesDialog(true);
  };

  const handleAcceptDialog = async () => {
    const uid = job?.created_by as string;
    const job_id = job.job_id as string;
    const NEEDED = +candidatesNeeded;

    if (!job_id) {
      toast(<ToastMessage title="Job" body="Missing job details" />, {
        type: "error",
        position: "top-right",
      });
      return;
    }

    await dispatch(
      doUpdateJobAction({
        job_id,
        job: {
          attached_candidates: {
            NEEDED,
          },
        },
      })
    );
    setOpenNeededCandidatesDialog(false);

    toast(
      <ToastMessage
        title="Candidates needed"
        body="Updated ready-interview candidates needed"
      />,
      {
        type: "success",
        position: "top-right",
      }
    );
  };

  const handleCloseDialog = () => {
    if (jobUpdateReduxState.status === "pending") {
      return;
    }

    setOpenNeededCandidatesDialog(false);
  };

  const handleInputChange = (e: any) => {
    setInvalidCandidatesNeeded(false);

    if (!e.target.value || isNaN(e.target.value) || e.target.value < 0) {
      setInvalidCandidatesNeeded(true);
    }
    setCandidatesNeeded(e.target.value);
  };

  // Methods
  const handleModalOpen = (candidate: IConnectedCandidate) => {
    setOpenCandidateProfileModal(true);
    setSelectedCandidate(candidate);
    setCandidateURL(candidate.candidate_id);
    navigate(
      `/${ROUTE_ACTIVE_JOBS}/${id}/candidate/${candidate.candidate_id}`,
      {
        state: {
          from: `${ROUTE_ACTIVE_JOBS}/${id}`,
        },
      }
    );
  };

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const button_id = open ? "calendar-popover" : undefined;
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleFilter = (
    filter: Filters,
    startDate: Date | null,
    endDate: Date | null
  ) => {
    setFilter(filter);
    const filter_ = filter as unknown;
    const start_date = startDate as Date;
    let end_date = endDate as Date;
    end_date?.setHours(23, 59, 59, 999);
    const start_date_milliseconds = start_date?.getTime();
    const end_date_milliseconds = end_date?.getTime();
    const list =
      filter === Filters.ALL
        ? candidates.filter((candidate: IConnectedCandidate) => {
            if (startDate && endDate) {
              return (
                candidate.created_at >= start_date_milliseconds &&
                candidate.created_at <= end_date_milliseconds
              );
            }
            return candidate;
          })
        : candidates.filter((candidate: IConnectedCandidate) => {
            if (startDate && endDate) {
              return (
                candidate.created_at >= start_date_milliseconds &&
                candidate.created_at <= end_date_milliseconds &&
                candidate.interview_status === filter_
              );
            }
            return candidate.interview_status === filter_;
          });
    setCandidatesList(list);
  };

  const handleModalClose = () => {
    setStartDate(null);
    setEndDate(null);
    handleFilter(Filters.ALL, null, null);
    setOpenCandidateProfileModal(false);
    setSelectedCandidate(initialCandidate);
    setCandidateURL(null);
    navigate(`/${ROUTE_ACTIVE_JOBS}/${id}`, {
      state: {
        from: `${ROUTE_ACTIVE_JOBS}/${id}/candidate/${candidateURL}`,
      },
    });
  };

  const onDateRangeChange = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    if (end) {
      handleFilter(filter, start, end);
      handleClose();
    }
  };

  const clearDates = () => {
    setStartDate(null);
    setEndDate(null);
    handleFilter(filter, null, null);
    handleClose();
  };

  const switchCandidate = (move: "previous" | "next") => {
    const currentIndex = candidatesList?.indexOf(selectedCandidate);
    const firstCandidate = move === "previous" && currentIndex === 0;
    const lastCandidate =
      move === "next" && currentIndex === candidatesList.length - 1;

    if (firstCandidate || lastCandidate) {
      return;
    }

    let candidate: IConnectedCandidate;

    if (move === "next") {
      candidate = candidatesList[currentIndex + 1];
    } else {
      candidate = candidatesList[currentIndex - 1];
    }

    setSelectedCandidate(candidate);
    navigate(
      `/${ROUTE_ACTIVE_JOBS}/${id}/candidate/${candidate.candidate_id}`,
      {
        state: {
          from: `${ROUTE_ACTIVE_JOBS}/${id}/candidate/${selectedCandidate.candidate_id}`,
        },
      }
    );
  };

  const handleClickEditJob = async () => {
    await dispatch(
      doCreateJobAction({
        job: {
          seniority_level: job.seniority_level,
          job_function: job.job_function,
          custom_job_function: job.custom_job_function,
          job_description_link: job.job_description_link,
          job_description: job.job_description,
          job_title: job.job_title,
          company_id: job.company_id || "",
          company_name: job.company_name || "",
          created_by: job.created_by || "",
          skills_nice_to_have: job.skills_nice_to_have,
          skills_not_to_have: job.skills_not_to_have,
          skills_must_have: job.skills_must_have,
          job_exciting_things: job.job_exciting_things,
          salary_range: job.salary_range,
          selected_currency: job.selected_currency,
          checkbox_pay_unknown: job.checkbox_pay_unknown,
          checkbox_share_salary_range: job.checkbox_share_salary_range,
          recruiter_notes: job.recruiter_notes,
        },
      })
    );
    navigate(`/${ROUTE_EDIT_JOB}`, {
      state: {
        from: ROUTE_ACTIVE_JOBS,
        uid: job.created_by,
        job_id: job.job_id,
        company_options: {
          company_id: job.company_id,
          company_name: job.company_name,
        },
      },
    });
  };

  const navigateToCandidateOnboarding = () => {
    navigate(`/${ROUTE_ACTIVE_JOBS}/${id}/create-candidate`, {
      state: {
        from: `${ROUTE_ACTIVE_JOBS}/${id}`,
      },
    });
  };

  const handleBack = () => {
    navigate(`/${ROUTE_ACTIVE_JOBS}`);
  };

  const closeCandidateOnboardingModal = () => {
    const pathname = location.pathname;
    const fromAddCandidate = pathname.includes("/create-candidate");
    const to = fromAddCandidate
      ? `/${ROUTE_ACTIVE_JOBS}/${id}`
      : `/${ROUTE_ACTIVE_JOBS}/${id}/candidate/${selectedCandidate?.candidate_id}`;
    const from = fromAddCandidate
      ? `${ROUTE_ACTIVE_JOBS}/${id}/create-candidate`
      : `/${ROUTE_ACTIVE_JOBS}/${id}/candidate/${selectedCandidate?.candidate_id}/edit-candidate`;
    navigate(to, {
      state: {
        from,
      },
    });
    setOpenCandidateOnboardingModal(false);
  };

  // Effects
  React.useEffect(() => {
    const pathname = location.pathname;
    if (candidateID && candidates.length) {
      const cand = candidates.find(
        (c: IConnectedCandidate) => c.candidate_id === candidateID
      ) as IConnectedCandidate;
      if (cand) {
        setSelectedCandidate(cand);
      }
      if (pathname.includes("/candidate/")) {
        setOpenCandidateProfileModal(true);
      }
    }
  }, [id, candidates]);

  const fetchCandidates = React.useCallback(async () => {
    const job_id = id as string;
    const candidatesQuery = query(
      collection(db, `v2_jobs/${job_id}/connected_candidates`),
      orderBy("created_at", "desc")
    );
    const unsubscribe = onSnapshot(candidatesQuery, async (querySnapshot) => {
      const candidates = querySnapshot.docs.map((doc) => {
        let candidate = doc.data() as IConnectedCandidate;
        candidate = {
          ...candidate,
          created_at: candidate.created_at?.seconds * 1000,
          updated_at: candidate.updated_at?.seconds * 1000,
        };
        return candidate;
      });
      await dispatch(
        doGetCandidatesAction({
          job_id,
          candidates,
        })
      );
      setLoadingStatus(false);
    });
    return () => {
      unsubscribe();
    };
  }, [id]);

  React.useEffect(() => {
    fetchCandidates();
  }, [fetchCandidates]);

  React.useEffect(() => {
    handleFilter(filter, startDate, endDate);
  }, [candidates]);

  React.useEffect(() => {
    const pathname = location.pathname;
    const openModal =
      pathname.includes("/create-candidate") ||
      pathname.includes("/edit-candidate");
    setOpenCandidateOnboardingModal(openModal);
  }, [location, id]);

  // Callback
  const fetchJob = React.useCallback(async () => {
    const unsubscribe = onSnapshot(
      doc(db, `${collection_name.v2_jobs}/${id}`),
      (doc) => {
        const job_data: IJob = { ...doc.data(), job_id: doc.id };
        setJob(job_data);
        setCandidatesNeeded(job_data?.attached_candidates?.NEEDED || 0);
      }
    );
    return () => {
      unsubscribe();
    };
  }, [id]);

  React.useEffect(() => {
    fetchJob();
  }, [fetchJob]);

  // Constants && Computed props
  const hasCandidates = candidates.length > 0;
  const interested_candidates =
    (job?.attached_candidates?.total || 0) -
    (job?.attached_candidates?.archived || 0);

  const conversion_percentage = () => {
    const shortlisted = job?.attached_candidates?.SHORTLISTED || 0;
    if (interested_candidates < 1) {
      return 0;
    }
    return Math.round((shortlisted / interested_candidates) * 100);
  };

  return (
    <PrivateLayout>
      <Header>
        <Title>
          <span>{TitleIcon[`${job?.job_function}`]}</span>
          {job?.job_title}
        </Title>
        <ButtonGroup>
          <SharedButton
            sx={{
              width: "auto",
              background: "white",
              border: `1px solid ${_get(theme, "palette.grey.300")}`,
              color: _get(theme, "palette.text.secondary"),
              height: "48px",
              "&:hover": {
                background: (theme) => _get(theme, "palette.colors.light_grey"),
                border: (theme) =>
                  `1px solid ${_get(theme, "palette.colors.light_grey")}`,
              },
            }}
            variant="contained"
            onClick={handleBack}
          >
            <img src={BackArrow} alt="Back" />
            <Box sx={{ marginLeft: "8px", textTransform: "initial" }}>Back</Box>
          </SharedButton>
          <SharedButton
            onClick={handleClickEditJob}
            sx={{
              width: "auto",
              background: "white",
              border: `1px solid ${_get(theme, "palette.grey.300")}`,
              color: "#1A1D1F",
              height: "48px",
              "&:hover": {
                background: (theme) => _get(theme, "palette.colors.light_grey"),
                border: (theme) =>
                  `1px solid ${_get(theme, "palette.colors.light_grey")}`,
              },
            }}
            variant="contained"
          >
            <img src={Pen} alt="Edit" />
            <Box sx={{ marginLeft: "8px", textTransform: "initial" }}>
              Edit Job Posting
            </Box>
          </SharedButton>
          <SharedButton
            onClick={navigateToCandidateOnboarding}
            sx={{ width: "auto", height: "48px" }}
            variant="contained"
          >
            <img src={Person} alt="Person" />
            <Box sx={{ marginLeft: "8px", textTransform: "initial" }}>
              Add Candidate
            </Box>
          </SharedButton>
        </ButtonGroup>
      </Header>

      <Statistics>
        <Results>
          <Subtitle>
            Interested Candidates
            <SharedTooltip title="This shows the total number of candidates that were sourced by our recruiters and have expressed their interest in having a first call.">
              <InfoIcon
                sx={{
                  ml: "5px",
                  height: "13.33px",
                  width: "13.33px",
                  left: "1.33",
                  top: "1.33px",
                  bordeRadius: "0px",
                }}
              />
            </SharedTooltip>
          </Subtitle>
          <Value>{interested_candidates}</Value>
        </Results>
        <ResultsDivider />
        <Results>
          <Subtitle>
            Shortlisted Candidates
            <SharedTooltip title="This shows the number of candidates that you chose to interview, out of all interested candidates for this role.">
              <InfoIcon
                sx={{
                  ml: "5px",
                  height: "13.33px",
                  width: "13.33px",
                  left: "1.33",
                  top: "1.33px",
                  borderRadius: "0px",
                }}
              />
            </SharedTooltip>
          </Subtitle>
          <Value>{job?.attached_candidates?.SHORTLISTED || 0}</Value>
        </Results>
        <ResultsDivider />
        <Results>
          <Subtitle>Conversion to Shortlisted</Subtitle>
          <Value>
            <>
              {conversion_percentage()}
              <Span
                sx={{
                  width: "13px",
                  height: "15px",
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: 600,
                  fontSize: "15px",
                  lineHeight: "100%",
                  letterSpacing: "-0.01em",
                  color: "#33383f",
                }}
              >
                %
              </Span>
            </>
          </Value>
        </Results>
        <ResultsDivider />
        <Results>
          <Subtitle>Total Shortlisted Candidates Needed</Subtitle>
          <Box sx={{ width: "auto", display: "flex" }}>
            <Value
              sx={{
                position: "relative",
                paddingRight: "28px",
                paddingBottom: "4px",
              }}
            >
              {job?.attached_candidates?.NEEDED || 0}
              <Box
                sx={{
                  bottom: 0,
                  right: 0,
                  position: "absolute",
                }}
              >
                <img
                  src={EditIcon}
                  style={{ color: "#6f767e", cursor: "pointer" }}
                  alt="edit icon"
                  onClick={handleOpenNeededCandidatesDialog}
                />
              </Box>
            </Value>
          </Box>
        </Results>
      </Statistics>

      {loadingStatus ? (
        <Spinner>
          <img className="jobs-loader__spinner" src={Loader} alt="spinner" />
        </Spinner>
      ) : (
        <>
          {hasCandidates ? (
            <CandidatesArea>
              <FilterGroup>
                <Box
                  sx={{
                    display: "flex",
                  }}
                >
                  <Box
                    sx={{
                      width: "16px",
                      height: "auto",
                      margin: "0px 16px 0px 0px",
                      background: theme?.custom.colors.green,
                      borderRadius: "4px",
                    }}
                  />
                  <SelectButton
                    onClick={() =>
                      handleFilter(Filters.ALL, startDate, endDate)
                    }
                    className={filter === Filters.ALL ? "selected" : ""}
                    aria-describedby={button_id}
                  >
                    All
                  </SelectButton>
                  <SelectButton
                    onClick={() =>
                      handleFilter(Filters.PENDING, startDate, endDate)
                    }
                    className={filter === Filters.PENDING ? "selected" : ""}
                  >
                    Pending
                  </SelectButton>
                  <SelectButton
                    onClick={() =>
                      handleFilter(Filters.SHORTLISTED, startDate, endDate)
                    }
                    className={filter === Filters.SHORTLISTED ? "selected" : ""}
                  >
                    Shortlisted
                  </SelectButton>
                  <SelectButton
                    onClick={() =>
                      handleFilter(Filters.REJECTED, startDate, endDate)
                    }
                    className={filter === Filters.REJECTED ? "selected" : ""}
                  >
                    Rejected
                  </SelectButton>
                  <SelectButton
                    onClick={() =>
                      handleFilter(Filters.ARCHIVED, startDate, endDate)
                    }
                    className={filter === Filters.ARCHIVED ? "selected" : ""}
                  >
                    Archived
                  </SelectButton>
                </Box>
                <Box>
                  <SelectButton
                    sx={{
                      background: "#fcfcfc",
                    }}
                    onClick={handleOpen}
                  >
                    {startDate && endDate
                      ? `${new Date(
                          startDate
                        ).toLocaleDateString()} - ${new Date(
                          endDate
                        ).toLocaleDateString()}`
                      : "Date"}
                    <CalendarTodayIconOutlined
                      sx={{
                        ml: "5px",
                        height: "15px",
                        width: "15px",
                        left: "1.5px",
                        top: "1.5px",
                        color: " #6f767e",
                      }}
                    />
                  </SelectButton>
                </Box>
              </FilterGroup>

              <DateRangePicker
                handleClose={handleClose}
                open={open}
                onChange={onDateRangeChange}
                startDate={startDate}
                endDate={endDate}
                clearDates={clearDates}
                anchorEl={anchorEl}
              />
              {loadingStatus ? (
                <Spinner>
                  <img
                    className="jobs-loader__spinner"
                    src={Loader}
                    alt="spinner"
                  />
                </Spinner>
              ) : (
                <>
                  <Candidates>
                    {candidatesList?.length} candidate
                    {candidatesList?.length !== 1 && <span>s</span>}
                  </Candidates>

                  {candidatesList?.map((candidate) => (
                    <Candidate
                      onClick={() => handleModalOpen(candidate)}
                      key={candidate.candidate_id}
                      candidate={candidate}
                      updated_by={updated_by}
                    />
                  ))}
                  <CandidateDetails
                    candidate={selectedCandidate}
                    handleClose={handleModalClose}
                    open={openCandidateProfileModal}
                    updated_by={updated_by}
                    switchCandidate={switchCandidate}
                    candidatesList={candidatesList}
                  />
                </>
              )}
            </CandidatesArea>
          ) : (
            <>
              {candidatesReduxState.status === "fulfilled" && <NoCandidates />}
            </>
          )}
        </>
      )}
      <CandidateOnboardingForm
        skills_must_have={job.skills_must_have}
        skills_nice_to_have={job.skills_nice_to_have}
        open={openCandidateOnboardingModal}
        handleClose={closeCandidateOnboardingModal}
        job_id={id as string}
        candidate={selectedCandidate}
      />
      <CandidatesNeededDialog
        openDialog={openNeededCandidatesDialog}
        isLoading={jobUpdateReduxState.status === "pending"}
        invalidCandidatesNeeded={invalidCandidatesNeeded}
        candidatesNeeded={candidatesNeeded}
        handleInputChange={handleInputChange}
        handleCloseDialog={handleCloseDialog}
        handleAcceptDialog={handleAcceptDialog}
      />
    </PrivateLayout>
  );
};

export default Job;
