import { createTheme, Theme } from "@mui/material/styles";
export interface ITheme extends Theme {
  custom: {
    colors: {
      purple: string;
      green: string;
      orange: string;
      redFaded: string;
      light_grey_1: string;
      dark_grey_1: string;
      neutral_grey: string;
    };
  };
}
export default createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#003350",
    },
    secondary: {
      main: "#3785F9",
    },
    error: {
      main: "#EA526F",
    },
    background: {
      default: "#fff",
    },
    text: {
      primary: "#9A9FA5",
      secondary: "#1A1D1F",
    },
    colors: {
      black: "#111315",
      light_orange: "#FDECE3",
      orange: "#FCBC99",
      light_dark_grey: "#cccccc",
      light_grey: "#d8d8d8",
      red: "#F74F44",
    },
    grey: {
      50: "#ffffff",
      100: "#fcfcfc",
      200: "#F2F2F2",
      300: "#EFEFEF",
      400: "#6F767E",
      500: "#272B30",
      600: "#2A2A2A",
      700: "#F3F3F3",
      800: "#f2f2f2",
      900: "#F4F4F4",
    },
    info: {
      main: "#1B6EEA",
      dark: "#1B6EEA",
    },
  },
  typography: {},
  spacing: 4,
  custom: {
    colors: {
      purple: "#CABDFF",
      green: "#B5E4CA",
      orange: "#33383F",
      redFaded: "#FFBC99",
      light_grey_1: "#ADAEAF",
      dark_grey_1: "#757575",
      neutral_grey: "#E8E8E8",
    },
  },
} as unknown as ITheme);

export const text = {};
