import React from "react";
import { styled } from "@mui/material/styles";
import Text from "components/shared/text/text";

const Notice = styled("div")`
  background: ${({ theme }) => theme.palette.grey[100]};
  padding: 24px;
  margin-top: 8px;
  border-radius: 8px;
  align-items: center;
  font-family: "Inter";
`;

const MobileNotice = () => {
  return (
    <Notice
      sx={{
        height: "95vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Text
        sx={{
          marginTop: "2rem",
          fontFamily: "Inter",
          fontStyle: "normal",
          fontWeight: 600,
          fontSize: "32px",
          lineHeight: "40px",
          letterSpacing: "-0.03em",
        }}
      >
        Connect is made for laptop/desktop.
      </Text>
      <Text
        sx={{
          marginTop: "1rem",
          fontFamily: "Inter",
          fontStyle: "normal",
          color: "#6F767e",
          fontWeight: 400,
          fontSize: "14px",
          lineHeight: "150%",
          letterSpacing: "-0.01em",
        }}
      >
        We want to proivde a great mobile experience - but until then please use
        our software on your laptop or desktop computer.
      </Text>
    </Notice>
  );
};

export default MobileNotice;
