import React, { useState } from "react";
import AuthForm from "components/auth-form/auth-form";
import Text from "components/shared/text/text";
import Input from "components/shared/input/input";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import CorporateFareOutlinedIcon from "@mui/icons-material/CorporateFareOutlined";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { CompanySchema } from "utils/form-schema";
import Form from "components/shared/form/form";
import { doCreateCompanyAction } from "redux/services/company/create/create.actions";
import { useAppDispatch, RootState } from "redux/store";
import SharedButton from "components/shared/button/button";
import { useSelector } from "react-redux";
import { IRoleType, IWorkType } from "types/types";
import { useNavigate } from "react-router-dom";
import { ROUTE_BUILDING } from "routes/route-items";
import { doActionFetchUserAction } from "redux/services/user/user.actions";
import { _get } from "utils/lodash";
import CircularProgress from "@mui/material/CircularProgress";
import { toast } from "react-toastify";
import ToastMessage from "components/toast-message";

interface IState {
  companyName: string;
  companyLocation: string;
  checked?: boolean;
}

const initialState: IState = {
  checked: false,
  companyName: "",
  companyLocation: "",
};

const Company = () => {
  const theme = useTheme();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IState>({
    resolver: yupResolver(CompanySchema),
  });
  const [state, setState] = useState<IState>(initialState);
  const reduxState = useSelector(
    (reduxState: RootState) => reduxState.companyCreate || {}
  );

  const userState = useSelector(
    (reduxState: RootState) => reduxState.user.response
  );

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onSubmit = async (data: IState) => {
    if (userState?.uid) {
      const job_resp = await dispatch(
        doCreateCompanyAction({
          company: {
            location: data.companyLocation,
            uid: userState.uid,
            work_type: state.checked ? IWorkType.REMOTE : IWorkType.OFFICE,
            name: data.companyName,
            industries: [],
            vatID: "",
            billingAddress: "",
            billingEmailAddress: "",
            company_id: "",
            objectID: "",
            jobs_without_criteria: [],
            original_avatar_url: "",
            recruiters_attached: [userState.uid],
            role: IRoleType.RECRUITER,
            total_candidates: 0,
            total_jobs: 0,
            created_at: null,
            thumbnail_avatar_url: "",
          },
        })
      );
      const get_job_resp = _get(job_resp, "payload.response.data.message");
      if (get_job_resp) {
        await dispatch(
          doActionFetchUserAction({
            uid: userState.uid,
            user: {
              ...userState,
              ...get_job_resp.user,
            },
          })
        );
      }
      navigate(`/${ROUTE_BUILDING}`);
    } else {
      toast(<ToastMessage title="User" body="Missing user data" />, {
        type: "error",
        position: "top-right",
      });
      return;
    }
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState((st: IState) => ({
      ...st,
      checked: event.target.checked,
    }));
  };

  return (
    <AuthForm
      id="company"
      title="Next, let’s get to know your company"
      sx={{ maxWidth: "54%" }}
    >
      <Text
        variant="body1"
        fontFamily={"Inter"}
        fontStyle={"normal"}
        fontWeight={400}
        fontSize={"14px"}
        lineHeight={"1.5"}
        color={theme.palette.grey[400]}
        marginBottom={"20px"}
        sx={{
          textAlign: "left",
          marginLeft: 0,
        }}
      >
        Job openings you create will be under this company
      </Text>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Input
          id="companyName"
          register={register}
          errors={errors.companyName?.message}
          iconStart={
            <CorporateFareOutlinedIcon
              sx={{ color: theme.palette.text.primary, fontSize: 25 }}
            />
          }
          sx={{
            marginBottom: "20px",
          }}
          placeholder="Company name"
          // variant="outlined"
          variant="filled"
        />
        <Input
          id="companyLocation"
          register={register}
          errors={errors.companyLocation?.message}
          iconStart={
            <LocationOnOutlinedIcon
              sx={{ color: theme.palette.text.primary, fontSize: 25 }}
            />
          }
          sx={{
            marginBottom: "16px",
          }}
          placeholder="Location e.g. Berlin, Germany"
          // variant="outlined"
          variant="filled"
        />
        <Box display="flex" sx={{ marginBottom: "12px", alignItems: "center" }}>
          <Checkbox
            checked={state.checked}
            onChange={handleCheckboxChange}
            sx={{
              color: "rgba(111, 118, 126, 0.4)",
              "&.Mui-checked": {
                color: theme.palette.secondary.main,
              },
            }}
          />
          <Text
            fontSize="14px"
            fontWeight={600}
            lineHeight="150%"
            letterSpacing="-0.01em"
            sx={{
              fontStyle: "normal",
              textAlign: "left",
              color: theme.palette.text.primary,
              marginLeft: "0rem",
            }}
          >
            Remote
          </Text>
        </Box>
        <SharedButton type="submit" variant="contained">
          <Box
            sx={{
              marginLeft: "6px",
              textTransform: "initial",
              display: "flex",
              alignItems: "center",
            }}
          >
            {reduxState.status === "pending" && (
              <CircularProgress
                sx={{ marginRight: "10px" }}
                color="inherit"
                size="20px"
              />
            )}
            {reduxState.status === "pending" ? "Loading ..." : "Continue"}
          </Box>
        </SharedButton>
      </Form>
    </AuthForm>
  );
};

export default Company;
