export default [
  {
    formLabel: "First Name",
    id: "firstName",
    placeholder: "James",
    required: true,
    variant: "filled",
  },
  {
    formLabel: "Last Name",
    id: "lastName",
    placeholder: "Doe",
    required: true,
    variant: "filled",
  },
  {
    formLabel: "Email",
    id: "email",
    variant: "filled",
    placeholder: "candidate@email.com",
    required: false,
  },
  {
    formLabel: "Phone",
    id: "phone",
    variant: "filled",
    placeholder: "4930901810",
    required: false,
  },
  {
    formLabel: "LinkedIn link",
    id: "linkedInProfile",
    variant: "filled",
    placeholder: "https://www.linkedin.com/in/username",
    required: false,
  },
  {
    formLabel: "Portfolio link",
    id: "portfolio",
    variant: "filled",
    placeholder: "https://myportfolio.com",
    required: false,
  },
  {
    formLabel: "Location",
    id: "location",
    variant: "filled",
    placeholder: "Munich, Germany",
    required: false,
  },
  {
    formLabel: "Pronouns",
    id: "pronouns",
    variant: "filled",
    placeholder: "He/Him",
    required: false,
  },
  {
    formLabel: "Current job title",
    id: "jobTitle",
    variant: "filled",
    placeholder: "Software Engineer",
    required: false,
  },
  {
    formLabel: "Current company",
    id: "currentCompany",
    variant: "",
    placeholder: "Company Name",
    required: false,
  },
];
