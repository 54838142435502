import React, { useState, useEffect, useCallback } from "react";
import Drawer from "layouts/drawer";
import Card from "components/shared/card";
import { styled } from "@mui/material/styles";
import Slack from "assets/images/slack.svg";
import ATS from "assets/images/ats.svg";
import { clientMail } from "constants/constants";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../redux/store";
import { toast } from "react-toastify";
import { IRoleType } from "types/types";
import { config } from "config";

import ToastMessage from "components/toast-message";

import { doRemoveSlackUserAction } from "redux/services/manageSlackNotificationUsers/manageSlackNotification.actions";

const initialState = {
  user: {} as any,
};

const Integrations = () => {
  const Container = styled("div")`
    display: flex;
    flex-wrap: wrap;
    background: ${({ theme }) => theme.palette.grey[100]};
    padding: 40px;
    margin-top: 40px;
    border-radius: 8px;
    align-items: center;
  `;

  const [state, setState] = useState(initialState);
  const [slackNotificationsOff, setSlackNotificationsOff] = useState(false);
  const [slackNotificationsOn, setSlackNotificationsOn] = useState(true);
  const [offSwitchSlack, setOffSwitchSlack] = useState(false);
  const [onSwitchSlack, setOnSwitchSlack] = useState(true);

  const dispatch = useAppDispatch();

  const userState = useSelector(
    (reduxState: RootState) => reduxState.user.response
  );

  useEffect(() => {
    const url_query = new URLSearchParams(window.location.search);
    const success_token = url_query.get("slack_success");
    if (success_token) {
      toast(
        <ToastMessage
          title="Slack Integration"
          body="Slack Integration successful!"
        />,
        { type: "success", position: "top-right" }
      );
    }
  }, []);

  useEffect(() => {
    const user_data = {
      ...userState,
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setState((st: any) => ({
      ...st,
      user: user_data,
    }));
    setOffSwitchSlack(userState.integrations?.Slack?.selected);
    setOnSwitchSlack(userState.integrations?.Slack?.selected);
  }, [userState]);

  const handleSlackOnSettings = useCallback(async () => {
    if (slackNotificationsOn === false) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const response: any = await dispatch(
        doRemoveSlackUserAction({
          email: userState.email,
          uid: userState.uid,
          slack_access_token: userState.slack_access_token,
          slack_user_access_token: userState.slack_user_access_token,
          role: IRoleType.RECRUITER,
        })
      );

      if (response.payload.data.success) {
        toast(
          <ToastMessage
            title="Slack Integration"
            body="Unlinking Slack was successful!"
          />,
          { type: "success", position: "top-right" }
        );
      }
    }
  }, [slackNotificationsOn]);

  const handleSlackOffSettings = useCallback(async () => {
    if (slackNotificationsOff === true) {
      window.open(
        `https://slack.com/oauth/v2/authorize?client_id=3417414999671.3444660245457&scope=chat:write,chat:write.customize,users:read&user_scope=chat:write&redirect_uri=${
          config.REACT_APP_SLACK_REDIRECT_URI
        }/&state=${state.user.email}~${state.user.company_id}~${
          state.user?.firstName
        }~${
          window.location.protocol +
          "//" +
          window.location.host +
          window.location.pathname
        }~${state.user?.uid}
  		`,
        "_blank"
      );
    }
  }, [slackNotificationsOff]);

  const handleSlackOffNotifications = () => {
    setSlackNotificationsOff(!slackNotificationsOff);
  };

  const handleSlackOnNotifications = () => {
    setSlackNotificationsOn(!slackNotificationsOn);
  };

  useEffect(() => {
    handleSlackOnSettings();
  }, [handleSlackOnSettings]);

  useEffect(() => {
    handleSlackOffSettings();
  }, [handleSlackOffSettings]);

  const mailTo = `mailto:${clientMail}?subject=Connect-Integrations`;

  return (
    <Drawer menu="Integrations">
      <Container>
        {!offSwitchSlack && (
          <Card
            title="Slack"
            switch
            icon={Slack}
            switchState={slackNotificationsOff}
            switchOnchange={handleSlackOffNotifications}
          >
            Integrate with your Slack, so that you can be notified when new
            candidates are added to your jobs.
          </Card>
        )}
        {onSwitchSlack && (
          <Card
            title="Slack"
            switch
            icon={Slack}
            switchState={slackNotificationsOn}
            switchOnchange={handleSlackOnNotifications}
          >
            Integrate with your Slack, so that you can be notified when new
            candidates are added to your jobs.
          </Card>
        )}
        <Card title="ATS" switch icon={ATS}>
          Choose your ATS from 35 popular market options, so that candidate
          information gets seamlessly transferred.
        </Card>
        <Card
          title="Need more integrations?"
          action="Submit a suggestion"
          sx={{
            backgroundColor: (theme: { palette: { grey: never[] } }) =>
              theme.palette.grey[700],
          }}
          onClickHandler={() => window.open(mailTo, "_self")}
        >
          What integrations would help you the most? Let our team know.
        </Card>
      </Container>
    </Drawer>
  );
};

export default Integrations;
