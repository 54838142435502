import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { ICompany, IJob, IJobStatus, TJobStatusOption } from "types/types";
import { TypeCompanyStatus } from "components/companies/interface";

export const updateJobs = ({
  updatedJob,
  jobList,
}: {
  updatedJob: IJob;
  jobList: IJob[];
}) => {
  const select = createDraftSafeSelector(() => {
    const list = jobList.map((job) => {
      if (job.job_id === updatedJob.job_id) {
        return updatedJob;
      }
      return job;
    });

    return list;
  });
  return select(jobList);
};

export const deleteJob = ({
  deletedJob,
  jobList,
}: {
  deletedJob: IJob;
  jobList: IJob[];
}) => {
  const select = createDraftSafeSelector(() => {
    const list = jobList.map((job) => job.job_id !== deletedJob.job_id);
    return list;
  });
  return select(jobList);
};

export const restructureJobs = ({
  jobState,
  jobStatus,
  uid,
}: {
  jobState: IJob[];
  jobStatus: TJobStatusOption;
  uid: string;
}) => {
  const select = createDraftSafeSelector(
    (state: IJob[]) => state,
    (items) => {
      const jobsList: IJob[] = [];
      let archivedList: IJob[] = [];
      let restructuredJobs = items;

      if (jobStatus === "ALL_JOBS") {
        items.map((job: IJob) => {
          if (job.status === IJobStatus.ACTIVE || !job.status) {
            jobsList.push(job);
          } else {
            archivedList.push(job);
          }
        });
      } else if (jobStatus === "MY_JOBS") {
        items.map((job: IJob) => {
          const isMyJob = job?.recruiters_attached?.includes(uid);
          if (isMyJob) {
            jobsList.push(job);
          }
        });
      } else if (jobStatus === "ARCHIVED") {
        archivedList = items.filter((job) => job.status === "ARCHIVED");
      } else {
        items.map((job: IJob) => {
          jobsList.push(job);
        });
      }

      jobsList.sort((a, b) => {
        const A = new Date(a.created_at);
        const B = new Date(b.created_at);
        return A > B ? -1 : A < B ? 1 : 0;
      });

      archivedList.sort((a, b) => {
        const A = new Date(a.updated_at);
        const B = new Date(b.updated_at);
        return A > B ? -1 : A < B ? 1 : 0;
      });

      if (jobStatus === "ALL_JOBS") {
        restructuredJobs = jobsList.concat(archivedList.reverse());
      } else if (jobStatus === IJobStatus.ARCHIVE) {
        restructuredJobs = archivedList;
      } else {
        restructuredJobs = jobsList;
      }

      return restructuredJobs;
    }
  );
  return select(jobState);
};

export const updateCompanies = ({
  updatedCompany,
  companyList,
}: {
  updatedCompany: Partial<ICompany>;
  companyList: ICompany[];
}) => {
  const select = createDraftSafeSelector(() => {
    const list = companyList.map((company) => {
      if (company.company_id === updatedCompany.company_id) {
        return { ...company, ...updatedCompany };
      }
      return company;
    });

    return list;
  });
  return select(companyList);
};

export const restructureCompanies = ({
  companies_list,
  companyStatus,
  uid,
}: {
  companies_list: ICompany[];
  companyStatus: TypeCompanyStatus;
  uid: string;
}) => {
  const select = createDraftSafeSelector(
    (companies: ICompany[]) => companies,
    (items) => {
      let restructuredList = items;

      if (companyStatus === "MY_COMPANIES") {
        restructuredList = items.filter((company: ICompany) =>
          company?.recruiters_attached?.includes(uid)
        );
      }

      restructuredList.sort((a, b) => {
        const A = new Date(a.created_at);
        const B = new Date(b.created_at);
        return A > B ? -1 : A < B ? 1 : 0;
      });

      return restructuredList;
    }
  );
  return select(companies_list);
};
