import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

const Statistics = styled("div")`
  display: flex;
  justify-content: space-between;
  background: ${({ theme }) => theme.palette.grey[100]};
  padding: 24px;
  border-radius: 8px;
  align-items: center;
  font-family: "Inter";
  margin-top: 24px;
  height: 160px;
`;

const Results = styled(`div`)`
  min-height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ResultsDivider = styled("div")`
  width: 1px;
  height: 112px;
  background: #efefef;
  border-radius: 1px;
`;

const CandidatesArea = styled("div")`
  background: ${({ theme }) => theme.palette.grey[100]};
  padding: 24px;
  margin-top: 8px;
  border-radius: 8px;
  align-items: center;
  font-family: "Inter";
`;

const Header = styled("div")`
  font-family: "Inter", sans-serif;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
`;

const Title = styled(Typography)`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  color: ${({ theme }) => theme.palette.grey[500]};
`;

const ButtonGroup = styled("div")`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: flex-end;
`;

const Subtitle = styled("span")`
  color: #6f767e;
  font-family: Inter;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.01em;
  text-align: left;
  display: flex;
  align-items: center;
`;

const Value = styled("div")`
  font-family: Inter;
  font-size: 48px;
  font-weight: 600;
  line-height: 48px;
  letter-spacing: -0.03em;
  text-align: left;
  font-size: 48px;
  font-weight: 600;
`;

const Text = styled("span")`
  font-weight: 600;
`;

const FilterGroup = styled(`div`)`
  display: flex;
  justify-content: space-between;
`;

const URL = styled("a")`
  text-decoration: underline;
  color: #6f767e;
  font-size: 13px;
`;

const Candidates = styled("div")`
  margin-top: 32px;
  margin-bottom: 18px;
  color: ${({ theme }) => theme?.palette.grey[400]};
`;

const Spinner = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 45px;
`;

export {
  Spinner,
  Candidates,
  URL,
  FilterGroup,
  Value,
  Subtitle,
  Statistics,
  Results,
  ResultsDivider,
  CandidatesArea,
  Text,
  ButtonGroup,
  Title,
  Header,
};
