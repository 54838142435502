import React, { FC, useState, useRef } from "react";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/material/styles";
import { ITheme } from "theme";
import { useNavigate } from "react-router-dom";

import { useOnClickOutside } from "../../hooks/use-on-click-outside";
import Span from "components/shared/span/span";
import JobTitle from "./company-title";
import CompanyCard from "./company-card";
import Ellipsis from "assets/images/ellipsis.svg";
import BuildingIcon from "assets/images/custom-building-icon.svg";
import PeopleIcon from "assets/images/people-icon.svg";
import Edit from "assets/images/edit-pen.svg";
import { ROUTE_ACTIVE_JOBS, ROUTE_COMPANIES } from "routes/route-items";
import FolderAdd from "assets/images/folder-add-light.svg";
import Folder from "assets/images/folder-light.svg";
import { ICompany } from "types/types";
import { IGetJobsPayload } from "redux/services/job/getJob/interface";
import { useAppDispatch } from "redux/store";
import { doGetJobsAction } from "redux/services/job/getJob/get.actions";
import { auth } from "utils/firebase";

interface IProps {
  company: ICompany;
  uid: string;
  // eslint-disable-next-line no-unused-vars
  handleUpdateMyCompany: ({ company }: { company: ICompany }) => void;
  jobState: IGetJobsPayload;
}

const EllipsisIcon = styled("img")`
  cursor: pointer;
  padding: 8px;
  border-radius: 50%;
  transition: all 0.2s ease-in-out;
  &:hover {
    background-color: ${({ theme }) => theme.palette.grey[200]};
  }
`;

const EditJobContainer = styled(Box)`
  position: absolute;
  display: none;
  width: 102%;
  height: 102%;
  top: -2px;
  left: -3px;
  right: -2px;
  bottom: -2px;
  background-color: rgba(17, 19, 21, 0.8);
  z-index: 2;
  color: #f1f1f1;
  border-radius: 12px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  transition: all 0.3s ease;
  &.show {
    display: flex;
  }
`;

const EditTextContainer = styled(Box)`
  display: inline-flex;
  background: #fcfcfc;
  padding: 8px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 36px;
  gap: 4px;
`;

const CounterImage = styled("div")`
  background: #efefef;
  border-radius: 50px;
  height: 32px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Counter = styled("div")`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: -0.01em;
  color: #1a1d1f;
`;

const CounterDescription = styled("div")`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 175%;
  display: flex;
  align-items: center;
  letter-spacing: -0.01em;
  color: #6f767e;
`;

const CardWithJob: FC<IProps> = ({
  company,
  uid,
  handleUpdateMyCompany,
  jobState,
}) => {
  const theme: ITheme = useTheme();
  const dispatch = useAppDispatch();
  const ref = useRef(null);
  const [open, toggleDialog] = useState(false);
  const navigate = useNavigate();

  useOnClickOutside(ref, () => toggleDialog(false));
  const openDialog = (e: React.MouseEvent<HTMLImageElement>) => {
    e.stopPropagation();
    toggleDialog((prev: boolean) => !prev);
  };

  const handleClickEditCompany = async () => {
    await auth.getAuth().currentUser?.getIdTokenResult(true);
    navigate(`/${ROUTE_COMPANIES}/${company.company_id}`, {
      state: {
        from: `${ROUTE_COMPANIES}`,
      },
    });
  };

  const handleCardClicked = async (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    await dispatch(
      doGetJobsAction({
        ...jobState,
        jobs: [],
        company_id: company.company_id,
        company_name: company.name,
        jobStatus: "ALL_JOBS",
        selected_job_function: "All_job_functions",
        searchQuery: "",
        period: {
          start: null,
          end: null,
        },
        hasMore: true,
      })
    );
    navigate(`/${ROUTE_ACTIVE_JOBS}`);
  };

  const handleDisableBackdropClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };

  const isMyCompany = company.recruiters_attached?.includes(uid);

  const formatDate = () => {
    try {
      const createdAt = new Date(company?.created_at);
      const year = createdAt.getFullYear();
      const month = ("0" + (createdAt?.getMonth() + 1)).slice(-2);
      const date = ("0" + createdAt?.getDate()).slice(-2);
      return `${date}-${month}-${year}`;
    } catch {
      return "N/A";
    }
  };

  return (
    <CompanyCard onClick={handleCardClicked}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Span color={theme.palette.text.secondary}>{formatDate()}</Span>
        <EllipsisIcon src={Ellipsis} alt="Job options" onClick={openDialog} />
      </Box>
      <Box>
        <JobTitle
          title={company.name}
          avatar_uri={company.original_avatar_url}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "inline-flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "5px",
          }}
          color={theme.palette.grey[400]}
        >
          <CounterImage>
            <img height="20px" width="20px" src={BuildingIcon} alt="" />
          </CounterImage>
          <Box>
            <Counter>{company.total_jobs}</Counter>
            <CounterDescription>Total Jobs</CounterDescription>
          </Box>
        </Box>
        <Box
          sx={{
            display: "inline-flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "5px",
          }}
          color={theme.palette.grey[400]}
        >
          <CounterImage>
            <img height="20px" width="20px" src={PeopleIcon} alt="" />
          </CounterImage>
          <Box>
            <Counter>{company.total_candidates}</Counter>
            <CounterDescription>Total Candidates</CounterDescription>
          </Box>
        </Box>
      </Box>
      <EditJobContainer
        onClick={handleDisableBackdropClick}
        ref={ref}
        className={open ? "show" : ""}
      >
        <EditTextContainer
          onClick={handleClickEditCompany}
          onKeyDown={handleClickEditCompany}
        >
          <img src={Edit} alt="" />
          <Span color="#6f767e" fontSize="12px" sx={{ lineHeight: "1" }}>
            Edit
          </Span>
        </EditTextContainer>
        <EditTextContainer
          onClick={() => {
            handleUpdateMyCompany({
              company,
            });
            toggleDialog(false);
          }}
          onKeyDown={() => {}}
        >
          <img src={isMyCompany ? Folder : FolderAdd} alt="Folder Icon" />
          <Span color="#6f767e" fontSize="12px" sx={{ lineHeight: "1" }}>
            {isMyCompany ? "Remove from my companies" : "Add to my companies"}
          </Span>
        </EditTextContainer>
      </EditJobContainer>
    </CompanyCard>
  );
};

export default CardWithJob;
