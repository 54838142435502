import * as React from "react";
import Drawer from "layouts/drawer";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import { ITheme } from "theme";

import Text from "components/shared/text/text";
import FaqList from "components/faqs/faq-list";

import "./faqs-style.scss";

const FAQs = () => {
  const theme: ITheme = useTheme();
  const Container = styled("div")`
    display: flex;
    flex-wrap: wrap;
    margin-top: 25px;
    border-radius: 8px;
    flex-direction: column;
  `;

  return (
    <Drawer menu="FAQs">
      <Container>
        <Box>
          <Text
            fontWeight={500}
            fontSize="13px"
            lineHeight="1"
            letterSpacing="-0.01em"
            color={theme.palette.grey[400]}
            sx={{
              textAlign: "left",
              marginBottom: "15px",
              marginLeft: 0,
            }}
          >
            Frequently Asked Questions
          </Text>
        </Box>
        <FaqList />
      </Container>
    </Drawer>
  );
};

export default FAQs;
