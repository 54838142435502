import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IGetJobsPayload, IGetJobsState } from "./interface";
import { doGetJobsAction } from "./get.actions";
import { ReduxStatus } from "types/types";

const initialPayload: IGetJobsPayload = {
  jobs: [],
  company_id: "",
  hasMore: true,
  jobStatus: "ALL_JOBS",
  selected_job_function: "All_job_functions",
  period: {
    start: undefined,
    end: undefined,
  },
  page: 0,
  totalPages: 0,
  totalJobs: 0,
  searchQuery: "",
  job_added: undefined,
  job_updated: undefined,
};

const initialState: IGetJobsState = {
  status: ReduxStatus.empty,
  response: initialPayload,
  error: {},
};

export const getCompany = createSlice({
  name: "jobs/get",
  initialState,
  reducers: {
    increment: () => {
      // state.candidateList = [1, 2];
      // ✅ CORRECT: logs a plain JS copy of the current data
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(doGetJobsAction.pending, (state: IGetJobsState) => {
        state.status = ReduxStatus.pending;
      })
      .addCase(
        doGetJobsAction.fulfilled,
        (state: IGetJobsState, action: PayloadAction<IGetJobsPayload>) => {
          state.status = ReduxStatus.fulfilled;
          state.error = {};
          state.response = action.payload;
        }
      )
      .addCase(
        doGetJobsAction.rejected,
        (state: IGetJobsState, action: PayloadAction<unknown>) => {
          state.status = ReduxStatus.rejected;
          state.response = initialPayload;
          state.error = action.payload;
        }
      );
  },
});

export const { increment } = getCompany.actions;
export default getCompany.reducer;
