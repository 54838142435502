import axios from "axios";
import { auth } from "utils/firebase";
import { config } from "config";

const axiosInstance = axios.create({
  baseURL: `https://europe-west3-${config.REACT_APP_FIREBASE_PROJECT_ID}.cloudfunctions.net/api-app-app`,
  timeout: 10000,
});

axiosInstance.interceptors.request.use(
  async (config) => {
    const token = await auth.getAuth().currentUser?.getIdToken();
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;
