import * as React from "react";
import LogoImage from "assets/images/A8.svg";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";

interface IProps {
  sx?: {};
}

const LayoutLogo = styled(Box)`
  background-image: url(${LogoImage});
  background-repeat: no-repeat;
  background-size: cover;
  height: 32px;
  width: 54px;
  margin-left: 12px;
`;

const Logo = ({ sx = {} }: IProps) => {
  return (
    <LayoutLogo
      sx={{
        ...sx,
      }}
    />
  );
};

export default Logo;
