import React, { FC, useState, useEffect, useCallback } from "react";
import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Logo from "components/shared/logo";
import { _get } from "utils/lodash";
import { useLocation, useNavigate } from "react-router-dom";
import {
  ROUTE_ACTIVE_JOBS,
  ROUTE_ACTIVE_JOB,
  ROUTE_COMPANIES,
  ROUTE_COMMAND_BAR,
  ROUTE_RESIZE_NAVBAR,
  ROUTE_RELEASE_NOTES,
  ROUTE_SETTINGS,
} from "routes/route-items";
import HR from "components/shared/horizontal-rule";
import Box from "@mui/material/Box";
import JobsIcon from "assets/images/jobs-menu-icon-2.svg";
import MagnifyingGlassIcon from "assets/images/magnifying-glass.svg";
import ExpandCarret from "assets/images/expand-carret.svg";
import Notes from "assets/images/notes.svg";
import CompanyIcon from "assets/images/company-menu-icon.svg";
import SettingsIcon from "assets/images/settings-menu-icon.svg";
import { db } from "utils/firebase";
import { doc, onSnapshot } from "firebase/firestore";
import { collection_name } from "utils/firebase-refs";
import SideMenuListItem from "./list-item";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "redux/store";
import { doGetJobsAction } from "redux/services/job/getJob/get.actions";
import { IUser } from "types/types";
import { doActionFetchUserAction } from "redux/services/user/user.actions";

interface IProps {
  drawerWidth: number;
  resizeDrawer?: () => void;
}

const SideMenu: FC<IProps> = ({ drawerWidth, resizeDrawer }) => {
  const routes = {
    // Home: {
    //   active: false,
    //   icon: <img src={JobsIcon} alt="" />,
    //   route: ROUTE_HOME,
    // },
    Connect: {
      // active: false,
      // icon: <img src={CompanyIcon} alt="" />,
      // route: ROUTE_COMPANY_PROFILE,
      active: false,
      icon: <img src={JobsIcon} alt="" style={{ width: "20px" }} />,
      route: ROUTE_ACTIVE_JOBS || ROUTE_ACTIVE_JOB,
    },
    Companies: {
      active: false,
      icon: <img src={CompanyIcon} alt="" />,
      route: ROUTE_COMPANIES,
    },
    "Command Bar": {
      active: false,
      icon: <img src={MagnifyingGlassIcon} alt="" style={{ width: "18px" }} />,
      route: ROUTE_COMMAND_BAR,
    },
    Settings: {
      active: false,
      icon: <img src={SettingsIcon} alt="" />,
      route: ROUTE_SETTINGS,
    },
  };

  const lowerRoutes = {
    // FAQs: {
    //   active: false,
    //   icon: <img src={FaqIcon} alt="" />,
    //   route: ROUTE_FAQS,
    // },
  };

  const supportingLowestItems = {
    "Release Notes": {
      active: false,
      icon: <img src={Notes} alt="" />,
      route: ROUTE_RELEASE_NOTES,
    },
  };

  const lowestRoutes = {
    "Collapse Navbar": {
      active: false,
      icon: <img src={ExpandCarret} alt="" style={{ width: "auto" }} />,
      route: ROUTE_RESIZE_NAVBAR,
    },
  };

  const jobsGetState = useSelector(
    (reduxState: RootState) => reduxState.jobsGet.response
  );
  const userState = useSelector(
    (reduxState: RootState) => reduxState.user.response
  );

  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [menuItems, setMenuItems] = useState(routes);
  const [lowermenuItems, setLowerMenuItems] = useState(lowerRoutes);
  const [supportingLowestMenuItems] = useState(supportingLowestItems);
  const [lowestmenuItems] = useState(lowestRoutes);
  const [lowestMenuActive, setLowestMenuActive] = useState(() => {
    const { active: primal_active_state } = _get(
      lowestRoutes,
      "Collapse Navbar"
    );
    const stickyValue = window.localStorage.getItem("lowest_menu_active");
    return stickyValue !== null
      ? JSON.parse(stickyValue)
      : window.localStorage.setItem("lowest_menu_active", primal_active_state);
  });

  const clearCompanySelected = async () => {
    const hasCompanyId = !!jobsGetState.company_id;
    if (hasCompanyId) {
      await dispatch(
        doGetJobsAction({
          ...jobsGetState,
          jobs: [],
          company_id: undefined,
          company_name: undefined,
        })
      );
    }
  };

  const navigateToConnect = () => {
    clearCompanySelected();
    navigate(`/${ROUTE_ACTIVE_JOBS}`);
  };
  const openCommandBar = () => {
    window.CommandBar.open();
  };
  const openReleaseNotes = () => {
    window.open("https://headwayapp.co/acelr8-changelog", "_blank");
  };
  const updateMenu = (
    value: { [x: string]: boolean },
    routesObject: any,
    topLevel: boolean
  ) => {
    const key = Object.keys(value)[0];
    clearCompanySelected();
    if (topLevel) {
      setMenuItems(() => {
        navigate(`/${_get(routesObject, `${key}.route`)}`);
        return {
          ...routesObject,
          [key]: { ..._get(routes, key), active: value[key] },
        };
      });
    } else {
      setLowerMenuItems(() => {
        navigate(`/${_get(routesObject, `${key}.route`)}`);
        return {
          ...routesObject,
          [key]: { ..._get(lowerRoutes, key), active: value[key] },
        };
      });
    }
  };

  const toogleBtnActiveState = (value: boolean) => {
    setLowestMenuActive(value);
    window.localStorage.setItem("lowest_menu_active", "" + value);
  };

  const fetchUser = useCallback(async () => {
    const uid = userState.uid;
    const docRef = doc(db, `${collection_name.v2_recruiters}/${uid}`);
    const unsubscribe = onSnapshot(docRef, (doc) => {
      const {
        firstName,
        lastName,
        email,
        role,
        uid,
        is_active,
        company_id,
        company_name,
        next_onboarding_stage,
        original_avatar_url,
        thumbnail_avatar_url,
        notifications,
      } = doc.data() as IUser;

      dispatch(
        doActionFetchUserAction({
          uid: userState.uid,
          user: {
            ...userState,
            firstName,
            lastName,
            email,
            role,
            uid,
            is_active,
            company_id,
            company_name,
            next_onboarding_stage,
            original_avatar_url,
            thumbnail_avatar_url,
            notifications,
          },
        })
      );
    });
    return () => {
      unsubscribe();
    };
  }, [userState.uid]);

  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        transition: "width 0.6s cubic-bezier(0.16, 1, 0.3, 1)",
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
          color: (theme) => theme.palette.grey[400],
          backgroundColor: (theme) => theme.palette.grey[50],
          borderRight: 0,
          transition: "width 0.6s cubic-bezier(0.16, 1, 0.3, 1)",
        },
      }}
      variant="permanent"
      anchor="left"
    >
      <Toolbar sx={{ height: "96px" }}>
        <Box onClick={() => navigateToConnect()} sx={{ cursor: "pointer" }}>
          <Logo />
        </Box>
      </Toolbar>
      <Box
        sx={{
          margin: 0,
          position: "relative",
          top: "37%",
          transform: "translateY(-50%)",
        }}
      >
        <List sx={{ paddingTop: "20px" }}>
          {Object.keys(menuItems).map((value) => {
            const { active, route } = _get(menuItems, `${value}`);
            const path = pathname.substring(1);
            return (
              <SideMenuListItem
                value={value}
                menuItems={menuItems}
                route={route}
                routes={routes}
                integrations={0}
                updateMenu={updateMenu}
                key={value}
                active={active}
                path={path}
                topLevel={true}
                openCommandBar={openCommandBar}
                resizeDrawer={resizeDrawer}
                isExpanded={lowestMenuActive}
              />
            );
          })}
        </List>
        <Box
          sx={{
            marginTop: "auto",
            marginBottom: "20px",
          }}
        >
          <Box
            sx={{
              paddingLeft: "24px",
              paddingRight: "24px",
              marginBottom: "20px",
            }}
          >
            <HR />
          </Box>
          <List>
            {Object.keys(lowermenuItems).map((value) => {
              const { active, route } = _get(lowermenuItems, `${value}`);
              const path = pathname.substring(1);
              return (
                <SideMenuListItem
                  value={value}
                  menuItems={lowermenuItems}
                  route={route}
                  routes={lowerRoutes}
                  integrations={0}
                  updateMenu={updateMenu}
                  key={value}
                  active={active}
                  path={path}
                  topLevel={false}
                  resizeDrawer={resizeDrawer}
                  isExpanded={lowestMenuActive}
                />
              );
            })}
          </List>
        </Box>
      </Box>
      <Box
        sx={{
          marginTop: "auto",
          marginBottom: "20px",
        }}
      >
        <List>
          {Object.keys(supportingLowestItems).map((value) => {
            const { active, route } = _get(
              supportingLowestMenuItems,
              `${value}`
            );
            const path = pathname.substring(1);
            return (
              <SideMenuListItem
                value={value}
                menuItems={supportingLowestMenuItems}
                route={route}
                routes={supportingLowestItems}
                integrations={0}
                updateMenu={updateMenu}
                key={value}
                active={active}
                path={path}
                topLevel={false}
                resizeDrawer={resizeDrawer}
                isExpanded={lowestMenuActive}
                openReleaseNotes={openReleaseNotes}
              />
            );
          })}
          {Object.keys(lowestRoutes).map((value) => {
            const { route } = _get(lowestmenuItems, `${value}`);
            const path = pathname.substring(1);
            return (
              <SideMenuListItem
                value={value}
                menuItems={lowestmenuItems}
                route={route}
                routes={lowerRoutes}
                integrations={0}
                updateMenu={updateMenu}
                key={value}
                active={lowestMenuActive}
                path={path}
                topLevel={false}
                resizeDrawer={resizeDrawer}
                nonRedirect={toogleBtnActiveState}
                isExpanded={lowestMenuActive}
              />
            );
          })}
        </List>
      </Box>
    </Drawer>
  );
};

export default SideMenu;
