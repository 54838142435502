import { z } from "zod";

const configSchema = z.object({
  REACT_APP_FIREBASE_API_KEY: z.string(),
  REACT_APP_FIREBASE_AUTH_DOMAIN: z.string(),
  REACT_APP_FIREBASE_DATABASE_URL: z.string().url(),
  REACT_APP_FIREBASE_PROJECT_ID: z.string(),
  REACT_APP_FIREBASE_STORAGE_BUCKET: z.string(),
  REACT_APP_FIREBASE_MESSAGING_SENDER_ID: z.string(),
  REACT_APP_FIREBASE_APP_ID: z.string(),
  REACT_APP_GOOGLE_ANALYTICS_TARGET_ID: z.string(),
  REACT_APP_GOOGLE_UNIVERSIAL_ANALYTICS_PROPERTY_ID: z.string(),
  REACT_APP_HOTJAR_ID: z.string().transform(Number),
  REACT_APP_DRIFT_ID: z.string(),
  REACT_APP_CLIENT_EMAIL: z.string(),
  REACT_APP_SLACK_REDIRECT_URI: z.string().url(),
  REACT_APP_IS_PRODUCTION: z
    .string()
    .transform((i) => (i === "true" ? true : false)),
  REACT_APP_CLIENT_DOMAIN: z.string().url(),
  REACT_APP_ALGOLIA_APP_ID: z.string(),
  REACT_APP_COMMANDBAR_ORGANISATION_ID: z.string(),
  REACT_APP_ALGOLIA_SEARCH_KEY: z.string(),
});

// eslint-disable-next-line no-undef
const result = configSchema.safeParse(process.env);
if (!result.success) {
  // eslint-disable-next-line no-console
  console.error(result.error);
  throw new Error("Invalid configuration");
}

export const config = result.data;
