import LoadingButton from "@mui/lab/LoadingButton";
import { ButtonProps } from "@mui/material";
import { styled } from "@mui/material/styles";

type IProps = ButtonProps & {
  backgroundColor?: string;
  borderColor?: string;
  handleClick?: () => void;
  component?: string;
};

const SharedButton = styled(LoadingButton)<IProps>`
  background-color: ${({ variant, theme }) =>
    variant === "contained" ? theme.palette.secondary.main : "none"};
  color: ${({ variant, theme }) => {
    return variant === "contained"
      ? theme.palette.common.white
      : theme.palette.text.secondary;
  }};
  border: 2px solid
    ${({ variant, borderColor, theme }) =>
      variant === "contained"
        ? borderColor || theme.palette.secondary.main
        : variant === "outlined"
        ? theme.palette.grey[300]
        : theme.palette.text.primary};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 20px;
  border-radius: 20px 4px;
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 24px;
  text-transform: capitalize;
  width: 100%;
  &:hover {
    background-color: ${({ variant, theme }) =>
      variant === "contained" ? theme.palette.info.dark : "white"};
    border: 2px solid
      ${({ variant, theme }) =>
        variant === "contained"
          ? theme.palette.secondary.main
          : variant === "outlined"
          ? theme.palette.grey[300]
          : theme.palette.text.primary};
  }
`;

export default SharedButton;
