import React, { FC } from "react";
import Text from "components/shared/text/text";
import { Box, styled } from "@mui/material";
import BuildingFilledIcon from "assets/images/building-filled-icon.svg";

interface IProps {
  title: string;
  avatar_uri?: string;
  className?: string;
}

const LogoContainer = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  background: #f4f4f4;
  border-radius: 12px;
`;

const JobTitle: FC<IProps> = ({ title, avatar_uri, className = "" }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          {avatar_uri ? (
            <img
              style={{
                borderRadius: "4px",
              }}
              height="48px"
              width="48px"
              src={avatar_uri}
              alt=""
            />
          ) : (
            <LogoContainer>
              <img
                style={{
                  borderRadius: "4px",
                }}
                height="26px"
                width="26px"
                src={BuildingFilledIcon}
                alt=""
              />
            </LogoContainer>
          )}
        </Box>
        <Text
          className={className}
          fontSize="24px"
          color=""
          sx={{
            fontFamily: "Inter",
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "20px",
            lineHeight: "32px",
            textAlign: "center",
            marginTop: "10px",
            color: "#2a2a2a",
            marginLeft: 0,
            textOverflow: "ellipsis",
            maxWidth: "100%",
            whiteSpace: "nowrap",
            overflow: "hidden",
            "&.new-company": {
              marginBottom: "35px",
            },
          }}
        >
          {title}
        </Text>
      </Box>
    </Box>
  );
};

export default JobTitle;
