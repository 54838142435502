import { styled } from "@mui/material/styles";

interface SpanProps {
  fontWeight?: number;
  fontSize?: string;
  lineheight?: string;
  letterSpacing?: string;
  color?: string;
}

const Span = styled("span")<SpanProps>`
  font-family: Inter;
  font-style: normal;
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : 400)};
  font-size: ${({ fontSize }) => (fontSize ? fontSize : "13px")};
  line-height: ${({ lineheight }) => (lineheight ? lineheight : "16px")};
  text-align: left;
  letter-spacing: ${({ letterSpacing }) =>
    letterSpacing ? letterSpacing : "-0.01em"};
  color: ${({ theme, color }) => {
    return color ? color : theme.palette.text.secondary;
  }};
`;

export default Span;
