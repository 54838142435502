const styles = {
  choicesContainer: {
    display: "flex",
    paddingRight: "5rem",
    flexWrap: "wrap",
    width: "100rem",
    overflowX: "scroll",
    overflowY: "hidden",
  },
  buttonChoice: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "15px",
    lineHeight: "24px",
    background: "#fcfcfc",
    border: "2px solid #efefef",
    borderRadius: "20px 4px",
    outline: "none",
    padding: "12px 20px",
    height: "48px",
    marginRight: "20px",
    marginBottom: "20px",
    cursor: "pointer",
    transition: "ease-in-out 0.2s",
    "&:hover": {
      color: "#fcfcfc",
      background: "#1a1d1f",
    },
  },
  buttonChoiceSelected: {
    color: "#fcfcfc",
    background: "#1a1d1f",
  },
};
export default styles;
