import React, { useState } from "react";
import PrivateLayout from "layouts/drawer";
import { useAppDispatch, RootState } from "redux/store";
import { useSelector } from "react-redux";
import { doInviteMemberAction } from "redux/services/company/invite/invite.actions";
import { emailRegex } from "constants/constants";
import { _get, _uniqBy } from "utils/lodash";
import CompanyComponent from "components/company";
import Dialog from "components/dialog";
import { toast } from "react-toastify";
import ToastMessage from "components/toast-message";
import { collection_name } from "utils/firebase-refs";
import {
  onSnapshot,
  query,
  db,
  collection,
  orderBy,
  doc,
} from "utils/firebase";
import {
  ICompany,
  IInvitedMember,
  InviteStatus,
  IRoleType,
  IWorkType,
} from "types/types";
import { doUpdateCompanyAction } from "redux/services/company/update/update-company.actions";
import { doCreateCompanyAction } from "redux/services/company/create/create.actions";
import { doGetCompaniesAction } from "redux/services/companies/get/get.actions";
import { ROUTE_COMPANIES } from "routes/route-items";
import { useParams, useNavigate } from "react-router-dom";

interface IState {
  billingAddress: string;
  inviteToCollaborate: string;
  billingEmailAddress: string;
  companyName: string;
  locationHq: string;
  VATID: string;
  remote: boolean;
  file: File[];
}

const initialCompany: ICompany = {
  billingAddress: "",
  billingEmailAddress: "",
  company_id: "",
  objectID: "",
  industries: [],
  location: "",
  name: "",
  original_avatar_url: "",
  recruiters_attached: [],
  role: IRoleType.RECRUITER,
  total_candidates: 0,
  total_jobs: 0,
  uid: "",
  vatID: "",
  work_type: IWorkType.REMOTE,
  created_at: null,
  jobs_without_criteria: [],
  thumbnail_avatar_url: "",
};

interface IInviteEmail {
  invalid: boolean;
  email: string;
}

const initialInviteEmail = { email: "", invalid: false };

let global_invited_members: { [keys: string]: IInvitedMember[] } = {};
let inviteExistingUser: {
  [key: string]: { [key: string]: string };
} = {
  user: {
    receiversUID: "",
    receiversEmail: "",
    receiversDisplayName: "",
  },
  request_info: {
    company_id: "",
    company_name: "",
    receiver_id: "",
    receiversEmail: "",
    receiversFirstName: "",
    receiversLastName: "",
    role: "",
    sendersEmail: "",
    sendersFirstName: "",
    sendersId: "",
    sendersLastName: "",
  },
};

const CompanyProfile = React.memo(() => {
  const { companyId } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [isNewCompany, setIsNewCompany] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [company, setCompany] = useState<ICompany>(initialCompany);
  const [selectedIndustries, setSelectedIndustries] = useState<string[]>([]);
  const [workType, setWorkType] = React.useState<IWorkType>(IWorkType.OFFICE);
  const [inviteEmail, setInviteEmail] =
    React.useState<IInviteEmail>(initialInviteEmail);
  const [invitedMembers, setInvitedMembers] = React.useState<{
    [key: string]: IInvitedMember[];
  }>({
    [InviteStatus.pending]: [],
    [InviteStatus.accepted]: [],
    [InviteStatus.rejected]: [],
  });

  const userState = useSelector(
    (reduxState: RootState) => reduxState.user.response
  );
  const companyUpdateReduxState = useSelector(
    (reduxState: RootState) => reduxState.updateCompany
  );
  const companyCreateReduxState = useSelector(
    (reduxState: RootState) => reduxState.companyCreate || {}
  );
  const inviteReduxState = useSelector(
    (reduxState: RootState) => reduxState.inviteMember || {}
  );
  const companiesGetState = useSelector(
    (reduxState: RootState) => reduxState.getCompanies.response
  );

  const onSubmit = async (data: IState) => {
    if (isNewCompany) {
      const response = await dispatch(
        doCreateCompanyAction({
          company: {
            name: data.companyName,
            location: data.locationHq,
            uid: userState.uid,
            work_type: workType,
            industries: selectedIndustries,
            vatID: data.VATID,
            billingAddress: data.billingAddress,
            billingEmailAddress: data.billingEmailAddress,
            company_id: "",
            objectID: "",
            jobs_without_criteria: [],
            original_avatar_url: "",
            recruiters_attached: [userState.uid],
            role: IRoleType.RECRUITER,
            total_candidates: 0,
            total_jobs: 0,
            created_at: null,
            thumbnail_avatar_url: "",
          },
        })
      );

      const companyData = response.payload as ICompany;
      const companyAdded: ICompany = {
        company_id: companyData.company_id,
        objectID: companyData.company_id,
        created_at: new Date().toISOString(),
        industries: companyData.industries,
        location: companyData.location,
        name: companyData.name,
        role: companyData.role,
        uid: companyData.uid,
        work_type: companyData.work_type,
        original_avatar_url: companyData.original_avatar_url,
        thumbnail_avatar_url: companyData.thumbnail_avatar_url,
        recruiters_attached: companyData.recruiters_attached,
        total_candidates: companyData.total_candidates,
        total_jobs: companyData.total_jobs,
        billingAddress: companyData.billingAddress,
        billingEmailAddress: companyData.billingEmailAddress,
        vatID: companyData.vatID,
        jobs_without_criteria: companyData.jobs_without_criteria,
      };

      await dispatch(
        doGetCompaniesAction({
          ...companiesGetState,
          companyAdded,
          companies: [],
          page: 0,
          hasMore: true,
        })
      );

      toast(<ToastMessage title="Company" body="Company added" />, {
        type: "success",
        position: "top-right",
      });

      navigate(`/${ROUTE_COMPANIES}`);
      return;
    }

    if (!company.company_id) {
      toast(<ToastMessage title="Company" body="Missing company data" />, {
        type: "error",
        position: "top-right",
      });
      return;
    }

    const response = await dispatch(
      doUpdateCompanyAction({
        company_id: company.company_id,
        company: {
          vatID: data.VATID,
          billingAddress: data.billingAddress,
          billingEmailAddress: data.billingEmailAddress,
          work_type: workType,
          industries: selectedIndustries,
          name: data.companyName,
          location: data.locationHq,
        },
      })
    );

    const isSuccessful = response.meta.requestStatus === "fulfilled";

    if (isSuccessful) {
      const companyUpdated = {
        company_id: company.company_id,
        uid: company.uid,
        vatID: data.VATID,
        billingAddress: data.billingAddress,
        billingEmailAddress: data.billingEmailAddress,
        work_type: workType,
        industries: selectedIndustries,
        name: data.companyName,
        location: data.locationHq,
      };

      await dispatch(
        doGetCompaniesAction({
          ...companiesGetState,
          companyUpdated,
          companies: [],
          page: 0,
          hasMore: true,
        })
      );

      toast(<ToastMessage title="Company" body="Company details saved" />, {
        type: "success",
        position: "top-right",
      });
    } else {
      toast(<ToastMessage title="Company" body="Unable to update company" />, {
        type: "error",
        position: "top-right",
      });
    }
  };

  const updateSelectedIndustries = (industry: string) => {
    let industries = selectedIndustries;
    const isSelected = selectedIndustries?.includes(industry);
    if (isSelected) {
      industries = selectedIndustries?.filter((ind) => ind !== industry);
    } else {
      industries = [...selectedIndustries, industry];
    }
    setSelectedIndustries(industries);
  };

  const handleCheckCheckBox = (event: React.ChangeEvent<HTMLInputElement>) => {
    setWorkType(event.target.checked ? IWorkType.REMOTE : IWorkType.OFFICE);
  };

  const handleCloseDialog = async () => {
    setOpenDialog(false);

    await dispatch(
      doInviteMemberAction({
        sendersFirstName: "",
        sendersLastName: "",
        sendersEmail: "",
        sendersId: "",
        receiversEmail: "",
        company_id: "",
        company_name: "",
        cancelled: true,
      })
    );
  };

  const handleAcceptDialog = async () => {
    const response = await dispatch(
      doInviteMemberAction({
        sendersFirstName:
          inviteExistingUser.request_info.sendersFirstName || "",
        sendersLastName: inviteExistingUser.request_info.sendersLastName || "",
        sendersEmail: inviteExistingUser.request_info.sendersEmail || "",
        sendersId: inviteExistingUser.request_info.sendersId || "",
        receiversEmail: inviteExistingUser.request_info.receiversEmail,
        company_id: inviteExistingUser.request_info.company_id,
        company_name: company.name,
        receiver_id: inviteExistingUser?.user?.receiversUID || "",
        receiversFirstName:
          inviteExistingUser.user.receiversDisplayName.split(" ")[0] || "",
        receiversLastName:
          inviteExistingUser.user.receiversDisplayName.split(" ")[1] || "",
        isExistingUser: true,
      })
    );
    setOpenDialog(false);
    setInviteEmail({ email: "", invalid: true });
    setIsLoading(false);

    const resp = _get(response, "payload");
    if (resp.success) {
      toast(<ToastMessage title="Email sent" body="Invite email sent" />, {
        type: "success",
        position: "top-right",
      });
    } else {
      toast(
        <ToastMessage
          title="Email error"
          body="Error occurred sending email"
        />,
        {
          type: "error",
          position: "top-right",
        }
      );
    }
  };

  const handleSendInvite = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();

    if (inviteEmail.invalid) {
      return;
    }

    const response = await dispatch(
      doInviteMemberAction({
        sendersFirstName: userState.firstName || "",
        sendersLastName: userState.lastName || "",
        sendersEmail: userState.email || "",
        sendersId: userState.uid || "",
        receiversEmail: inviteEmail.email,
        company_id: company.company_id,
        company_name: company.name,
        isExistingUser: false,
      })
    );

    const resp = _get(response, "payload");
    if (resp.message.code === 409) {
      inviteExistingUser = {
        user: {
          ...resp.message.user,
        },
        request_info: {
          ...resp.message.request_info,
        },
      };
      await handleAcceptDialog();
      return;
    }

    if (resp.success) {
      setInviteEmail({ ...inviteEmail, email: "" });
      toast(<ToastMessage title="Email sent" body="Invite email sent" />, {
        type: "success",
        position: "bottom-right",
      });
    } else {
      toast(
        <ToastMessage
          title="Email error"
          body="Error occurred sending email"
        />,
        {
          type: "error",
          position: "top-right",
        }
      );
    }
  };

  const handleChangeInvite = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInviteEmail({ email: e.target.value, invalid: false });
  };

  const handleOnBlurInvite = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isValidEmail = e.target.value.match(emailRegex);
    if (!isValidEmail) {
      setInviteEmail({ ...inviteEmail, invalid: true });
    }
  };

  const sortInvite = (list: IInvitedMember[]) => {
    if (!Array.isArray(list)) return [];
    return list.sort((a, b) => {
      if (!a?.created_at || !b?.created_at) return 0;
      const A = new Date(a.created_at);
      const B = new Date(b.created_at);
      return A > B ? 1 : -1;
    });
  };

  const getInviteStatus = React.useCallback(() => {
    if (!companyId) {
      toast(<ToastMessage title="Company" body="Missing company data" />, {
        type: "error",
        position: "top-right",
      });
      return;
    }

    const q = query(
      collection(
        db,
        `${collection_name.v2_company}`,
        companyId,
        "invited_members"
      ),
      orderBy("created_at")
    );
    let curr_invite_state: { [key: string]: IInvitedMember[] } = {
      [InviteStatus.pending]: [],
      [InviteStatus.accepted]: [],
      [InviteStatus.rejected]: [],
    };
    if (invitedMembers) {
      curr_invite_state = {
        ...invitedMembers,
      };
    }
    const unsubscribe = onSnapshot(q, (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        if (change.type === "added") {
          const inviteDoc = change.doc.data();
          const parsed_created_at = inviteDoc.created_at
            ? new Date(inviteDoc.created_at.seconds * 1000).toISOString()
            : "";

          const invites_doc: IInvitedMember = {
            created_at: parsed_created_at,
            invite_link: inviteDoc?.invite_link,
            receiversEmail: inviteDoc?.receiversEmail,
            sendersEmail: inviteDoc?.sendersEmail,
            sendersId: inviteDoc?.sendersId,
            status: inviteDoc?.status,
          };
          if (
            invites_doc.status.toLowerCase() ===
            InviteStatus.pending.toLowerCase()
          ) {
            curr_invite_state = {
              ...curr_invite_state,
              [InviteStatus.pending.toLowerCase()]: _uniqBy(
                [
                  ...curr_invite_state[InviteStatus.pending.toLowerCase()],
                ].concat({ ...invites_doc }),
                "receiversEmail"
              ),
            };
          }
          if (
            invites_doc.status.toLowerCase() ===
            InviteStatus.accepted.toLowerCase()
          ) {
            curr_invite_state = {
              ...curr_invite_state,
              [InviteStatus.accepted.toLowerCase()]: _uniqBy(
                [
                  ...curr_invite_state[InviteStatus.accepted.toLowerCase()],
                ].concat({ ...invites_doc }),
                "receiversEmail"
              ),
            };
          }
          if (
            invites_doc.status.toLowerCase() ===
            InviteStatus.rejected.toLowerCase()
          ) {
            curr_invite_state = {
              ...curr_invite_state,
              [InviteStatus.rejected.toLowerCase()]: _uniqBy(
                [
                  ...curr_invite_state[InviteStatus.rejected.toLowerCase()],
                ].concat({ ...invites_doc }),
                "receiversEmail"
              ),
            };
          }
        }

        if (change.type === "modified") {
          const updated_invite = change.doc.data();

          const updatedInvite: IInvitedMember = {
            created_at: updated_invite.created_at
              ? new Date(updated_invite.created_at.seconds * 1000).toISOString()
              : "",
            invite_link: updated_invite?.invite_link,
            receiversEmail: updated_invite?.receiversEmail,
            sendersEmail: updated_invite?.sendersEmail,
            sendersId: updated_invite?.sendersId,
            status: updated_invite?.status,
          };

          const invite_status: string = updatedInvite.status.toLowerCase();

          if (global_invited_members) {
            if (invite_status === InviteStatus.accepted.toLowerCase()) {
              const acc_list = _get(global_invited_members, "pending") || [];
              const filtered_pending_list = acc_list.filter(
                (el: { [key: string]: string }) => {
                  return el.receiversEmail !== updatedInvite.receiversEmail;
                }
              );
              curr_invite_state = {
                ...curr_invite_state,
                [InviteStatus.pending.toLowerCase()]: _uniqBy(
                  [...filtered_pending_list],
                  "receiversEmail"
                ),
                [InviteStatus.accepted.toLowerCase()]: _uniqBy(
                  [
                    ...curr_invite_state[InviteStatus.accepted.toLowerCase()],
                  ].concat({ ...updatedInvite }),
                  "receiversEmail"
                ),
              };
            }

            if (invite_status === InviteStatus.pending.toLowerCase()) {
              const acc_list = _get(global_invited_members, "accepted") || [];
              const filtered_accepted_list = acc_list.filter(
                (el: { [key: string]: string }) => {
                  return el.receiversEmail !== updatedInvite.receiversEmail;
                }
              );
              curr_invite_state = {
                ...curr_invite_state,
                [InviteStatus.pending.toLowerCase()]: _uniqBy(
                  [
                    ...curr_invite_state[InviteStatus.pending.toLowerCase()],
                  ].concat({ ...updatedInvite }),
                  "receiversEmail"
                ),
                [InviteStatus.accepted.toLowerCase()]: _uniqBy(
                  [...filtered_accepted_list],
                  "receiversEmail"
                ),
              };
            }

            if (invite_status === InviteStatus.rejected.toLowerCase()) {
              const acc_pending_list =
                _get(global_invited_members, "pending") || [];

              const filtered_pending_list = acc_pending_list.filter(
                (el: { [key: string]: string }) => {
                  return el.receiversEmail !== updatedInvite.receiversEmail;
                }
              );

              curr_invite_state = {
                ...curr_invite_state,
                [InviteStatus.rejected.toLowerCase()]: _uniqBy(
                  [
                    ...curr_invite_state[InviteStatus.rejected.toLowerCase()],
                  ].concat({ ...updatedInvite }),
                  "receiversEmail"
                ),
                [InviteStatus.pending.toLowerCase()]: _uniqBy(
                  [...filtered_pending_list],
                  "receiversEmail"
                ),
                [InviteStatus.accepted.toLowerCase()]: _uniqBy(
                  [...filtered_pending_list],
                  "receiversEmail"
                ),
              };
            }
          }
        }
      });

      const invited_members = {
        [InviteStatus.pending]: sortInvite(
          _uniqBy(
            curr_invite_state[InviteStatus.pending.toLowerCase()],
            "receiversEmail"
          )
        ),
        [InviteStatus.accepted]: sortInvite(
          _uniqBy(
            curr_invite_state[InviteStatus.accepted.toLowerCase()],
            "receiversEmail"
          )
        ),
        [InviteStatus.rejected]: sortInvite(
          _uniqBy(
            curr_invite_state[InviteStatus.rejected.toLowerCase()],
            "receiversEmail"
          )
        ),
      };
      setInvitedMembers(invited_members);
    });
    return () => {
      unsubscribe();
    };
  }, [companyId]);

  const getCompany = React.useCallback(() => {
    if (companyId === "new-company") {
      return;
    }

    if (!companyId) {
      toast(<ToastMessage title="Company" body="Missing company data" />, {
        type: "error",
        position: "top-right",
      });
      return;
    }
    const unsubscribe = onSnapshot(
      doc(db, `${collection_name.v2_company}/${companyId}`),
      (doc) => {
        const data = doc.data() as ICompany;
        const company: ICompany = { ...data, company_id: doc.id };
        setCompany(company);
        setSelectedIndustries(company?.industries || []);
      }
    );
    return () => {
      unsubscribe();
    };
  }, [companyId]);

  React.useEffect(() => {
    if (companyId !== "new-company") {
      setIsNewCompany(false);
      getCompany();
    }
  }, [getCompany]);

  React.useEffect(() => {
    getInviteStatus();
  }, [getInviteStatus]);

  return (
    <PrivateLayout menu="Companies">
      <CompanyComponent
        onSubmit={onSubmit}
        updateSelectedIndustries={updateSelectedIndustries}
        handleCheckCheckBox={handleCheckCheckBox}
        handleSendInvite={handleSendInvite}
        handleChangeInvite={handleChangeInvite}
        handleOnBlurInvite={handleOnBlurInvite}
        isUpdatingCompany={companyUpdateReduxState.status}
        isCreatingCompany={companyCreateReduxState.status}
        workType={workType}
        inviteResponse={inviteReduxState}
        userDisplayName={"Company Name"}
        company={company}
        selectedIndustries={selectedIndustries}
        inviteEmail={inviteEmail}
        invitedMembers={invitedMembers}
        isNewCompany={isNewCompany}
      />
      <Dialog
        openDialog={openDialog}
        isLoading={isLoading}
        handleCloseDialog={handleCloseDialog}
        handleAcceptDialog={handleAcceptDialog}
        body={`Invite an existing account`}
      />
    </PrivateLayout>
  );
});

CompanyProfile.displayName = "CompanyProfile";
export default CompanyProfile;
