import React from "react";
import PrivateLayout from "layouts/drawer";

const Home = () => {
  return (
    <PrivateLayout menu="Home">
      <div>Hello there</div>
    </PrivateLayout>
  );
};

export default Home;
