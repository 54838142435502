import React, { useRef } from "react";
import SharedButton from "components/shared/button/button";
import { _get, _first } from "utils/lodash";

import Upload from "assets/images/upload.svg";
import Pdf from "assets/images/pdf.svg";
import Doc from "assets/images/doc.svg";

import Box from "@mui/material/Box";
import { styled } from "@mui/system";

import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import ToastMessage from "components/toast-message";
import { toast } from "react-toastify";

const CustomBox = styled(Box)`
  display: flex;
  justify-content: center;
  height: 200px;
  background: ${({ theme }) => theme.palette.grey[900]};
  border-radius: 12px;
  cursor: pointer;
  align-items: center;
`;

const FileName = styled("div")`
  font-size: 12px;
  font-weight: 500;
  padding-top: 12px;
  padding-bottom: 12px;
`;

const UploadingState = styled("div")`
  font-size: 15px;
  font-weight: 500;
  padding-top: 12px;
  padding-bottom: 12px;
`;

const Container = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

interface IProps {
  // eslint-disable-next-line no-unused-vars
  handleSetFile: (file: File | null) => void;
  handleRemoveFile: () => void;
  handleCancel: () => void;
  file?: File | null;
  uploading: boolean;
  progress: number;
}

interface ICustomButton {
  onClick: () => void;
  text: string;
  Component?: any;
}

const FileUpload = ({
  handleSetFile,
  file,
  handleRemoveFile,
  handleCancel,
  uploading,
  progress,
}: IProps) => {
  const fileInput = useRef(null);

  const handleClick = () => {
    const current = _get(fileInput, "current");
    if (current) current.click();
  };

  const Icon = {
    "application/pdf": Pdf,
    "application/vnd": Doc,
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileUploaded = _first(_get(event, "target.files", []));
    handleSetFile(fileUploaded);
  };

  const removeFile = async () => {
    handleSetFile(null);
    await handleRemoveFile();
  };

  const cancelUpload = () => {
    try {
      handleCancel();
      handleSetFile(null);
    } catch {
      toast(
        <ToastMessage title="Cancel Upload" body="Unable to cancel upload" />,
        {
          type: "warning",
          position: "top-right",
        }
      );
    }
  };

  const UploadButton = () => (
    <>
      <img src={Upload} alt="Upload" />
      <Box sx={{ marginLeft: "8px", textTransform: "initial" }}>Upload</Box>
    </>
  );

  const CustomButton = ({ onClick, text, Component }: ICustomButton) => {
    return (
      <SharedButton
        onClick={onClick}
        sx={{
          width: "auto",
          background: "white",
          border: (theme) => `1px solid ${theme.palette.grey[50]}`,
          color: (theme) => theme.palette.text.secondary,
          height: "48px",
          "&:hover": {
            background: (theme) => _get(theme, "palette.colors.light_grey"),
            border: (theme) =>
              `1px solid ${_get(theme, "palette.colors.light_grey")}`,
          },
        }}
        variant="contained"
      >
        {Component ? (
          <Component />
        ) : (
          <Box sx={{ marginLeft: "8px", textTransform: "initial" }}>{text}</Box>
        )}
      </SharedButton>
    );
  };

  const ShowProgress = () => {
    return (
      <Container>
        <Box sx={{ position: "relative", display: "inline-flex" }}>
          <CircularProgress
            variant="determinate"
            value={progress}
            size="60px"
            sx={{ color: (theme) => _get(theme, "palette.secondary.main") }}
          />
          <Box
            sx={{
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              position: "absolute",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography variant="caption" component="div" color="inherit">
              {`${progress}%`}
            </Typography>
          </Box>
        </Box>
        <UploadingState>Uploading...</UploadingState>
        <CustomButton onClick={cancelUpload} text="Cancel" />
      </Container>
    );
  };

  const ShowUploadedFile = () => {
    if (!file) return null;
    const fileType: string = _first(file.type.split("."));
    const FileIcon = _get(Icon, fileType);
    return (
      <Container>
        <img src={FileIcon} alt="Upload" />
        <FileName>{file.name}</FileName>
        <CustomButton onClick={removeFile} text="Remove" />
      </Container>
    );
  };

  return (
    <CustomBox onClick={handleClick}>
      {uploading ? (
        <ShowProgress />
      ) : file ? (
        <ShowUploadedFile />
      ) : (
        <>
          <CustomButton
            onClick={removeFile}
            text="Remove"
            Component={UploadButton}
          />
          <input
            name="file"
            type="file"
            accept=".pdf, .docx, .doc"
            ref={fileInput}
            style={{ display: "none" }}
            onChange={handleChange}
          />
        </>
      )}
    </CustomBox>
  );
};

export default FileUpload;
