export const topics = [
  "What data do we collect?",
  "How do we collect your data?",
  "How will we use your data?",
  "Who receives your data?",
  "What are your data protection rights?",
  "How do we use cookies?",
  "What types of cookies do we use?",
  "How to manage cookies and similar technologies",
  "How to contact us",
];
export const dataCollectionMethods = [
  "Register online or place an order for any of our products or services",
  "Voluntarily complete a customer survey or provide feedback on any of our message boards or via email",
  "Use or view our website via your browser",
  "Join our events",
  "Join our webinars",
  "Subscribe to our newsletter to receive updates",
];
export const protectionRights = [
  "The right to access – You have the right to request ACELR8 Ltd for copies of your personal data. " +
    "We may charge you a small fee for this service.",
  "The right to rectification – You have the right to request that ACELR8 Ltd correct any information " +
    "you believe is inaccurate. You also have the right to request ACELR8 Ltd to complete the information " +
    "you believe is incomplete.",
  "The right to erasure – You have the right to ask ACELR8 Ltd to delete your data at any point in time. " +
    "Please keep in mind that we might have to store some data, e.g. billing or tax related, for up to " +
    "six or even ten years. This only applies to data that falls under legal data retention obligations.",
  "The right to restrict processing – You have the right to request that ACELR8 Ltd restrict the processing of " +
    "your personal data, under certain conditions.",
  "The right to object to processing – You have the right to object to ACELR8 Ltd’s processing of your " +
    "personal data, under certain conditions.",
  "The right to data portability – You have the right to request that ACELR8 Ltd transfer the data that we have " +
    "collected to another organization, or directly to you, under certain conditions.",
  "The right to file a complaint with the supervisory authority – You have the right to contact your local " +
    "supervisory authority and file a complaint. If you make a request, we have one month to respond to you. " +
    "We have also designated a data protection officer. If you would like to exercise any of these rights, " +
    "please contact us at our Call us: +44 2071 938069 email: catia@acelr8.com",
];

export const dataUsage =
  "ACELR8 Ltd collects your data so that we can: Process your order and manage your account. Email you with content. " +
  "Contact you about our services. The collection and use of your data is either based on the performance of a " +
  "contract that you are part of or based on your voluntary consent. If it is based on consent, you can always " +
  "contact us to revoke that consent";

export const dataRecipient =
  "ACELR8 Ltd relies on audited service providers, to whom we might send your data to if it is " +
  "necessary for the performance of our services. We available safeguards. We will keep your contact data only " +
  "for a strictly necessary time period, e.g. as long as you are our customer or as long as you do not opt-out. " +
  "If you subscribe to our newsletter through our website or register on external sites such as a webinar, " +
  "we will send you information about products and services. You may always opt-out at any time. We use Mailchimp," +
  " for our newsletters and e-mail campaigns. Mailchimp is a US service provider, with whom we have concluded " +
  "all available safeguards. When joining our events, we use well known platforms like Meetup or securely store " +
  "your data in our customer management system Mailchimp. Webinars are hosted on Livestorm. Livestorm is a US " +
  "service provider, with whom we have concluded all available safeguards. If you join through an external " +
  "platform, you will most likely accept their terms and services, so please read carefully. We will however " +
  "always ensure that we choose the best service provider together with our data protection officer. " +
  "This includes all required assessments and agreements to safeguard your personal data.";

export const dataManagement =
  "Interested applicants can apply through the “Careers” option we provide on our website. " +
  "We use the online hiring tool Workable to manage new applicants. If you apply for an open role, " +
  "you will be redirected to aWorkable webpage that is managed by us. Workable is a US service provider, " +
  "with whom we have concluded all available safeguards. We will keep your applicant data for six months, " +
  "except in those cases where you have given consent for extended storage (e.g. talent pool).";

export const cookieTypes =
  "There are a number of different types of cookies, however, our website uses: Functionality – ACELR8 Ltd " +
  "uses these cookies so that we recognize you on our website and remember your previously selected preferences. " +
  "These could include what language you prefer and location you are in. A mix of first-party and third-party " +
  "cookies are used. Advertising – ACELR8 Ltd uses these cookies to collect information about your visit to our" +
  " website, the content you viewed, the links you followed and information about your browser, device, " +
  "and your IP address. ACELR8 Ltd sometimes shares some limited aspects of this data with third parties " +
  "for advertising purposes. We may also share online data collected through cookies with our " +
  "advertising partners. This means that when you visit another website, you may be shown " +
  "advertising based on your browsing patterns on our website.";

export const manageCookies =
  "You can set your browser not to accept cookies. Depending on the technologies " +
  "we currently use, you can also change settings through our cookie banner. However, in a few cases, " +
  "some of our website features may not function as a result.";

export default {
  protectionRights,
  topics,
  dataCollectionMethods,
  dataUsage,
  dataRecipient,
  dataManagement,
  cookieTypes,
  manageCookies,
};
