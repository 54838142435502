/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from "@reduxjs/toolkit";
import { functions, httpsCallable } from "utils/firebase";
import { IRemoveState, ISlack, ICompany } from "./interface";
import * as axios from "api/axios";
const axiosInstance = axios.default;

export const doRemoveSlackUserAction = createAsyncThunk(
  "clients-removeSlackUsers",
  async (payload: IRemoveState) => {
    try {
      const userData = await axiosInstance.post("/slack-delete", {
        email: payload.email,
        uid: payload.uid,
        slack_access_token: payload.slack_access_token,
        slack_user_access_token: payload.slack_user_access_token,
        role: payload.role,
      });
      return userData;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      return error;
    }
  }
);

export const doAddCompanies = createAsyncThunk(
  "clients-addCompanie",
  async (payload: ICompany) => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const userData: any = await httpsCallable(
        functions,
        "api-http-clientsSlack-onAddCompany"
      )({
        email: payload.email,
        company_id: payload.company_id,
        first_name: payload.first_name,
        authed_user_id: payload.slack_authed_user_id,
        access_token: payload.slack_access_token,
      });
      if (userData.data.success) {
        return userData;
      }
    } catch (error: any) {
      return error.response?.data || "Something went wrong";
    }
  }
);

export const doSendCandidateSlackNotifications = createAsyncThunk(
  "slack-candidate-notifications",
  async (payload: ISlack) => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const slackData: any = await httpsCallable(
        functions,
        "api-http-clientsSlack-onCandidateNotification"
      )({
        job_title: payload.job_title,
        job_url: payload.job_url,
        current_user_email: payload.current_user_email,
        company_id: payload.company_id,
      });
      if (slackData.data.success) {
        return slackData;
      }
    } catch (error: unknown) {
      return error;
    }
  }
);
