import * as React from "react";
import DatePicker from "react-datepicker";
import { Box } from "@mui/material";
import Popover from "@mui/material/Popover";
import Close from "@mui/icons-material/Close";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import "./custom.scss";

interface IProps {
  // eslint-disable-next-line no-unused-vars
  onChange: (dates: [Date | null, Date | null]) => void;
  open: boolean;
  handleClose: () => void;
  startDate: Date | null;
  endDate: Date | null;
  clearDates: () => void;
  anchorEl: any;
}

const DateRangePicker: React.FC<IProps> = ({
  onChange,
  open,
  handleClose,
  startDate,
  endDate,
  clearDates,
  anchorEl,
}) => {
  return (
    <>
      <Popover
        open={open}
        onClose={handleClose}
        aria-labelledby="calender"
        aria-describedby="Calendar"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        anchorEl={anchorEl}
      >
        <Box
          sx={{
            width: "auto",
            bgcolor: "white",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              py: "5px",
            }}
          >
            <Box onClick={() => clearDates()}>
              <RestartAltIcon
                sx={{
                  ml: "5px",
                  height: "20px",
                  width: "20px",
                  left: "1.5px",
                  top: "1.5px",
                  cursor: "pointer",
                  fontWeight: 600,
                }}
              />
            </Box>
            <Box onClick={() => handleClose()}>
              <Close
                sx={{
                  ml: "5px",
                  height: "20px",
                  width: "20px",
                  left: "1.5px",
                  top: "1.5px",
                  cursor: "pointer",
                  fontWeight: 600,
                }}
              />
            </Box>
          </Box>
          <DatePicker
            selected={startDate}
            onChange={onChange}
            startDate={startDate}
            endDate={endDate}
            selectsRange
            inline
            isClearable
          />
        </Box>
      </Popover>
    </>
  );
};

export default DateRangePicker;
