import * as React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import * as ROUTES from "./route-items";
import { auth as firebaseAuth } from "utils/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import storage from "redux-persist/lib/storage";
import { useAppDispatch } from "redux/store";
import { resetUsers } from "redux/services/user/user";
import { useReadLocalStorage } from "usehooks-ts";
import { IUser } from "types/types";

interface IProps {
  user: IUser;
}

const PrivateRoute: React.FC<IProps> = ({ user }) => {
  let location = useLocation();
  const auth = firebaseAuth.getAuth();
  const [loggedOut, setLoggedOut] = React.useState(false);
  const [authUser] = useAuthState(auth);
  const dispatch = useAppDispatch();
  const localStorage = useReadLocalStorage("persist:root");

  React.useEffect(() => {
    if ((!authUser?.uid && !user) || !localStorage) {
      dispatch(resetUsers());
      setLoggedOut(true);
      storage.removeItem("persist:root");
    }
  }, [authUser, user, localStorage]);

  if (user?.uid && !loggedOut) {
    return <Outlet />;
  }

  return (
    <Navigate to={ROUTES.ROUTE_SIGN_IN} state={{ from: location }} replace />
  );
};

export default PrivateRoute;
