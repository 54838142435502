import { yupResolver } from "@hookform/resolvers/yup";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import { ReactComponent as LockIcon } from "assets/images/lock.svg";
import { ReactComponent as Person } from "assets/images/person.svg";
import { ReactComponent as Warning } from "assets/images/warning.svg";
import AuthForm from "components/auth-form/auth-form";
import SharedButton from "components/shared/button/button";
import Form from "components/shared/form/form";
import Input from "components/shared/input/input";
import Text from "components/shared/text/text";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { SignUpSchema } from "utils/form-schema";
import { styled } from "@mui/system";
import CircularProgress from "@mui/material/CircularProgress";

interface IState {
  email: string;
  password?: string;
  firstName: string;
  lastName: string;
  company_name?: string;
  isLoading?: boolean;
}

interface IProps {
  // eslint-disable-next-line no-unused-vars
  onSubmit: (data: IState) => void;
  state: IState;
  reduxStatus: string;
}

const FormContainer = styled(Form)`
  max-width: 330px;
  align-items: center;
`;

const CompleteSignUp: React.FC<IProps> = ({ onSubmit, state, reduxStatus }) => {
  const theme = useTheme();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<IState>({
    resolver: yupResolver(SignUpSchema),
  });

  useEffect(() => {
    setValue("email", state.email);
  }, [state.email]);

  return (
    <AuthForm
      id="complete-sign-up"
      title="Complete Sign up"
      sx={{ maxWidth: "54%" }}
    >
      <Box display="flex" flexDirection="column" sx={{ maxWidth: "90%" }}>
        <Text
          fontSize="14px"
          lineHeight="150%"
          fontWeight={400}
          letterSpacing="-0.01em"
          sx={{
            marginLeft: 0,
            marginTop: ".5rem",
            marginBottom: "1.3rem",
            color: theme.palette.text.primary,
            textAlign: "left",
          }}
        >
          Let’s set up your account
        </Text>
        <FormContainer onSubmit={handleSubmit(onSubmit)}>
          <Input
            iconStart={
              <MailOutlineIcon
                sx={{ color: theme.palette.text.primary, fontSize: 25 }}
              />
            }
            iconEnd={errors?.email?.message ? <Warning /> : null}
            sx={{
              marginBottom: "20px",
              position: "relative",
            }}
            placeholder="Your work email"
            variant="filled"
            id="email"
            register={register}
            errors={errors?.email?.message}
            disabled={!!state.company_name}
          />
          <Input
            iconStart={<LockIcon />}
            iconEnd={errors?.password?.message ? <Warning /> : null}
            sx={{
              marginBottom: "20px",
              position: "relative",
            }}
            type="password"
            placeholder="Password"
            variant="filled"
            id="password"
            register={register}
            errors={errors?.password?.message}
          />
          <Input
            iconStart={<Person />}
            iconEnd={errors?.firstName?.message ? <Warning /> : null}
            sx={{
              marginBottom: "20px",
              position: "relative",
            }}
            placeholder="First Name"
            variant="filled"
            id="firstName"
            register={register}
            errors={errors?.firstName?.message}
          />
          <Input
            iconStart={<Person />}
            iconEnd={errors?.lastName?.message ? <Warning /> : null}
            sx={{
              marginBottom: "20px",
              position: "relative",
            }}
            placeholder="Last Name"
            variant="filled"
            id="lastName"
            register={register}
            errors={errors?.lastName?.message}
          />
          <SharedButton type="submit" variant="contained">
            <Box
              sx={{
                marginLeft: "6px",
                textTransform: "initial",
                display: "flex",
                alignItems: "center",
              }}
            >
              {reduxStatus === "pending" || state.isLoading ? (
                <>
                  <CircularProgress
                    sx={{ marginRight: "10px" }}
                    color="inherit"
                    size="20px"
                  />
                  <span>Loading ...</span>
                </>
              ) : (
                "Sign up"
              )}
              {/* // {reduxStatus === "pending" ? "Loading ..." : "Sign up"} */}
            </Box>
          </SharedButton>
        </FormContainer>
      </Box>
    </AuthForm>
  );
};

export default CompleteSignUp;
