import { AsyncThunk, createAsyncThunk } from "@reduxjs/toolkit";
import { ICreateJobPayload } from "./interface";
import { collection, db, doc, serverTimestamp, setDoc } from "utils/firebase";
import { IJobStatus } from "types/types";

export const doCreateJobAction: AsyncThunk<
  ICreateJobPayload,
  ICreateJobPayload,
  {}
> = createAsyncThunk("job/create", async (payload, { rejectWithValue }) => {
  if (payload.step !== "step_3") {
    return payload;
  }

  try {
    const jobsRef = collection(db, "v2_jobs");
    const job_id = doc(jobsRef).id;
    const data = {
      ...payload.job,
      job_id,
      status: IJobStatus.ACTIVE,
      created_at: serverTimestamp(),
    };

    const jobRef = doc(db, `v2_jobs/${job_id}`);
    await setDoc(jobRef, data, { merge: true });

    const newPayload: ICreateJobPayload = {
      ...payload,
      job: {
        ...payload.job,
        objectID: job_id,
        job_id,
        status: IJobStatus.ACTIVE,
      },
    };

    return newPayload;
  } catch (error: unknown) {
    return rejectWithValue(JSON.stringify(error));
  }
});
