/* eslint-disable no-unused-vars */
export interface IUser {
  firstName: string;
  lastName: string;
  email: string;
  created_at: any;
  uid: string;
  role: IRoleType;
  company_name: string;
  company_id: string;
  original_avatar_url: string;
  thumbnail_avatar_url: string;
  accepted_terms: boolean;
  next_onboarding_stage: string;
  is_active: boolean;
  authed_user_id: string;
  slack_access_token: string;
  slack_app_id: string;
  slack_user_access_token: string;
  integrations: {
    Slack: {
      selected: boolean;
    };
    ATS: {
      selected: boolean;
    };
  };
  notifications: {
    email: INotificationTypes;
    slack: INotificationTypes;
  };
}

export interface INotificationTypes {
  new_candidate?: boolean;
}

export enum IAuthType {
  GOOGLE_AUTH = "GOOGLE",
  EMAIL_PASS = "EMAIL_PASS",
}

export enum IRoleType {
  RECRUITER = "RECRUITER",
  CLIENT = "CLIENT",
}

export enum IWorkType {
  REMOTE = "REMOTE",
  PARTIAL_REMOTE = "PARTIAL_REMOTE",
  OFFICE = "OFFICE",
}

export enum IJobStatus {
  ACTIVE = "ACTIVE",
  ARCHIVE = "ARCHIVED",
}

const jobStatusOptions = ["ACTIVE", "ARCHIVED", "MY_JOBS", "ALL_JOBS"] as const;
export type TJobStatusOption = typeof jobStatusOptions[number];

export enum InviteStatus {
  pending = "pending",
  accepted = "accepted",
  rejected = "rejected",
}

export interface IJob {
  seniority_level?: string;
  job_function?: string;
  custom_job_function?: string | null;
  job_description?: string;
  recruiter_notes?: string;
  job_description_link?: string;
  job_title?: string;
  skills_must_have?: { [key: string]: string };
  skills_nice_to_have?: { [key: string]: string };
  skills_not_to_have?: { [key: string]: string };
  job_exciting_things?: { [key: string]: string };
  salary_range?: number[];
  selected_currency?: { value: string; label: string };
  linked_ats_job_details?: { [key: string]: string } | undefined | string;
  checkbox_pay_unknown?: boolean;
  checkbox_share_salary_range?: boolean;
  company_id?: string;
  company_name?: string;
  created_by?: string;
  status?: IJobStatus;
  job_id?: string;
  created_at?: any;
  updated_at?: any;
  attached_candidates?: {
    total?: number;
    PENDING?: number;
    SHORTLISTED?: number;
    REJECTED?: number;
    NEEDED?: number;
    archived?: number;
  };
  recruiters_attached?: string[];
  objectID?: string;
}

export interface IUploadData {
  userType: IRoleType;
  target_id: string;
  target_name: string;
  file: string;
  sizes: number[];
}

export interface IUploadAvatarRes {
  result?: boolean;
  original_avatar_url?: string;
  thumbnail_avatar_url?: string;
  code?: string | number;
  message?: string;
}

export interface IUploadCVRes {
  result?: boolean;
  cv_url?: string;
  code?: string | number;
  message?: string;
}
export interface IFileUploadData {
  file: string | ArrayBuffer | null;
  filepath: string;
  sizes?: number[];
}

export interface IImageResponse {
  data: {
    originalFileURL: string;
    avatarThumbnailUrl: string;
  };
}

export interface IFileResponse {
  data: {
    originalFileURL: string;
  };
}

export enum IDBVersion {
  V2_CANDIDATES = "V2_CANDIDATES",
  CANDIDATES = "CANDIDATES",
}

export enum IInterviewStatus {
  PENDING = "PENDING",
  SHORTLISTED = "SHORTLISTED",
  REJECTED = "REJECTED",
  ARCHIVED = "archived",
}

interface ICandidateEducation {
  date_range: string;
  degree_title: string;
  end_at: any;
  institution_name: string;
  start_at: any;
}

interface ICandidateExperience {
  companyName?: string;
  company_name?: string;
  companyUrl: string;
  dateRange?: string;
  date_range?: string;
  description: string;
  jobTitle?: string;
  title?: string;
  location: string;
  logoUrl: string;
}

interface ICandidateSkill {
  is_top_skill: boolean;
  name: string;
  rate: number;
}

export interface ISelectedSkill {
  name: string;
  selected: boolean;
}

export interface ICandidate {
  firstName: string;
  lastName: string;
  email?: string;
  created_at?: any;
  candidate_id: string;
  candidate_avatar_url: string;
  candidate_experiences?: ICandidateExperience[];
  connected_links?: { [keys: string]: string }[];
  educations?: ICandidateEducation[];
  experience?: { [keys: string]: string }[];
  selectedJobs?: { [keys: string]: string }[];
  skills?: ICandidateSkill[];
  currency?: string;
  experience_level?: string;
  is_open_to_remote?: boolean;
  is_archived?: boolean;
  creation_type?: string;
  jobTitle?: string;
  job_function?: string;
  job_id: string;
  company_id: string;
  linked_in?: string;
  linkedin_id?: string;
  original_avatar_url?: string;
  recruiter_notes?: string;
  location?: string;
  portfolio?: string;
  thumbnail_avatar_url?: string;
  reasonForNotRelevance?: string;
  reasonForRelevance?: string;
  source?: string;
  hasAttachedCV?: boolean;
  max_salary_per_year?: number;
  min_salary_per_year?: number;
  file?: any;
  skills_must_have?: ISelectedSkill[];
  skills_nice_to_have?: ISelectedSkill[];
  phone?: string;
  currentCompany?: string;
  pronouns?: string;
  cv_url?: string;
  cv_file_name?: string;
  updated_at: any;
  IDBVersion: IDBVersion;
}

export interface IConnectedCandidate extends ICandidateInterview, ICandidate {}

export type TCandidateUpdate = {
  firstName: string;
  lastName: string;
  role: IRoleType;
  uid: string;
  updated_at: any;
};

export interface ICandidateInterview {
  interview_status: IInterviewStatus;
  reason: string;
  updated_by: TCandidateUpdate;
}

export interface IModalState {
  add_candidate?: boolean;
  edit_candidate?: boolean;
}

export interface ICompany {
  company_id: string;
  created_at: any;
  industries: string[];
  location: string;
  name: string;
  role: IRoleType;
  uid: string;
  work_type: IWorkType;
  original_avatar_url: string;
  thumbnail_avatar_url: string;
  recruiters_attached: string[];
  jobs_without_criteria: [];
  total_jobs: number;
  total_candidates: number;
  billingAddress: string;
  billingEmailAddress: string;
  vatID: string;
  objectID: string;
  invited_members?: { [key: string]: { [key: string]: string } };
}

export interface IInvitedMember {
  created_at: any;
  invite_link: string;
  receiversEmail: string;
  sendersEmail: string;
  sendersId: string;
  status: InviteStatus;
}

export interface IRecruiterMyCompany {
  company_id: string;
  recruiter_id: string;
  action: IRecruiterMyCompanyAction;
}

export enum IRecruiterMyCompanyAction {
  ADD = "ADD",
  REMOVE = "REMOVE",
}

export enum ReduxStatus {
  pending = "pending",
  fulfilled = "fulfilled",
  rejected = "rejected",
  empty = "",
}
