import React, { FC } from "react";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";

import Text from "components/shared/text/text";

interface IProps {
  title: string;
  body?: string;
}

const ToastMessage: FC<IProps> = ({ title = "", body = "" }) => {
  const theme = useTheme();
  return (
    <Box>
      <Text
        fontSize="15px"
        lineHeight="1"
        color={theme.palette.grey[900]}
        sx={{ textAlign: "left" }}
      >
        {title}
      </Text>
      <Text
        fontSize="13px"
        lineHeight="1"
        // @ts-ignore
        color={theme.palette.colors.light_dark_grey}
        sx={{ textAlign: "left", marginTop: "10px" }}
      >
        {body}
      </Text>
    </Box>
  );
};

export default ToastMessage;
