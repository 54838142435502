/* eslint-disable no-unused-vars */
import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { styled } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";
import SharedButton from "components/shared/button/button";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import AsyncSelect from "react-select/async";
import Box from "@mui/material/Box";
import CustomStyles from "./styles";
import clientSearch from "utils/algolia";
import { ICompany } from "types/types";

interface IProps {
  openDialog: boolean;
  isLoading: boolean;
  handleAcceptDialog: (e: { [key: string]: string }) => void;
  handleCloseDialog: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const DialogContainer = styled(Dialog)`
  & .MuiDialog-paperScrollPaper {
    min-width: 35rem;
    overflow: hidden;
    background-color: ${({ theme }) => theme.palette.grey[50]};
  }
  & .MuiDialogTitle-root {
    color: ${({ theme }) => theme.palette.primary.main};
    font-weight: 500;
    font-size: 22px;
    font-family: Inter;
    padding: 0px 24px;
    padding-top: 10px;
    padding-left: 12px;
  }
  & .MuiDialogContentText-root {
    color: ${({ theme }) => theme.palette.primary.main};
    font-weight: 400;
    font-size: 16px;
    font-family: Inter;
  }
  & .MuiLoadingButton-text {
    color: ${({ theme }) => theme.palette.primary.main};
    font-weight: 400;
    font-size: 16px;
    font-family: Inter;
  }
`;

const DisButton = styled(Button)`
  color: ${({ theme }) => theme.palette.primary.main};
  font-weight: 400;
  font-size: 16px;
  font-family: Inter;
`;

const CompanyDialog: React.FC<IProps> = ({
  openDialog,
  handleCloseDialog,
  handleAcceptDialog,
  isLoading,
}) => {
  const [company, setCompany] = React.useState<{ [key: string]: string }>({});

  const fetchCompanies = async (searchQuery: string) => {
    if (searchQuery.length < 3) return [];

    const searchIndex = clientSearch.initIndex("v2_company");
    const searchResponse = await searchIndex.search(searchQuery, {
      page: 0,
    });
    const companies = [...searchResponse.hits] as unknown as ICompany[];
    const selectResponse = companies.map((data) => {
      const company = {
        company_name: data.name,
        company_id: data.company_id,
        value: data.name,
        label: data.name,
      } as { [key: string]: string };
      return company;
    });
    return selectResponse;
  };

  const handleChangeSelect = async (options: any) => {
    setCompany({
      company_id: options.company_id,
      company_name: options.company_name,
    });
  };

  const handleSelectCompany = () => handleAcceptDialog(company);

  return (
    <DialogContainer
      open={openDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            padding: 0,
          }}
        >
          <span>Add a new job</span>
        </DialogTitle>
        <DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleCloseDialog}
            sx={{
              position: "absolute",
              right: 5,
              top: 20,
            }}
          >
            <CloseIcon htmlColor="#000000" />
          </IconButton>
        </DialogTitle>
      </DialogTitle>
      <DialogContent sx={{ minHeight: "27rem", overflowY: "hidden" }}>
        <DialogContentText id="alert-dialog-description">
          Select company to add a new job
        </DialogContentText>
        <Box sx={{ marginTop: ".5rem" }}>
          <AsyncSelect
            menuIsOpen
            closeMenuOnSelect={false}
            cacheOptions
            defaultOptions
            loadOptions={fetchCompanies}
            styles={CustomStyles}
            onChange={handleChangeSelect}
            placeholder={"Type to search..."}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        {Object.keys(company).length ? (
          <SharedButton
            variant="contained"
            onClick={handleSelectCompany}
            sx={{ marginBottom: "1rem", marginRight: "1rem", width: "auto" }}
          >
            {isLoading ? (
              <CircularProgress
                sx={{ marginRight: "10px" }}
                color="inherit"
                size="20px"
              />
            ) : (
              `Continue`
            )}
          </SharedButton>
        ) : null}
      </DialogActions>
    </DialogContainer>
  );
};

export default CompanyDialog;
