import { InputProps } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import { styled, useTheme } from "@mui/material/styles";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import Text from "components/shared/text/text";
import React, { FC, ReactNode, useEffect } from "react";
import { _get } from "utils/lodash";

type IVariant = "outlined" | "standard" | "filled" | undefined;

interface TextProps {
  show_variant: IVariant;
  errors?: string;
  disabled?: boolean;
  text_align?: "left" | "center" | "right";
}
type IProps = TextFieldProps & {
  iconStart?: ReactNode;
  iconEnd?: ReactNode;
  onChange?: React.MouseEventHandler<HTMLButtonElement>;
  InputProps?: InputProps;
  variant?: IVariant;
  sx?: {};
  errorSx?: {};
  placeholder?: string;
  id?: string;
  errors?: string;
  register?: any;
  type?: string;
  disabled?: boolean;
  text_align?: "left" | "center" | "right";
};

const Input = styled(TextField)<TextProps>`
  box-sizing: border-box;
  background-color: ${({ theme, show_variant, errors }) => {
    if (errors?.length) {
      return _get(theme, "palette.colors.light_orange");
    }
    return show_variant === "filled"
      ? theme.palette.grey[900]
      : theme.palette.primary.contrastText;
  }};
  border-radius: 12px;
  width: 100%;
  & input {
    padding: 12px 0;
    color: ${({ theme, errors }) => {
      if (errors?.length) return _get(theme, "palette.colors.red");
    }};
    text-align: ${({ text_align }) => text_align};
    &:focus {
      color: ${({ theme }) => theme.palette.text.secondary};
      background-color: ${({ theme }) => theme.palette.primary.contrastText};
    }
    &:-webkit-autofill {
      box-shadow: none;
      -webkit-text-fill-color: ${({ theme }) => theme.palette.text.secondary};
    }
    &.Mui-disabled {
      -webkit-text-fill-color: ${({ theme }) => theme.palette.text.secondary};
      cursor: not-allowed;
    }
  }
  & .MuiOutlinedInput-root {
    border-radius: 12px;
    padding-left: 10px;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: -0.01em;
    color: ${({ theme }) => theme.palette.primary.dark};
    &:hover {
      background-color: ${({ theme, disabled }) =>
        disabled
          ? theme.palette.grey[900]
          : theme.palette.primary.contrastText};
      color: ${({ theme }) => theme.palette.text.secondary};
    }
    &:hover fieldset {
      border: 2px solid
        ${({ theme, disabled }) =>
          disabled ? "none" : theme.palette.text.primary};
    }
    &.Mui-focused {
      background-color: ${({ theme, disabled }) =>
        disabled
          ? theme.palette.grey[900]
          : theme.palette.primary.contrastText};
    }
    &.Mui-focused fieldset {
      border: 2px solid
        ${({ theme, disabled }) =>
          disabled ? theme.palette.grey[900] : theme.palette.text.primary};
    }
  }
`;

const InputItem: FC<IProps> = ({
  iconStart,
  iconEnd,
  InputProps,
  sx = {},
  errorSx = {},
  placeholder = "",
  variant = "outlined",
  id,
  errors = "",
  type = "text",
  register,
  disabled = false,
  text_align = "left",
  onChange,
  ...props
}) => {
  const app_theme = useTheme();
  const input = React.useRef(null);
  useEffect(() => {
    // @ts-ignore
    input.current && input.current.focus();
  }, [input]);

  return (
    <>
      <Input
        {...props}
        id={id}
        ref={input}
        onChange={(e) => onChange && onChange(e)}
        {...register(id)}
        placeholder={placeholder}
        show_variant={variant}
        text_align={text_align}
        errors={errors}
        type={type}
        disabled={disabled}
        sx={{
          ...sx,
        }}
        InputProps={{
          ...InputProps,
          autoComplete: "off",
          startAdornment: iconStart ? (
            <InputAdornment position="start">{iconStart}</InputAdornment>
          ) : null,
          endAdornment: iconEnd ? (
            <InputAdornment position="end">{iconEnd}</InputAdornment>
          ) : null,
        }}
      />
      {errors ? (
        <Text
          sx={{
            ...errorSx,
            position: "relative",
            textAlign: "left",
            color: app_theme.palette.error.main,
            fontSize: "12px",
            marginTop: "-1.0rem",
            marginBottom: "0.75rem",
            marginLeft: "-0.01px",
            display: "block",
            fontWeight: 500,
            letterSpacing: "-0.01em",
            lineHeight: "normal",
          }}
        >
          {errors}
        </Text>
      ) : null}
    </>
  );
};

export default InputItem;
