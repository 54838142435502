/* eslint-disable comma-dangle */
import * as React from "react";
import Box from "@mui/material/Box";
import { styled, useTheme } from "@mui/material/styles";
import { useSelector } from "react-redux";
import SelectButton from "../job/select-button";
import SelectComponent from "../shared/select/select-component";
import DateRangePicker from "../shared/daterangepicker";
import { ITheme } from "theme";
import { useAppDispatch, RootState } from "redux/store";
import { doGetJobsAction } from "redux/services/job/getJob/get.actions";
import { IJobStatus, TJobStatusOption } from "types/types";
import { job_function } from "constants/constants";
import CalendarTodayIconOutlined from "@mui/icons-material/CalendarTodayOutlined";
import { IJobsState } from "./interface";
import Input from "components/shared/input/input";
import { debounce } from "lodash";
import SearchIcon from "assets/images/search-light-icon.svg";

const JobOptions = [
  { value: "MY_JOBS", label: "My Jobs" },
  { value: "ALL_JOBS", label: "All Jobs" },
  { value: IJobStatus.ARCHIVE, label: "Archived Jobs" },
];

const getIndustryList = () => {
  const industry_options: {
    [key: string]: { title: string; selected: boolean };
  } = job_function;
  const keys = Object.keys(job_function);
  let list: { [key: string]: string }[] = [
    { value: "All_job_functions", label: "All job functions" },
  ];
  keys.map((el: string) => {
    const curr_func: { title: string; selected: boolean } =
      industry_options[el];
    list.push({
      value: curr_func.title.split(" ").join("_"),
      label: curr_func.title,
    });
  });
  return list;
};

const HeaderContainer = styled(Box)`
  margin-bottom: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
interface IProps {
  setJobsState: Function;
}

export default function JobsHeader({ setJobsState }: IProps) {
  const theme: ITheme = useTheme();
  const dispatch = useAppDispatch();
  const jobsGetState = useSelector(
    (reduxState: RootState) => reduxState.jobsGet.response
  );

  interface IJobStatusOption {
    value: TJobStatusOption;
    label: string;
  }

  const handleFilterByStatus = async (options: IJobStatusOption) => {
    if (options.value === jobsGetState.jobStatus) {
      return;
    }

    setJobsState((st: IJobsState) => ({
      ...st,
      jobStatus: options.value,
      job_list: [],
      page: 0,
      hasMore: true,
    }));
    await dispatch(
      doGetJobsAction({
        ...jobsGetState,
        jobs: [],
        jobStatus: options.value,
        page: 0,
        hasMore: true,
      })
    );
  };

  const handleFilterByJobFunction = async (options: {
    label: string;
    value: string;
  }) => {
    if (options.value === jobsGetState.selected_job_function) {
      return;
    }

    setJobsState((st: IJobsState) => ({
      ...st,
      selected_job_function: options.value,
      job_list: [],
      page: 0,
      hasMore: true,
    }));

    await dispatch(
      doGetJobsAction({
        ...jobsGetState,
        jobs: [],
        selected_job_function: options.value,
        page: 0,
        hasMore: true,
      })
    );
  };

  const [startDate, setStartDate] = React.useState<Date | null>(new Date());
  const [endDate, setEndDate] = React.useState<Date | null>(null);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const [searchQuery, setSearchQuery] = React.useState("");

  const open = Boolean(anchorEl);
  const button_id = open ? "calendar-popover" : undefined;
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onDateRangeChange = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;

    start?.setHours(0, 0, 0, 0);
    end?.setHours(23, 59, 59, 999);

    setStartDate(start);
    setEndDate(end);

    if (
      start?.toISOString() === jobsGetState.period?.start &&
      end?.toISOString() === jobsGetState.period?.end
    ) {
      handleClose();
      return;
    }

    if (end) {
      handleClose();
      setJobsState((st: IJobsState) => ({
        ...st,
        period: {
          start: start?.toISOString(),
          end: end?.toISOString(),
        },
        job_list: [],
        page: 0,
        hasMore: true,
      }));
      dispatch(
        doGetJobsAction({
          ...jobsGetState,
          jobs: [],
          period: {
            start: start?.toISOString(),
            end: end?.toISOString(),
          },
          page: 0,
          hasMore: true,
        })
      );
    }
  };

  const clearDates = () => {
    if (endDate === null) {
      handleClose();
      return;
    }

    setStartDate(null);
    setEndDate(null);
    setJobsState((st: IJobsState) => ({
      ...st,
      period: {
        start: null,
        end: null,
      },
      job_list: [],
      page: 0,
      hasMore: true,
    }));
    dispatch(
      doGetJobsAction({
        ...jobsGetState,
        jobs: [],
        period: {
          start: null,
          end: null,
        },
        page: 0,
        hasMore: true,
      })
    );
    handleClose();
  };

  const defaultStatus = jobsGetState.jobStatus
    ? JobOptions.find((op) => op.value === jobsGetState.jobStatus)
    : JobOptions[0];

  const defaultSelectedFunction = jobsGetState.selected_job_function
    ? getIndustryList().find(
        (op) => op.value === jobsGetState.selected_job_function
      )
    : getIndustryList()[0];

  const setQuery = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setJobsState((st: IJobsState) => ({
      ...st,
      page: 0,
      hasMore: true,
      job_list: [],
      searchQuery: event.target.value,
    }));
    return dispatch(
      doGetJobsAction({
        ...jobsGetState,
        jobs: [],
        page: 0,
        hasMore: true,
        searchQuery: event.target.value,
      })
    );
  };

  const handleInputChange = React.useCallback(
    debounce((e) => setQuery(e), 1000),
    [
      jobsGetState.jobStatus,
      jobsGetState.selected_job_function,
      jobsGetState?.period?.start,
      jobsGetState?.period?.end,
      jobsGetState?.company_id,
    ]
  );

  const handleQueryChange = (event: any) => {
    setSearchQuery(event.target.value);
    handleInputChange(event);
  };

  React.useEffect(() => {
    setSearchQuery(jobsGetState.searchQuery || "");
    const start = jobsGetState.period?.start as string;
    const end = jobsGetState.period?.end as string;
    if (start && end) {
      setStartDate(new Date(start));
      setEndDate(new Date(end));
    }
  }, []);

  return (
    <HeaderContainer>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            width: "16px",
            height: "32px",
            margin: "0px 16px 0px 0px",
            background: theme?.custom.colors.green,
            borderRadius: "4px",
          }}
        />
        <Box
          sx={{ width: "200px", display: "inline-flex", marginLeft: "15px" }}
        >
          <SelectComponent
            handleChange={handleFilterByStatus}
            options={JobOptions}
            defaultValue={defaultStatus}
          />
        </Box>
        <Box
          sx={{
            width: "15.125rem",
            display: "inline-flex",
            marginLeft: "10px",
          }}
        >
          <SelectComponent
            options={getIndustryList()}
            defaultValue={defaultSelectedFunction}
            handleChange={handleFilterByJobFunction}
          />
        </Box>
        <Box
          sx={{
            marginLeft: "16px",
            borderRadius: "4px",
          }}
        >
          <Input
            iconStart={<img src={SearchIcon} alt="" />}
            fullWidth
            type="text"
            id="searchQuery"
            register={() => {}}
            sx={{
              mx: "auto",
              width: "360px",
              textAlign: "left",
            }}
            placeholder="Search jobs"
            variant="filled"
            onChange={handleQueryChange}
            value={searchQuery}
          />
        </Box>
      </Box>
      <Box>
        <SelectButton
          sx={{
            background: "#fcfcfc",
          }}
          onClick={handleOpen}
          aria-describedby={button_id}
        >
          {startDate && endDate
            ? `${new Date(startDate).toLocaleDateString()} - ${new Date(
                endDate
              ).toLocaleDateString()}`
            : "Date"}
          <CalendarTodayIconOutlined
            sx={{
              ml: "5px",
              height: "15px",
              width: "15px",
              left: "1.5px",
              top: "1.5px",
              color: " #6f767e",
            }}
          />
        </SelectButton>
      </Box>
      <DateRangePicker
        handleClose={handleClose}
        open={open}
        onChange={onDateRangeChange}
        anchorEl={anchorEl}
        startDate={startDate}
        endDate={endDate}
        clearDates={clearDates}
      />
    </HeaderContainer>
  );
}
