import React, { FC } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { _get } from "utils/lodash";
import Box from "@mui/material/Box";
import { styled } from "@mui/system";

interface IProps {
  text?: string;
  size?: number;
}

const Container = styled(Box)`
  display: flex;
  width: 100%;
  height: 100vh;
  max-height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: ${({ theme }) =>
    _get(theme, "palette.colors.light_dark_grey")};
`;

const TextBox = styled(Box)`
  margin-top: 10px;
  font-size: 15px;
  font-weight: 500;
  font-family: "Inter";
`;

const PageLoader: FC<IProps> = ({ text = "", size = 40 }) => {
  return (
    <Container>
      <CircularProgress size={size} />
      <TextBox>{text}</TextBox>
    </Container>
  );
};

export default PageLoader;
