import { ThemeProvider } from "@mui/material/styles";
import React from "react";
import { Provider as ReduxProvider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "redux/store";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Routes from "routes/routes";
import "./App.scss";
import "./calendar-overwrites.scss";
import theme from "./theme";

const App = () => {
  return (
    <ReduxProvider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <Routes />
          </BrowserRouter>
          <ToastContainer position="top-right" newestOnTop theme="dark" />
        </ThemeProvider>
      </PersistGate>
    </ReduxProvider>
  );
};

export default App;
