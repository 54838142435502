import { AsyncThunk, createAsyncThunk } from "@reduxjs/toolkit";
import { IPayload } from "./interface";
import { db, doc, serverTimestamp, setDoc } from "utils/firebase";
import { IUser } from "types/types";

export const doUpdateUserAction: AsyncThunk<
  Partial<IUser>,
  IPayload,
  {}
> = createAsyncThunk("user/update", async (payload: IPayload) => {
  const recruiterData = { ...payload.user, updated_at: serverTimestamp() };
  const recruiterRef = doc(db, `v2_recruiters/${payload.uid}`);
  await setDoc(recruiterRef, recruiterData, { merge: true });

  return payload.user;
});
