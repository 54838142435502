import React, { useEffect } from "react";
import { Box } from "@mui/system";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Avatar from "@mui/material/Avatar";

import Span from "components/shared/span/span";
import Text from "components/shared/text/text";
import CandidateStatus from "components/job/candidate-status";

import CandidateNext from "assets/images/candidate-next.svg";
import CandidatePrevious from "assets/images/candidate-previous.svg";
import CandidateArrow from "assets/images/candidate-arrow.svg";
import CandidateClose from "assets/images/candidate-close.svg";
import LinkIcon from "assets/images/candidate-link-icon.svg";
import Resume from "assets/images/candidate-resume.svg";
import Briefcase from "assets/images/candidate-case.svg";
import LinkedIn from "assets/images/candidate-linkedin.svg";

import { ICandidate, IConnectedCandidate } from "types/types";
import { formatCandidateDate, getRecruiterNotes } from "utils/helpers";
import SelectedSkill from "./selected-skill";

import {
  Modal,
  Backdrop,
  ModalBody,
  ContentContainer,
  ModalHeader,
  ModalFooter,
  ModalContentBody,
  FlexSpan,
  BackButton,
  TrackBox,
  CandidateIntro,
  CandidateNameBox,
  CandidateLinks,
  CandidateLink,
  LinkIconContainer,
  LinksContainer,
  NotesContainer,
  SkillsBox,
  SkillsContainer,
  Skill,
  ExperienceBox,
  EducationBox,
  IconContainer,
} from "./styles";
import { _first, _get } from "utils/lodash";
import ReactHtmlParser from "react-html-parser";
import draftToHtml from "draftjs-to-html";

interface IProps {
  handleClose: () => void;
  open: boolean;
  candidate: IConnectedCandidate;
  updated_by: any;
  // eslint-disable-next-line no-unused-vars
  switchCandidate: (move: "previous" | "next") => void;
  candidatesList: ICandidate[];
}

const CandidateDetails = ({
  handleClose,
  open,
  candidate,
  updated_by,
  switchCandidate,
  candidatesList,
}: IProps) => {
  function stringAvatar(name: string) {
    return {
      children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
    };
  }

  function onArrowKeysPress(e: KeyboardEvent) {
    const pathname = location.pathname;
    const isCandidateProfileModal =
      open &&
      !pathname.includes("/create-candidate") &&
      !pathname.includes("/edit-candidate");
    if (isCandidateProfileModal) {
      if (e.key == "ArrowLeft") {
        switchCandidate("previous");
      } else if (e.key == "ArrowRight") {
        switchCandidate("next");
      }
    }
  }

  useEffect(() => {
    window.addEventListener("keydown", onArrowKeysPress);
    return () => {
      window.removeEventListener("keydown", onArrowKeysPress);
    };
  });

  const getLinkedInURL = () => {
    let url = candidate?.connected_links?.find(
      (link) => link?.key === "linkedin"
    )?.link;

    if (url) {
      if (url.startsWith("linkedin")) {
        url = "https://www." + url;
      }
      try {
        return new URL(url).href;
      } catch (e) {
        return undefined;
      }
    }
    return undefined;
  };

  const prepareExternalLink = (link: string) => {
    if (!link.includes("://") && !link.startsWith("/")) {
      return "//" + link;
    } else {
      return link;
    }
  };

  const linked_in_url = getLinkedInURL();
  const url_query = new URLSearchParams(window.location.search);
  const extension_param = url_query.get("from_extension");
  const experiences = _first(_get(candidate, "candidate_experiences")) || {};
  const jobTitle = experiences.jobTitle || candidate.jobTitle;
  const companyName = experiences.companyName || candidate.currentCompany;
  const candidateLocation = experiences.location || candidate.location;

  return (
    <Modal
      aria-labelledby="unstyled-modal-title"
      aria-describedby="unstyled-modal-description"
      open={open}
      onClose={handleClose}
      components={{ Backdrop }}
    >
      <ModalBody>
        <Box
          sx={{
            width: "20%",
            paddingTop: "411px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <IconContainer onClick={() => switchCandidate("previous")}>
            <img
              width={48}
              height={48}
              src={CandidatePrevious}
              alt="Previous"
            />
          </IconContainer>
        </Box>
        <ContentContainer>
          <ModalHeader>
            <BackButton onClick={handleClose}>
              <FlexSpan>
                <img src={CandidateArrow} alt="" />
                <TrackBox>
                  <Span
                    fontWeight={500}
                    fontSize="13px"
                    color={"#ffffff"}
                    sx={{
                      lineHeight: "1",
                    }}
                  >
                    {candidatesList?.indexOf(candidate) + 1}/
                    {candidatesList?.length}
                  </Span>
                </TrackBox>
              </FlexSpan>
            </BackButton>
            <BackButton onClick={handleClose} sx={{ right: "-4px" }}>
              <FlexSpan>
                <Span
                  fontWeight={400}
                  fontSize="14px"
                  color={"#dddddd"}
                  sx={{
                    lineHeight: "1",
                    display: "inline-block",
                    marginRight: "10px",
                  }}
                >
                  Close
                </Span>
                <img src={CandidateClose} alt="" />
              </FlexSpan>
            </BackButton>
          </ModalHeader>
          <ModalContentBody>
            {extension_param && !candidate?.candidate_avatar_url ? (
              <div style={{ paddingTop: "68px", paddingBottom: "368px" }}>
                <Text
                  variant="body1"
                  fontSize="20px"
                  fontWeight={600}
                  lineHeight="30px"
                  letterSpacing="-0.02em"
                  sx={{
                    marginBottom: "0",
                    marginLeft: 0,
                  }}
                >
                  Candidate data is being fetched.
                </Text>
                <Text
                  variant="body1"
                  fontSize="20px"
                  fontWeight={600}
                  lineHeight="30px"
                  letterSpacing="-0.02em"
                  sx={{
                    marginBottom: "0",
                    marginLeft: 0,
                  }}
                >
                  Refresh the page in about 60 seconds
                </Text>
                <Text
                  variant="body1"
                  fontSize="20px"
                  fontWeight={600}
                  lineHeight="30px"
                  letterSpacing="-0.02em"
                  sx={{
                    marginBottom: "0",
                    marginLeft: 0,
                  }}
                >
                  <a href={window.location.href}>Refresh</a>
                </Text>
              </div>
            ) : (
              <>
                <CandidateIntro>
                  <CandidateNameBox>
                    <Box>
                      <Avatar
                        sx={{
                          height: "80px",
                          width: "80px",
                          background: "#ddd",
                          marginRight: "24px",
                        }}
                        alt={candidate?.firstName}
                        src={candidate?.candidate_avatar_url}
                        {...stringAvatar(
                          `${candidate?.firstName} ${candidate?.lastName}`
                        )}
                      />
                    </Box>
                    <Box>
                      <Text
                        variant="body1"
                        fontSize="20px"
                        fontWeight={600}
                        lineHeight="20px"
                        letterSpacing="-0.02em"
                        sx={{
                          marginBottom: "10px",
                          marginLeft: 0,
                          textAlign: "left",
                        }}
                      >
                        {candidate?.firstName} {candidate?.lastName}
                      </Text>
                      <Text
                        variant="body1"
                        fontSize="13px"
                        fontWeight={400}
                        lineHeight="13px"
                        letterSpacing="-0.01em"
                        color="#9A9FA5"
                        sx={{
                          marginBottom: "10px",
                          marginLeft: 0,
                          textAlign: "left",
                        }}
                      >
                        {jobTitle || ""}{" "}
                        {(
                          <>
                            {companyName ? " @ " : ""}
                            {companyName}
                          </>
                        ) || ""}
                      </Text>
                      <Text
                        sx={{
                          fontFamily: "Inter",
                          fontSize: "13px",
                          color: "#9A9FA5",
                          fontWeight: 400,
                          lineHeight: "16px",
                          letterSpacing: "-1%",
                          textAlign: "left",
                          marginBottom: "10px",
                          marginLeft: 0,
                        }}
                      >
                        {candidateLocation || ""}
                      </Text>
                      <Text
                        variant="body1"
                        fontSize="13px"
                        fontWeight={500}
                        lineHeight="13px"
                        letterSpacing="-0.01em"
                        color="#9A9FA5"
                        sx={{
                          marginBottom: "10px",
                          marginLeft: 0,
                          textAlign: "left",
                        }}
                      >
                        Added {formatCandidateDate(candidate?.created_at)}
                      </Text>
                    </Box>
                  </CandidateNameBox>
                  <Box>
                    <CandidateStatus
                      modal
                      candidate={candidate}
                      updated_by={updated_by}
                    />
                  </Box>
                </CandidateIntro>
                {!linked_in_url &&
                !candidate?.file &&
                !candidate?.cv_url &&
                !candidate?.portfolio ? (
                  ""
                ) : (
                  <CandidateLinks>
                    <Text
                      variant="body1"
                      fontSize="13px"
                      fontWeight={400}
                      lineHeight="13px"
                      letterSpacing="-0.01em"
                      color="#9A9FA5"
                      sx={{
                        marginBottom: "20px",
                        marginLeft: 0,
                        textAlign: "left",
                      }}
                    >
                      Documents & Links
                    </Text>
                    <LinksContainer>
                      {linked_in_url && (
                        <FlexSpan>
                          <img
                            src={LinkedIn}
                            alt=""
                            style={{ marginRight: "8px" }}
                          />
                          <CandidateLink target="_blank" href={linked_in_url}>
                            LinkedIn
                          </CandidateLink>
                          <LinkIconContainer
                            target="_blank"
                            href={linked_in_url}
                          >
                            <img src={LinkIcon} />
                          </LinkIconContainer>
                        </FlexSpan>
                      )}
                      {(candidate?.file || candidate?.cv_url) && (
                        <FlexSpan
                          sx={{
                            ...(linked_in_url && {
                              marginLeft: "60px",
                            }),
                          }}
                        >
                          <img
                            src={Resume}
                            alt=""
                            style={{ marginRight: "10px" }}
                          />
                          <CandidateLink
                            target="_blank"
                            href={candidate?.file || candidate?.cv_url}
                          >
                            Resume
                          </CandidateLink>
                          <LinkIconContainer
                            target="_blank"
                            href={candidate?.file || candidate?.cv_url}
                          >
                            <img src={LinkIcon} />
                          </LinkIconContainer>
                        </FlexSpan>
                      )}
                      {candidate?.portfolio && (
                        <FlexSpan
                          sx={{
                            ...((linked_in_url ||
                              candidate?.file ||
                              candidate?.cv_url) && {
                              marginLeft: "60px",
                            }),
                          }}
                        >
                          <img
                            src={Briefcase}
                            alt=""
                            style={{ marginRight: "10px" }}
                          />
                          <CandidateLink
                            target="_blank"
                            href={prepareExternalLink(candidate?.portfolio)}
                          >
                            Portfolio
                          </CandidateLink>
                          <LinkIconContainer
                            target="_blank"
                            href={prepareExternalLink(candidate?.portfolio)}
                          >
                            <img src={LinkIcon} />
                          </LinkIconContainer>
                        </FlexSpan>
                      )}
                    </LinksContainer>
                  </CandidateLinks>
                )}
                {candidate?.skills_must_have ||
                candidate?.skills_nice_to_have ? (
                  <NotesContainer>
                    <Text
                      variant="body1"
                      fontSize="12px"
                      fontWeight={700}
                      lineHeight="18px"
                      letterSpacing="-0.01em"
                      color="#414141"
                      sx={{
                        marginBottom: "14px",
                        marginLeft: 0,
                        textAlign: "left",
                      }}
                    >
                      Candidate meets the following must-have criteria:
                    </Text>
                    {candidate?.skills_must_have &&
                    candidate?.skills_must_have?.length > 0
                      ? candidate?.skills_must_have?.map((skill, i) => (
                          <SelectedSkill key={i} skill={skill}></SelectedSkill>
                        ))
                      : "-"}
                    <Box
                      sx={{
                        border: "0.5px solid #FCE998",
                        boxShadow:
                          "46.361px 46.361px 154.537px rgba(25, 75, 56, 0.15)",
                        margin: "14px -20px",
                      }}
                    ></Box>
                    <Text
                      variant="body1"
                      fontSize="12px"
                      fontWeight={700}
                      lineHeight="18px"
                      letterSpacing="-0.01em"
                      color="#414141"
                      sx={{
                        marginBottom: "14px",
                        marginLeft: 0,
                        textAlign: "left",
                      }}
                    >
                      Candidate meets the following nice-to-have criteria:
                    </Text>
                    {candidate?.skills_nice_to_have &&
                    candidate?.skills_nice_to_have?.length > 0
                      ? candidate?.skills_nice_to_have
                        ? candidate?.skills_nice_to_have?.map((skill, i) => (
                            <SelectedSkill
                              key={i}
                              skill={skill}
                            ></SelectedSkill>
                          ))
                        : "-"
                      : "-"}
                    <Box
                      sx={{
                        border: "0.5px solid #FCE998",
                        boxShadow:
                          "46.361px 46.361px 154.537px rgba(25, 75, 56, 0.15)",
                        margin: "14px -20px",
                      }}
                    ></Box>
                    <Text
                      variant="body1"
                      fontSize="12px"
                      fontWeight={700}
                      lineHeight="18px"
                      letterSpacing="-0.01em"
                      color="#414141"
                      sx={{
                        marginBottom: "14px",
                        marginLeft: 0,
                        textAlign: "left",
                      }}
                    >
                      Recruiter's notes:
                    </Text>
                    <div
                      style={{
                        fontSize: "14px",
                        color: "#818181",
                        fontFamily: "Inter",
                      }}
                    >
                      {typeof getRecruiterNotes(
                        candidate?.recruiter_notes,
                        true
                      ) === "string"
                        ? getRecruiterNotes(candidate?.recruiter_notes, true) ||
                          "-"
                        : ReactHtmlParser(
                            draftToHtml(
                              getRecruiterNotes(
                                candidate?.recruiter_notes,
                                true
                              )
                            )
                          ) || "-"}
                    </div>
                  </NotesContainer>
                ) : (
                  <NotesContainer>
                    <Text
                      variant="body1"
                      fontSize="18px"
                      fontWeight={600}
                      lineHeight="18px"
                      letterSpacing="-0.02em"
                      color="#414141"
                      sx={{
                        marginBottom: "30px",
                        marginLeft: 0,
                        textAlign: "left",
                      }}
                    >
                      Notes from the recruiter
                    </Text>
                    <Text
                      variant="body1"
                      fontSize="14px"
                      fontWeight={400}
                      lineHeight="150%"
                      letterSpacing="-0.01em"
                      color="#818181"
                      sx={{
                        marginBottom: "30px",
                        marginLeft: 0,
                        textAlign: "left",
                      }}
                    >
                      {typeof getRecruiterNotes(
                        candidate?.recruiter_notes,
                        true
                      ) === "string"
                        ? getRecruiterNotes(candidate?.recruiter_notes, true) ||
                          "-"
                        : ReactHtmlParser(
                            draftToHtml(
                              getRecruiterNotes(
                                candidate?.recruiter_notes,
                                true
                              )
                            )
                          ) || "-"}
                    </Text>
                    <Text
                      variant="body1"
                      fontSize="12px"
                      fontWeight={700}
                      lineHeight="16px"
                      letterSpacing="-0.01em"
                      color="#414141"
                      sx={{
                        marginBottom: "14px",
                        marginLeft: 0,
                        textAlign: "left",
                      }}
                    >
                      Why is this candidate relevant?
                    </Text>
                    <Text
                      variant="body1"
                      fontSize="14px"
                      fontWeight={400}
                      lineHeight="150%"
                      letterSpacing="-0.01em"
                      color="#818181"
                      sx={{
                        marginBottom: "30px",
                        marginLeft: 0,
                        textAlign: "left",
                      }}
                    >
                      {candidate?.reasonForRelevance || "-"}
                    </Text>
                    <Text
                      variant="body1"
                      fontSize="12px"
                      fontWeight={700}
                      lineHeight="16px"
                      letterSpacing="-0.01em"
                      color="#414141"
                      sx={{
                        marginBottom: "14px",
                        marginLeft: 0,
                        textAlign: "left",
                      }}
                    >
                      Potential concerns?
                    </Text>
                    <Text
                      variant="body1"
                      fontSize="14px"
                      fontWeight={400}
                      lineHeight="150%"
                      letterSpacing="-0.01em"
                      color="#818181"
                      sx={{
                        marginBottom: "0",
                        marginLeft: 0,
                        textAlign: "left",
                      }}
                    >
                      {candidate?.reasonForNotRelevance || "-"}
                    </Text>
                  </NotesContainer>
                )}
                {Array.isArray(candidate?.skills) &&
                  candidate.skills?.length > 0 && (
                    <SkillsBox>
                      <Text
                        variant="body1"
                        fontSize="13px"
                        fontWeight={400}
                        lineHeight="13px"
                        letterSpacing="-0.01em"
                        color="#9A9FA5"
                        sx={{
                          marginBottom: "20px",
                          marginLeft: 0,
                          textAlign: "left",
                        }}
                      >
                        Skills
                      </Text>
                      <SkillsContainer>
                        {Array.isArray(candidate?.skills) &&
                          candidate.skills?.map((skill) => {
                            return (
                              <Skill key={skill?.name}>{skill?.name}</Skill>
                            );
                          })}
                      </SkillsContainer>
                      <Divider
                        sx={{
                          borderColor: "#EFEFEF",
                          borderRadius: "1px",
                          marginBottom: "20px",
                          marginTop: "14px",
                        }}
                      />
                    </SkillsBox>
                  )}
                {Array.isArray(candidate?.candidate_experiences) &&
                  candidate?.candidate_experiences?.length > 0 && (
                    <ExperienceBox>
                      <Text
                        variant="body1"
                        fontSize="13px"
                        fontWeight={400}
                        lineHeight="13px"
                        letterSpacing="-0.01em"
                        color="#9A9FA5"
                        sx={{
                          marginBottom: "20px",
                          marginLeft: 0,
                          textAlign: "left",
                        }}
                      >
                        Experience
                      </Text>
                      {Array.isArray(candidate?.candidate_experiences) &&
                        candidate?.candidate_experiences?.map((experience) => (
                          <Box
                            key={`${experience?.companyName}-${
                              experience?.jobTitle
                            }-${
                              experience?.dateRange
                                ? experience?.dateRange
                                : experience?.date_range
                            }`}
                            sx={{ flexGrow: 1 }}
                          >
                            <Grid container spacing={1}>
                              <Grid item xs={12} md={2}>
                                <Text
                                  variant="body1"
                                  fontSize="13px"
                                  fontWeight={400}
                                  lineHeight="13px"
                                  letterSpacing="-0.01em"
                                  color="#9A9FA5"
                                  sx={{
                                    marginBottom: "5px",
                                    paddingRight: "10px",
                                    marginLeft: 0,
                                    textAlign: "left",
                                  }}
                                >
                                  {experience?.dateRange
                                    ? experience?.dateRange.split(" · ")[0]
                                    : experience?.date_range
                                    ? experience?.date_range.split(" · ")[0]
                                    : ""}
                                </Text>
                                {experience?.dateRange &&
                                  experience?.dateRange.split(" · ")[1] && (
                                    <Text
                                      variant="body1"
                                      fontSize="13px"
                                      fontWeight={400}
                                      lineHeight="13px"
                                      letterSpacing="-0.01em"
                                      color="#9A9FA5"
                                      sx={{
                                        marginBottom: "20px",
                                        paddingRight: "10px",
                                        marginLeft: 0,
                                        textAlign: "left",
                                      }}
                                    >
                                      {experience?.dateRange &&
                                        experience?.dateRange.split(" · ")[1]}
                                    </Text>
                                  )}
                                {experience?.date_range &&
                                  experience?.date_range.split(" · ")[1] && (
                                    <Text
                                      variant="body1"
                                      fontSize="13px"
                                      fontWeight={400}
                                      lineHeight="13px"
                                      letterSpacing="-0.01em"
                                      color="#9A9FA5"
                                      sx={{
                                        marginBottom: "20px",
                                        paddingRight: "10px",
                                        marginLeft: 0,
                                        textAlign: "left",
                                      }}
                                    >
                                      {experience?.date_range &&
                                        experience?.date_range.split(" · ")[1]}
                                    </Text>
                                  )}
                              </Grid>
                              <Grid item xs={12} md={10}>
                                <Text
                                  variant="body1"
                                  fontSize="15px"
                                  fontWeight={700}
                                  lineHeight="15px"
                                  letterSpacing="-0.01em"
                                  color="#1A1D1F"
                                  sx={{
                                    marginBottom: "10px",
                                    marginLeft: 0,
                                    textAlign: "left",
                                  }}
                                >
                                  {experience?.jobTitle
                                    ? experience?.jobTitle
                                    : experience?.title}
                                </Text>
                                <Text
                                  variant="body1"
                                  fontSize="13px"
                                  fontWeight={500}
                                  lineHeight="13px"
                                  letterSpacing="-0.01em"
                                  color="#1A1D1F"
                                  sx={{
                                    marginBottom: "10px",
                                    marginLeft: 0,
                                    textAlign: "left",
                                  }}
                                >
                                  {experience?.companyName
                                    ? experience?.companyName
                                    : experience?.company_name}
                                </Text>
                                <Text
                                  variant="body1"
                                  fontSize="14px"
                                  fontWeight={400}
                                  lineHeight="150%"
                                  letterSpacing="-0.01em"
                                  color="#9A9FA5"
                                  sx={{
                                    marginBottom: "24px",
                                    marginLeft: 0,
                                    textAlign: "left",
                                  }}
                                >
                                  {experience?.description}
                                </Text>
                              </Grid>
                            </Grid>
                          </Box>
                        ))}
                      <Divider
                        sx={{
                          borderColor: "#EFEFEF",
                          borderRadius: "1px",
                          marginBottom: "20px",
                        }}
                      />
                    </ExperienceBox>
                  )}
                {Array.isArray(candidate?.educations) &&
                  candidate?.educations?.length > 0 && (
                    <EducationBox>
                      <Text
                        variant="body1"
                        fontSize="13px"
                        fontWeight={400}
                        lineHeight="13px"
                        letterSpacing="-0.01em"
                        color="#9A9FA5"
                        sx={{
                          marginBottom: "20px",
                          marginLeft: 0,
                          textAlign: "left",
                        }}
                      >
                        Education
                      </Text>
                      {Array.isArray(candidate?.educations) &&
                        candidate?.educations?.map((education) => (
                          <Box
                            key={`${education?.degree_title}-${education?.date_range}`}
                            sx={{ flexGrow: 1 }}
                          >
                            <Grid container spacing={1}>
                              <Grid item xs={12} md={2}>
                                <Text
                                  variant="body1"
                                  fontSize="13px"
                                  fontWeight={400}
                                  lineHeight="13px"
                                  letterSpacing="-0.01em"
                                  color="#9A9FA5"
                                  sx={{
                                    marginBottom: "20px",
                                    paddingRight: "10px",
                                    marginLeft: 0,
                                    textAlign: "left",
                                  }}
                                >
                                  {education?.date_range}
                                </Text>
                              </Grid>
                              <Grid item xs={12} md={10}>
                                <Text
                                  variant="body1"
                                  fontSize="15px"
                                  fontWeight={700}
                                  lineHeight="15px"
                                  letterSpacing="-0.01em"
                                  color="#1A1D1F"
                                  sx={{
                                    marginBottom: "10px",
                                    marginLeft: 0,
                                    textAlign: "left",
                                  }}
                                >
                                  {education?.degree_title}
                                </Text>
                                <Text
                                  variant="body1"
                                  fontSize="13px"
                                  fontWeight={500}
                                  lineHeight="13px"
                                  letterSpacing="-0.01em"
                                  color="#1A1D1F"
                                  sx={{
                                    marginBottom: "24px",
                                    marginLeft: 0,
                                    textAlign: "left",
                                  }}
                                >
                                  {education?.institution_name}
                                </Text>
                              </Grid>
                            </Grid>
                          </Box>
                        ))}
                      <Divider
                        sx={{
                          borderColor: "#EFEFEF",
                          borderRadius: "1px",
                          marginBottom: "20px",
                        }}
                      />
                    </EducationBox>
                  )}
              </>
            )}
          </ModalContentBody>
          <ModalFooter></ModalFooter>
        </ContentContainer>
        <Box
          style={{
            width: "20%",
            paddingTop: "411px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <IconContainer onClick={() => switchCandidate("next")}>
            <img width={48} height={48} src={CandidateNext} alt="Next" />
          </IconContainer>
        </Box>
      </ModalBody>
    </Modal>
  );
};

export default CandidateDetails;
