/* eslint-disable @typescript-eslint/no-explicit-any */
import { IRoleType, IUploadAvatarRes, IUploadData } from "types/types";
import { db, doc, setDoc } from "utils/firebase";
import * as axios from "api/axios";
const axiosInstance = axios.default;

interface IPayload {
  userType: IRoleType;
  avatarFile: any;
  target_id: string;
  target_name: string;
  shouldUpdateCollection: boolean;
}

export const uploadAvatar = ({
  userType,
  avatarFile,
  target_id,
  target_name,
  shouldUpdateCollection = false,
}: IPayload): Promise<IUploadAvatarRes> =>
  new Promise((resolve, reject) => {
    // Max file size is set to 3mb
    if (avatarFile.size >= 1572864) {
      reject({
        result: false,
        code: "file_size_large",
        message: "file size is greater than 1.5mb",
      });
      return;
    }
    if (!avatarFile) {
      reject({
        result: false,
        code: "file_not_exists",
        message: "file not exists",
      });
      return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(avatarFile);
    reader.addEventListener(
      "load",
      async () => {
        const base64image: any = reader.result;

        // Send to firebase and retrieve the url for the thumbnail and main image
        const data: IUploadData = {
          file: base64image,
          target_id,
          target_name,
          sizes: [128],
          userType,
        };

        try {
          const uploadAvatarResp = await axiosInstance.post(
            "/user-upload-image",
            {
              ...data,
            }
          );

          if (shouldUpdateCollection) {
            if (!target_id || !target_name) {
              return {
                message: `Error saving avatar url to ${target_name}`,
              };
            }
            const operationRef = doc(db, target_name, target_id);
            setDoc(
              operationRef,
              {
                original_avatar_url:
                  uploadAvatarResp?.data?.originalFileURL || "",
                thumbnail_avatar_url:
                  uploadAvatarResp?.data?.avatarThumbnailUrl || "",
              },
              { merge: true }
            );
          }

          const resp: IUploadAvatarRes = {
            result: true,
            original_avatar_url: uploadAvatarResp?.data?.originalFileURL || "",
            thumbnail_avatar_url:
              uploadAvatarResp?.data?.avatarThumbnailUrl || "",
          };
          resolve(resp);
        } catch (error) {
          reject({
            result: false,
            code: "upload_failed",
            message: "There is a problem with upload avatar, please try later.",
          });
        }
      },
      false
    );
  });
