import React from "react";
import { Box, styled } from "@mui/system";
import ModalUnstyled from "@mui/base/ModalUnstyled";
import Button from "@mui/material/Button";
import clsx from "clsx";

// eslint-disable-next-line react/display-name
const BackdropUnstyled = React.forwardRef<
  HTMLDivElement,
  { open?: boolean; className: string }
>((props, ref) => {
  const { open, className, ...other } = props;
  return (
    <div
      className={clsx({ "MuiBackdrop-open": open }, className)}
      ref={ref}
      {...other}
    />
  );
});

const Modal = styled(ModalUnstyled)`
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Backdrop = styled(BackdropUnstyled)`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: rgba(17, 19, 21, 0.6);
  -webkit-tap-highlight-color: transparent;
  backdrop-filter: blur(30px);
`;

const ModalBody = styled(Box)`
  width: 100%;
  height: 100%;
  background-color: transparent;
  display: flex;
  justify-content: center;
  overflow-y: auto;
`;

const ContentContainer = styled(Box)`
  width: 100%;
  margin-bottom: 50px;
  max-width: 1000px;
`;

const ModalHeader = styled(Box)`
  margin-bottom: 40px;
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ModalFooter = styled(Box)`
  height: 50px;
`;

const ModalContentBody = styled(Box)`
  background-color: #fff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 32px;
`;

const FlexSpan = styled("span")`
  display: inline-flex;
  align-items: center;
`;

const BackButton = styled(Button)`
  font-family: "Inter";
  font-weight: 400;
  font-size: 14px;
  letter-spacing: -0.01em;
  color: ${({ theme }) => theme.palette.grey[400]};
  text-transform: none;
`;

const TrackBox = styled("span")`
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  padding: 8px;
  margin-left: 13.5px;
  display: flex;
  align-items: center;
`;

const CandidateIntro = styled(Box)`
  display: flex;
  justify-content: space-between;
`;

const CandidateNameBox = styled(Box)`
  display: flex;
`;

const CandidateLinks = styled(Box)`
  margin-top: 50px;
`;

const CandidateLink = styled("a")`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 13px;
  letter-spacing: -0.01em;
  text-decoration-line: underline;
  color: #6f767e;
`;

const LinkIconContainer = styled("a")`
  margin-left: 3.5px;
`;

const LinksContainer = styled(Box)`
  display: flex;
`;

const NotesContainer = styled(Box)`
  margin-top: 50px;
  background: #fefbea;
  padding: 20px;
  border: 2px solid #fce998;
  border-radius: 8px;
`;

const SkillsBox = styled(Box)`
  margin-top: 50px;
`;

const SkillsContainer = styled(Box)`
  display: flex;
  flex-wrap: wrap;
`;

const Skill = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 20px;
  gap: 8px;
  background: #efefef;
  border: 2px solid #efefef;
  border-radius: 20px 4px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: -0.01em;
  color: #1a1d1f;
  margin-right: 10px;
  margin-bottom: 10px;
`;

const ExperienceBox = styled(Box)`
  margin-top: 30px;
`;

const EducationBox = styled(Box)`
  margin-top: 30px;
`;

const IconContainer = styled(Box)`
  cursor: pointer;
  height: 30px;
`;

export {
  Modal,
  Backdrop,
  ModalBody,
  ContentContainer,
  ModalHeader,
  ModalFooter,
  ModalContentBody,
  FlexSpan,
  BackButton,
  TrackBox,
  CandidateIntro,
  CandidateNameBox,
  CandidateLinks,
  CandidateLink,
  LinkIconContainer,
  LinksContainer,
  NotesContainer,
  SkillsBox,
  SkillsContainer,
  Skill,
  ExperienceBox,
  EducationBox,
  IconContainer,
};
