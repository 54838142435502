/* eslint-disable no-unused-vars */
import * as React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import * as ROUTES from "./route-items";
import { isMobile } from "react-device-detect";

// eslint-disable-next-line no-unused-vars
const DesktopRoute = () => {
  let location = useLocation();

  if (isMobile) {
    return (
      <Navigate
        to={ROUTES.ROUTE_MOBILE_NOTICE}
        state={{ from: location }}
        replace
      />
    );
  }

  return <Outlet />;
};

export default DesktopRoute;
