import * as React from "react";
import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  styled,
  TextareaAutosize,
  TextField,
  Grid,
} from "@mui/material";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import ShortlistButton from "./shortlist-button";
import RejectButton from "./reject-button";
import { reasonsForShortlisting } from "./data";
import {
  ICandidateInterview,
  IConnectedCandidate,
  IInterviewStatus,
  TCandidateUpdate,
} from "types/types";
import { useAppDispatch } from "redux/store";
import Dialog from "../dialog";
import { Asterisk } from "../shared/asterisk";
import { debounce } from "lodash";
import { _get } from "utils/lodash";
import Pen from "assets/images/pen_dark.svg";
import Undo from "assets/images/undo.svg";
import SharedButton from "components/shared/button/button";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ROUTE_ACTIVE_JOBS } from "routes/route-items";
import { doUpdateCandidateInterviewAction } from "redux/services/candidates/update-interview-status/update.actions";
import { toast } from "react-toastify";
import ToastMessage from "../toast-message";

interface IProps {
  candidate: IConnectedCandidate;
  updated_by: TCandidateUpdate;
  modal?: boolean;
}

const Reasons = styled("div")`
  display: flex;
  align-items: flex-start;
  color: #4d9b32;

  & > span {
    padding-right: 30px;
  }

  &.reject {
    color: #f74f44;
  }
`;

const TextArea = styled(TextareaAutosize)`
  width: 100%;
  margin-top: 25px;
  font-family: Inter;
  font-style: normal;
  padding: 12px;
  resize: none;
  border-radius: 12px;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: -0.01em;
  background-color: ${({ theme }) => theme.palette.grey[900]};
  &:focus {
    color: ${({ theme }) => theme.palette.text.secondary};
    background-color: ${({ theme }) => theme.palette.primary.contrastText};
  }
  &:hover {
    background-color: ${({ theme }) => theme.palette.primary.contrastText};
    color: ${({ theme }) => theme.palette.text.secondary};
  }
`;

const CandidateStatus = ({ candidate, updated_by, modal }: IProps) => {
  const location = useLocation();
  const [reason, setReason] = React.useState("");
  const [otherReason, setOtherReason] = React.useState("");
  const [option, setOption] = React.useState(IInterviewStatus.PENDING);
  const [openDialog, setOpenDialog] = React.useState(
    false ||
      (location.pathname.includes("/reject-candidate/") &&
        location.pathname.includes(candidate.candidate_id))
  );
  const [openShorlistDialog, setOpenShortlistDialog] = React.useState(
    false ||
      (location.pathname.includes("/shortlist-candidate/") &&
        location.pathname.includes(candidate.candidate_id))
  );
  const [openShortlistFeedback, setOpenShortlistFeedback] =
    React.useState(false);
  const [openUndoFeedback, setOpenUndoFeedback] = React.useState(false);
  const [error, setError] = React.useState("");

  const dispatch = useAppDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const candidateURL = `/${ROUTE_ACTIVE_JOBS}/${id}/candidate/${candidate.candidate_id}`;
  const jobURL = `/${ROUTE_ACTIVE_JOBS}/${id}`;

  React.useLayoutEffect(() => {
    setError("");
  }, [location]);

  const handleOpenDialog = () => {
    navigate(`${jobURL}/reject-candidate/${candidate.candidate_id}`, {
      state: {
        from: location.pathname,
      },
    });
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    navigate(`${jobURL}`);
    setOpenDialog(false);
  };

  const handleShortlistOpenDialog = () => {
    navigate(`${jobURL}/shortlist-candidate/${candidate.candidate_id}`, {
      state: {
        from: location.pathname,
      },
    });
    setOpenShortlistDialog(true);
  };

  const handleShortlistCloseDialog = () => {
    navigate(`${jobURL}`);
    setOpenShortlistDialog(false);
  };

  const updateCandidate = async (props: {
    updated_candidate: ICandidateInterview;
  }) => {
    const response = await dispatch(
      doUpdateCandidateInterviewAction({
        job_id: candidate.job_id,
        candidate_id: candidate.candidate_id,
        updated_candidate: props.updated_candidate,
      })
    );

    return response.meta.requestStatus;
  };

  const delayedCandidateUpdate = React.useCallback(
    debounce((candidate_update) => updateCandidate(candidate_update), 1000),
    []
  );

  const handleAcceptDialog = async () => {
    if (!otherReason.trim()) {
      setError("Enter a valid rejection reason.");
      return;
    }

    const updated_candidate: ICandidateInterview = {
      interview_status: IInterviewStatus.REJECTED,
      reason: otherReason,
      updated_by,
    };
    const requestStatus = await updateCandidate({ updated_candidate });

    if (requestStatus === "rejected") {
      handleCloseDialog();
      return toast(
        <ToastMessage
          title="Candidate Status"
          body="Unable to reject candidate"
        />,
        {
          type: "error",
          position: "top-right",
        }
      );
    }

    setOption(IInterviewStatus.REJECTED);
    handleCloseDialog();
    setOpenShortlistFeedback(true);
  };

  const onChange = (event: any) => {
    const reason: string = event.target.value;
    setOtherReason(reason);
    const updated_candidate = {
      ...candidate,
      candidate_status: IInterviewStatus.SHORTLISTED,
      reason,
    };
    delayedCandidateUpdate({ updated_candidate });
  };

  const onShortlistModalChange = (event: any) => {
    const reason: string = event.target.value;
    if (reasonsForShortlisting[3] === reason) {
      setOtherReason("");
    } else {
      setOtherReason(reason);
    }
  };

  const handleModalShortlistedReasonChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const reason = (event.target as HTMLInputElement).value;
    setOtherReason("");
    setReason(reason);
  };

  const submitShortlistModalData = async () => {
    const updated_candidate: ICandidateInterview = {
      interview_status: IInterviewStatus.SHORTLISTED,
      reason: reason || otherReason,
      updated_by,
    };
    const requestStatus = await updateCandidate({ updated_candidate });

    if (requestStatus === "rejected") {
      handleShortlistCloseDialog();
      return toast(
        <ToastMessage
          title="Candidate Status"
          body="Unable to shortlist candidate"
        />,
        {
          type: "error",
          position: "top-right",
        }
      );
    }

    setOption(IInterviewStatus.SHORTLISTED);
    handleShortlistCloseDialog();
    setOpenShortlistFeedback(true);
  };

  const resetInterviewStatus = async () => {
    const updated_candidate: ICandidateInterview = {
      interview_status: IInterviewStatus.PENDING,
      reason: "",
      updated_by,
    };
    const requestStatus = await updateCandidate({ updated_candidate });

    if (requestStatus === "rejected") {
      setOpenUndoFeedback(false);
      return toast(
        <ToastMessage
          title="Candidate Status"
          body="Unable to undo decision"
        />,
        {
          type: "error",
          position: "top-right",
        }
      );
    }

    setOption(IInterviewStatus.PENDING);
    setOpenUndoFeedback(false);
  };

  const handleShortlistedReasonChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const reason = (event.target as HTMLInputElement).value;
    setReason(reason);
    const updated_candidate: ICandidateInterview = {
      interview_status: IInterviewStatus.SHORTLISTED,
      reason,
      updated_by,
    };

    if (reasonsForShortlisting[3] === reason) {
      return;
    }

    const requestStatus = await updateCandidate({ updated_candidate });

    if (requestStatus === "rejected") {
      return toast(
        <ToastMessage
          title="Candidate Status"
          body="Unable to shortlist candidate"
        />,
        {
          type: "error",
          position: "top-right",
        }
      );
    }
  };

  const handleRejectCandidate = () => {
    handleOpenDialog();
  };

  React.useEffect(() => {
    const reason = reasonsForShortlisting.includes(candidate?.reason as string)
      ? (candidate?.reason as string)
      : reasonsForShortlisting[3];
    setReason(reason);
    const otherReasonShortListing = reasonsForShortlisting.includes(
      candidate?.reason as string
    )
      ? ""
      : (candidate?.reason as string);
    setOtherReason(otherReasonShortListing);
    setOption(candidate.interview_status);
  }, [candidate]);

  const renderRejected = (hide_revert?: boolean) => {
    return (
      <Grid item>
        <Reasons
          className="reject"
          sx={{
            marginTop: !modal ? "10px" : 0,
            marginBottom: "5px",
            fontSize: "15px",
            marginLeft: "4px",
          }}
        >
          <span>Main reason for Rejecting</span>{" "}
          <ThumbDownIcon sx={{ height: 20, width: 20 }} />
        </Reasons>
        <Box
          sx={{
            fontFamily: "Inter",
            fontStyle: " normal",
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "150%",
            letterSpacing: "-0.01em",
            color: "#9a9fa5",
            marginLeft: "4px",
          }}
        >
          {otherReason}
        </Box>
        {!hide_revert && (
          <SharedButton
            onClick={() => setOpenUndoFeedback(true)}
            sx={{
              background: "white",
              width: "200px",
              marginTop: "15px",
              border: (theme) => `1px solid ${_get(theme, "palette.grey.300")}`,
              color: (theme) => _get(theme, "palette.text.secondary"),
              height: "42px",
              "&:hover": {
                background: (theme) => _get(theme, "palette.colors.light_grey"),
                border: (theme) =>
                  `1px solid ${_get(theme, "palette.colors.light_grey")}`,
              },
              lineHeight: 1,
              fontWeight: 400,
            }}
            variant="contained"
          >
            <img src={Undo} alt="Change Decision" />
            <Box sx={{ marginLeft: "8px", textTransform: "initial" }}>
              Change Decision
            </Box>
          </SharedButton>
        )}
      </Grid>
    );
  };

  const renderShortlisted = (hide_revert?: boolean) => {
    return (
      <Grid item>
        <Reasons
          sx={{
            marginTop: !modal ? "10px" : 0,
            marginBottom: "5px",
            fontSize: "15px",
            marginLeft: "4px",
          }}
        >
          <span>Main reason for Shortlisting</span>{" "}
          <ThumbUpIcon sx={{ height: 20, width: 20 }} />
        </Reasons>
        <FormControl
          sx={{
            marginLeft: "4px",
          }}
        >
          <RadioGroup
            aria-labelledby="shortlisted-reasons-radio-buttons-group-label"
            name="radio-buttons-group"
            value={reason}
            onChange={handleShortlistedReasonChange}
          >
            {reasonsForShortlisting.map((res, index) => (
              <FormControlLabel
                key={index}
                value={res}
                control={
                  <Radio
                    sx={{
                      color: "#6f767e",
                      "&.Mui-checked": {
                        color: "#3785f9",
                      },
                    }}
                  />
                }
                label={
                  res === reasonsForShortlisting[3] ? (
                    <TextField
                      placeholder="or type here"
                      variant="standard"
                      onFocus={() => setReason(reasonsForShortlisting[3])}
                      onChange={onChange}
                      value={otherReason}
                      sx={{
                        "& .MuiInput-root": {
                          borderBottom: "1px solid #cccccc",
                        },
                      }}
                    />
                  ) : (
                    res
                  )
                }
                sx={{
                  fontFamily: "Inter",
                  fontStyle: " normal",
                  fontWeight: 400,
                  fontSize: "14px",
                  lineHeight: "150%",
                  letterSpacing: "-0.01em",
                  color: "#9a9fa5",
                }}
              />
            ))}
          </RadioGroup>
        </FormControl>
        {!hide_revert && (
          <SharedButton
            onClick={() => setOpenUndoFeedback(true)}
            sx={{
              width: "200px",
              marginTop: "15px",
              background: "white",
              border: (theme) => `1px solid ${_get(theme, "palette.grey.300")}`,
              color: (theme) => _get(theme, "palette.text.secondary"),
              height: "42px",
              "&:hover": {
                background: (theme) => _get(theme, "palette.colors.light_grey"),
                border: (theme) =>
                  `1px solid ${_get(theme, "palette.colors.light_grey")}`,
              },
              lineHeight: 1,
              fontWeight: 400,
            }}
            variant="contained"
          >
            <img src={Undo} alt="Change Decision" />
            <Box sx={{ marginLeft: "8px", textTransform: "initial" }}>
              Change Decision
            </Box>
          </SharedButton>
        )}
      </Grid>
    );
  };

  const renderPending = () => {
    return (
      <>
        <ShortlistButton
          onClick={handleShortlistOpenDialog}
          sx={{ marginRight: modal ? "10px" : 0 }}
        />
        <RejectButton
          onClick={handleRejectCandidate}
          sx={{
            marginTop: !modal ? "10px" : 0,
            marginRight: modal ? "10px" : 0,
          }}
        />
      </>
    );
  };

  const navigateToEditCandidate = () => {
    navigate(`${candidateURL}/edit-candidate`, {
      state: {
        from: location.pathname,
      },
    });
  };

  return (
    <>
      <Grid
        container
        direction="row"
        spacing={3}
        sx={{ flexDirection: !modal ? "column" : "row", marginTop: 0 }}
      >
        {option === IInterviewStatus.SHORTLISTED
          ? renderShortlisted()
          : option === IInterviewStatus.REJECTED
          ? renderRejected()
          : option === IInterviewStatus.ARCHIVED
          ? ""
          : renderPending()}
        <Dialog
          title={
            <>
              Why did you reject this candidate?
              <Asterisk />
            </>
          }
          submitButtonText="Submit"
          canCloseDialog={true}
          openDialog={openDialog}
          isLoading={false}
          handleCloseDialog={handleCloseDialog}
          handleAcceptDialog={handleAcceptDialog}
          errorMessage={error}
          body="Your detailed feedback would make future candidates more relevant."
          body_2={
            <TextArea
              aria-label="reason for rejection"
              minRows={6}
              placeholder="Type here..."
              style={{
                marginTop: 25,
              }}
              onChange={(e) => setOtherReason(e.target.value)}
            />
          }
        />
        <Dialog
          title={<>Confirm your action</>}
          submitButtonText="Yes"
          canCloseDialog={true}
          openDialog={openUndoFeedback}
          isLoading={false}
          handleCloseDialog={() => setOpenUndoFeedback(false)}
          handleAcceptDialog={resetInterviewStatus}
          errorMessage={error}
          body={
            <>
              Are you sure you want to undo{" "}
              {option === IInterviewStatus.SHORTLISTED
                ? "shortlisting"
                : "rejecting"}{" "}
              <b>
                {candidate.firstName} {candidate.lastName}?
              </b>
            </>
          }
        />
        <Dialog
          title={
            <>
              Your feedback for {candidate.firstName} {candidate.lastName} has
              been entered as follows:
            </>
          }
          submitButtonText="Okay"
          canCloseDialog={false}
          openDialog={openShortlistFeedback}
          isLoading={false}
          handleCloseDialog={() => setOpenShortlistFeedback(false)}
          handleAcceptDialog={() => setOpenShortlistFeedback(false)}
          body={
            <>
              {option == IInterviewStatus.PENDING && <span>loading...</span>}
              {option == IInterviewStatus.SHORTLISTED &&
                renderShortlisted(true)}
              {option == IInterviewStatus.REJECTED && renderRejected(true)}
            </>
          }
        />
        <Dialog
          title={<>Why did you shortlist this candidate?</>}
          submitButtonText="Submit"
          canCloseDialog={true}
          openDialog={openShorlistDialog}
          isLoading={false}
          handleCloseDialog={handleShortlistCloseDialog}
          handleAcceptDialog={submitShortlistModalData}
          errorMessage={error}
          body={
            <Grid item>
              <Reasons
                sx={{
                  marginTop: !modal ? "10px" : 0,
                  marginBottom: "5px",
                  fontSize: "15px",
                  marginLeft: "4px",
                }}
              >
                <span>Main reason for Shortlisting</span>{" "}
                <ThumbUpIcon sx={{ height: 20, width: 20 }} />
              </Reasons>
              <FormControl
                sx={{
                  marginLeft: "4px",
                }}
              >
                <RadioGroup
                  aria-labelledby="shortlisted-reasons-radio-buttons-group-label"
                  name="radio-buttons-group"
                  value={reason}
                  onChange={handleModalShortlistedReasonChange}
                >
                  {reasonsForShortlisting.map((res, index) => (
                    <FormControlLabel
                      key={index}
                      value={res}
                      control={
                        <Radio
                          sx={{
                            color: "#6f767e",
                            "&.Mui-checked": {
                              color: "#3785f9",
                            },
                          }}
                        />
                      }
                      label={
                        res === reasonsForShortlisting[3] ? (
                          <TextField
                            placeholder="or type here"
                            variant="standard"
                            onFocus={() => setReason(reasonsForShortlisting[3])}
                            onChange={onShortlistModalChange}
                            value={otherReason}
                            sx={{
                              "& .MuiInput-root": {
                                borderBottom: "1px solid #cccccc",
                              },
                            }}
                          />
                        ) : (
                          res
                        )
                      }
                      sx={{
                        fontFamily: "Inter",
                        fontStyle: " normal",
                        fontWeight: 400,
                        fontSize: "14px",
                        lineHeight: "150%",
                        letterSpacing: "-0.01em",
                        color: "#9a9fa5",
                      }}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </Grid>
          }
        />
        {modal && (
          <SharedButton
            onClick={navigateToEditCandidate}
            sx={{
              width: "140px",
              background: "white",
              border: (theme) => `1px solid ${_get(theme, "palette.grey.300")}`,
              color: (theme) => _get(theme, "palette.text.secondary"),
              height: "42px",
              marginLeft: modal ? "10px" : "0",
              "&:hover": {
                background: (theme) => _get(theme, "palette.colors.light_grey"),
                border: (theme) =>
                  `1px solid ${_get(theme, "palette.colors.light_grey")}`,
              },
            }}
            variant="contained"
          >
            <img src={Pen} alt="Edit" />
            <Box sx={{ marginLeft: "8px", textTransform: "initial" }}>Edit</Box>
          </SharedButton>
        )}
      </Grid>
    </>
  );
};

export default CandidateStatus;
