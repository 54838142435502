import { AnyAction, createSlice } from "@reduxjs/toolkit";
import { IUserState } from "./interface";
import { doActionFetchUserAction } from "./user.actions";
import { IRoleType, IUser, ReduxStatus } from "types/types";

const initialUser: IUser = {
  firstName: "",
  lastName: "",
  email: "",
  created_at: null,
  uid: "",
  role: IRoleType.RECRUITER,
  company_name: "",
  company_id: "",
  original_avatar_url: "",
  thumbnail_avatar_url: "",
  accepted_terms: false,
  next_onboarding_stage: "your-company",
  is_active: false,
  authed_user_id: "",
  slack_access_token: "",
  slack_app_id: "",
  slack_user_access_token: "",
  integrations: {
    Slack: {
      selected: false,
    },
    ATS: {
      selected: false,
    },
  },
  notifications: {
    email: {},
    slack: {},
  },
};

const initialState: IUserState = {
  status: ReduxStatus.empty,
  response: initialUser,
  error: {},
};

export const user = createSlice({
  name: "users/fetch",
  initialState,
  reducers: {
    resetUsers: () => initialState,
    signOut: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(doActionFetchUserAction.pending, (state: IUserState) => {
        state.status = ReduxStatus.pending;
      })
      .addCase(
        doActionFetchUserAction.fulfilled,
        (state: IUserState, action: AnyAction) => {
          state.status = ReduxStatus.fulfilled;
          state.error = {};
          state.response = action.payload;
        }
      )
      .addCase(
        doActionFetchUserAction.rejected,
        (state: IUserState, action: AnyAction) => {
          state.status = ReduxStatus.rejected;
          state.response = initialUser;
          state.error = action.error;
        }
      );
  },
});

export const { resetUsers } = user.actions;
export default user.reducer;
