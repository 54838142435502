/* eslint-disable prettier/prettier */
import { SerializedError } from "@reduxjs/toolkit";
import { lightFormat } from "date-fns";
import { _first } from "utils/lodash";

export const encodeBase64 = (str: string) => {
  return window.btoa(str);
};

export const decodeBase64 = (str: string) => {
  return window.atob(str);
};

export const scrollSectionIntoView = (hashId: string): void => {
  if (hashId) {
    const element = document.querySelector(hashId);

    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }
  }
};

export const formatCandidateDate = (timestamp: number) => {
  if (!timestamp) return "-";
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const newDate = new Date(timestamp);
  const day = newDate.getDate();
  const month = monthNames[newDate.getMonth()];
  const year = newDate.getFullYear();
  const time = lightFormat(newDate, "HH:mm");
  return `${day} ${month} ${year} ${time}`;
};

export const getRecruiterNotes = (
  notes: string | undefined,
  block?: boolean
) => {
  if (!notes) return;
  try {
    const parsedNotes = JSON.parse(notes);
    return block ? parsedNotes : _first(parsedNotes.blocks).text;
  } catch (e) {
    return notes;
  }
};

export const customError = (options: SerializedError) => options;
