import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ICreateJobPayload, ICreateJobState } from "./interface";
import { doCreateJobAction } from "./create.actions";
import { ReduxStatus } from "types/types";

const initialResponse: ICreateJobPayload = {
  job: {},
};

const initialState: ICreateJobState = {
  status: ReduxStatus.empty,
  response: initialResponse,
  error: {},
};

export const createCompany = createSlice({
  name: "job/create",
  initialState,
  reducers: {
    resetJobs: () => {
      // state.candidateList = [1, 2];
      // ✅ CORRECT: logs a plain JS copy of the current data
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(doCreateJobAction.pending, (state: ICreateJobState) => {
        state.status = ReduxStatus.pending;
      })
      .addCase(
        doCreateJobAction.fulfilled,
        (state: ICreateJobState, action: PayloadAction<ICreateJobPayload>) => {
          state.status = ReduxStatus.fulfilled;
          state.error = {};
          state.response = action.payload;
        }
      )
      .addCase(
        doCreateJobAction.rejected,
        (state: ICreateJobState, action: PayloadAction<unknown>) => {
          state.status = ReduxStatus.rejected;
          state.response = initialResponse;
          state.error = action.payload;
        }
      );
  },
});

export const { resetJobs } = createCompany.actions;
export default createCompany.reducer;
