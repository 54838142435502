/* eslint-disable no-unused-vars */
import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { styled } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";
import SharedButton from "../shared/button/button";
import SharedInput from "../shared/input/input";
import CloseIcon from "assets/images/close-light.svg";

const DialogContainer = styled(Dialog)`
  & .MuiDialog-paperScrollPaper {
    min-width: 30rem;
    max-width: 30rem;
    background-color: ${({ theme }) => theme.palette.grey[50]};
  }
  & .MuiDialogTitle-root {
    color: ${({ theme }) => theme.palette.primary.main};
    font-weight: 600;
    font-size: 20px;
    font-family: Inter;
    line-height: 32px;
    letter-spacing: -0.02em;
    display: flex;
    justify-content: space-between;
  }
  & .MuiDialogContentText-root {
    color: ${({ theme }) => theme.palette.primary.main};
    font-weight: 400;
    font-size: 16px;
    font-family: Inter;
  }
  & .MuiLoadingButton-text {
    color: ${({ theme }) => theme.palette.primary.main};
    font-weight: 400;
    font-size: 16px;
    font-family: Inter;
  }
`;

const CounterImage = styled("div")`
  background: #efefef;
  border-radius: 50px;
  height: 36px;
  width: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

interface IProps {
  isLoading: boolean;
  openDialog: boolean;
  invalidCandidatesNeeded: boolean;
  candidatesNeeded: number;
  handleInputChange: (e: any) => void;
  handleAcceptDialog: (e: React.MouseEvent<HTMLButtonElement>) => void;
  handleCloseDialog: (e: React.MouseEvent<HTMLDivElement>) => void;
}

const CandidatesNeededDialog: React.FC<IProps> = ({
  isLoading = false,
  openDialog = false,
  invalidCandidatesNeeded = false,
  candidatesNeeded,
  handleInputChange,
  handleAcceptDialog,
  handleCloseDialog,
}) => {
  const handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      handleAcceptDialog(e);
    }
  };

  return (
    <DialogContainer
      open={openDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Total Shortlisted Candidates Needed
        <CounterImage onClick={handleCloseDialog}>
          <img src={CloseIcon} alt="close icon" />
        </CounterImage>
      </DialogTitle>
      <DialogContent>
        <SharedInput
          sx={{
            position: "relative",
          }}
          text_align="center"
          placeholder="Candidates needed"
          variant="filled"
          id="candidatesNeeded"
          register={() => {}}
          type="number"
          errors={
            invalidCandidatesNeeded ? "Provide a valid number" : undefined
          }
          errorSx={{
            top: "20px",
          }}
          value={candidatesNeeded}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
        />
      </DialogContent>
      <DialogActions
        sx={{
          mx: "15px",
          marginBottom: "10px",
        }}
      >
        <SharedButton variant="contained" onClick={handleAcceptDialog}>
          {isLoading ? (
            <CircularProgress
              sx={{ marginRight: "10px" }}
              color="inherit"
              size="20px"
            />
          ) : (
            "Save"
          )}
        </SharedButton>
      </DialogActions>
    </DialogContainer>
  );
};

export default CandidatesNeededDialog;
