export const reasonsForShortlisting = [
  "Relevant experiences",
  "High potential",
  "Culture add",
  "or type here",
];

export const reasonsForRejection = [
  "Underqualified",
  "Overqualified",
  "Location",
  "Started a job recently",
  "or type here",
];
