import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import { _get } from "utils/lodash";

const SimpleCardArea = styled(Box)`
  border: 3px solid ${({ theme }) => theme.palette.grey[800]};
  cursor: pointer;
  display: flex;
  padding: 20px;
  transition: all 0.2s ease;
  border-radius: 12px;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.palette.grey[50]};
  position: relative;
  &:focus,
  &:hover {
    border: 3px solid ${({ theme }) => _get(theme, "palette.colors.light_grey")};
  }
  &.grey {
    background-color: ${({ theme }) => theme.palette.grey[800]};
    justify-content: center;
  }
`;

export default SimpleCardArea;
