import { AsyncThunk, createAsyncThunk } from "@reduxjs/toolkit";
import { ICreateCandidatePayload } from "./interface";
import { collection, db, doc, serverTimestamp, setDoc } from "utils/firebase";
import { IDBVersion } from "types/types";

export const doCreateCandidateAction: AsyncThunk<
  ICreateCandidatePayload,
  ICreateCandidatePayload,
  {}
> = createAsyncThunk("candidate/create", async (payload) => {
  const candidatesRef = collection(db, `v2_candidates`);
  const candidate_id = doc(candidatesRef).id;

  const candidateRef = doc(db, `v2_candidates/${candidate_id}`);
  const candidate = {
    ...payload.candidate,
    candidate_id,
    created_at: serverTimestamp(),
    source: "connect-app",
    IDBVersion: IDBVersion.V2_CANDIDATES,
  };
  await setDoc(candidateRef, candidate, { merge: true });

  const newPayload: ICreateCandidatePayload = {
    ...payload,
    candidate: {
      ...candidate,
      created_at: new Date().toISOString(),
    },
  };

  return newPayload;
});
