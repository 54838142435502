import * as React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { ROUTE_ACTIVE_JOBS } from "./route-items";

interface IProps {
  user: any;
}

const PublicRoute: React.FC<IProps> = ({ user }) => {
  let location = useLocation();

  if (!user?.uid) {
    return <Outlet />;
  }

  return <Navigate to={ROUTE_ACTIVE_JOBS} state={{ from: location }} replace />;
};

export default PublicRoute;
