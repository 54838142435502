/* eslint-disable no-unused-vars */
import React, { FC, useState, useRef } from "react";
import Box from "@mui/material/Box";
import Grow from "@mui/material/Grow";
import Fade from "@mui/material/Fade";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled, useTheme } from "@mui/material/styles";
import { ITheme } from "theme";
import { useNavigate } from "react-router-dom";
import { config } from "config";
import { useOnClickOutside } from "../../hooks/use-on-click-outside";
import Span from "components/shared/span/span";
import Text from "components/shared/text/text";
import ForwardArrow from "assets/images/forward-arrow.svg";
import JobTitle from "./job-title";
import JobCard from "./job-card";
import Ellipsis from "assets/images/ellipsis.svg";
import LightTicks from "assets/images/light-ticks.svg";
import Building from "assets/images/custom-building-icon.svg";
import Edit from "assets/images/edit-pen.svg";
import { IJobStatus, IJob } from "types/types";
import { ROUTE_ACTIVE_JOBS, ROUTE_EDIT_JOB } from "routes/route-items";
import { doCreateJobAction } from "redux/services/job/create/create.actions";
import FolderAdd from "assets/images/folder-add-light.svg";
import Folder from "assets/images/folder-light.svg";
import CopyIcon from "assets/images/copy-light.svg";
import CopyFilledIcon from "assets/images/copy-filled.svg";
import CopyToClipboard from "react-copy-to-clipboard";
import ArchiveRed from "assets/images/archive_red.svg";
import ArchiveGreen from "assets/images/unarchive.svg";
import { auth, db, doc, getDoc } from "utils/firebase";
import { collection_name } from "utils/firebase-refs";
import { _get } from "utils/lodash";
import { useSelector } from "react-redux";
import { useAppDispatch, RootState } from "redux/store";
import IconLinkedATS from "assets/images/linked-ats.svg";

interface IProps {
  id?: string;
  title: string;
  jobCategory: string;
  company: string;
  createdAt: string;
  jobStatus?: IJobStatus;
  jobData: IJob;
  uid: string;
  review?: number;
  handleUpdateJobStatus: (a: { job: IJob }) => void;
  handleUpdateMyJob: (a: { job: IJob }) => void;
}

const EllipsisIcon = styled("img")`
  cursor: pointer;
  padding: 8px;
  border-radius: 50%;
  transition: all 0.2s ease-in-out;
  &:hover {
    background-color: ${({ theme }) => theme.palette.grey[200]};
  }
`;

const EditJobContainer = styled(Box)`
  position: absolute;
  display: none;
  width: 102%;
  height: 102%;
  top: -2px;
  left: -3px;
  right: -2px;
  bottom: -2px;
  background-color: rgba(17, 19, 21, 0.8);
  z-index: 2;
  color: #f1f1f1;
  border-radius: 12px;
  justify-content: center;
  align-items: center;
  gap: 5px;
  transition: all 0.3s ease;
  &.show {
    display: flex;
  }
`;

const EditTextContainer = styled(Box)`
  display: inline-flex;
  background: #fcfcfc;
  padding: 8px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 36px;
  gap: 4px;
  &.archive {
    padding: 8px;
    background: linear-gradient(
        0deg,
        rgba(247, 79, 68, 0.15),
        rgba(247, 79, 68, 0.15)
      ),
      #ffffff;
  }
  &.unarchive {
    padding: 8px;
    background: linear-gradient(
        0deg,
        rgba(131, 191, 110, 0.15),
        rgba(131, 191, 110, 0.15)
      ),
      #ffffff;
  }
`;

const TheTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "black",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "black",
  },
}));

const CardWithJob: FC<IProps> = ({
  id,
  title = "",
  jobCategory = "",
  createdAt = "",
  jobStatus,
  jobData,
  uid,
  handleUpdateJobStatus,
  handleUpdateMyJob,
}) => {
  const theme: ITheme = useTheme();
  const ref = useRef(null);
  const dispatch = useAppDispatch();
  const [open, toggleDialog] = useState(false);
  const [linkCopied, setLinkCopied] = useState(false);
  const [fadeOut, setFadeOut] = useState(false);
  const [isLinkedToATS, setIsLinkedToATS] = useState(false);
  const navigate = useNavigate();
  const interested_candidates =
    (jobData?.attached_candidates?.total || 0) -
    (jobData?.attached_candidates?.archived || 0);

  useOnClickOutside(ref, () => toggleDialog(false));

  const openDialog = (e: React.MouseEvent<HTMLImageElement>) => {
    e.stopPropagation();
    toggleDialog((prev: boolean) => !prev);
  };

  React.useEffect(() => {
    if (jobData.linked_ats_job_details) {
      setIsLinkedToATS(true);
    } else {
      setIsLinkedToATS(false);
    }
  }, [jobData.linked_ats_job_details]);

  const handleClickEditJob = async () => {
    await auth.getAuth().currentUser?.getIdTokenResult(true);
    const jobRef = doc(db, `${collection_name.v2_jobs}/${jobData.job_id}`);
    const docSnap = await getDoc(jobRef);
    const job_data = docSnap.data() as IJob;

    await dispatch(
      doCreateJobAction({
        job: {
          seniority_level: job_data.seniority_level,
          job_function: job_data.job_function,
          custom_job_function: job_data.custom_job_function,
          job_description_link: job_data.job_description_link,
          job_description: job_data.job_description,
          job_title: job_data.job_title,
          company_id: job_data.company_id || "",
          company_name: job_data.company_name || "",
          created_by: job_data.created_by || "",
          skills_nice_to_have: job_data.skills_nice_to_have,
          skills_not_to_have: job_data.skills_not_to_have,
          skills_must_have: job_data.skills_must_have,
          job_exciting_things: job_data.job_exciting_things,
          salary_range: job_data.salary_range,
          selected_currency: job_data.selected_currency,
          checkbox_pay_unknown: job_data.checkbox_pay_unknown,
          checkbox_share_salary_range: job_data.checkbox_share_salary_range,
          recruiter_notes: job_data.recruiter_notes,
        },
      })
    );

    // eslint-disable-next-line prettier/prettier
    navigate(`/${ROUTE_EDIT_JOB}`, {
      state: {
        from: ROUTE_ACTIVE_JOBS,
        uid: jobData.created_by,
        job_id: jobData.job_id,
        company_options: {
          company_id: jobData.company_id,
          company_name: jobData.company_name,
        },
      },
    });
  };

  const handleCardClicked = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    navigate(`/${ROUTE_ACTIVE_JOBS}/${id}`, { state: title });
  };

  const handleDisableBackdropClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };

  const isMyJob = jobData?.recruiters_attached?.includes(uid);

  // eslint-disable-next-line no-undef
  const CLIENT_DOMAIN = config.REACT_APP_CLIENT_DOMAIN;

  const copiedToClipboard = () => {
    if (linkCopied) return;

    setLinkCopied(true);
    setFadeOut(true);
    setTimeout(() => {
      setLinkCopied(false);
    }, 3000);
  };

  const CopyButton = () => {
    return (
      <Box
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        <CopyToClipboard
          text={`${CLIENT_DOMAIN}/${ROUTE_ACTIVE_JOBS}/${jobData.job_id}`}
          onCopy={() => {
            copiedToClipboard();
          }}
        >
          <Box
            sx={{
              background: linkCopied ? "#ffffff" : "#efefef",
              margin: 0,
              padding: 1.5,
              height: "auto",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "5px",
              border: linkCopied
                ? "1px solid #b5e4ca"
                : jobStatus === IJobStatus.ARCHIVE
                ? "1px solid #6f767e"
                : null,
            }}
          >
            <img src={linkCopied ? CopyFilledIcon : CopyIcon} alt="copy icon" />
            <Text
              sx={{
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: "13px",
                lineHeight: "16px",
                display: "flex",
                alignItems: "center",
                letterSpacing: "-0.01em",
                color: linkCopied ? "#4d9b32" : "#6f767e",
                marginLeft: "2px",
              }}
            >
              {linkCopied ? "Copied!" : "Copy Link"}
            </Text>
          </Box>
        </CopyToClipboard>
      </Box>
    );
  };

  return (
    <>
      <JobCard jobStatus={jobStatus} onClick={handleCardClicked}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box display="flex" alignItems={"center"}>
            <Span color={theme.palette.text.secondary}>{createdAt} ago</Span>
            {isLinkedToATS ? (
              <TheTooltip
                title={
                  <span style={{ fontSize: "15px" }}>
                    This job is linked to an ATS job
                  </span>
                }
                placement="bottom"
              >
                <Box
                  display="flex"
                  alignItems="center"
                  sx={{
                    marginLeft: "10px",
                    background: "#ffd88d",
                    borderRadius: "4px",
                    padding: "4px 6px",
                  }}
                >
                  <img
                    style={{ width: "16px", height: "16px" }}
                    src={IconLinkedATS}
                    alt="linked icon"
                  />
                  <Text
                    fontWeight={400}
                    fontSize="13px"
                    lineHeight={"16px"}
                    letterSpacing="-0.01em"
                    color={"#1A1D1F;"}
                  >
                    ATS
                  </Text>
                </Box>
              </TheTooltip>
            ) : null}
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              position: "relative",
            }}
          >
            <Box>
              {linkCopied && (
                <Grow
                  in={linkCopied}
                  style={{ transformOrigin: "0 0 0" }}
                  {...(linkCopied ? { timeout: 700 } : {})}
                >
                  {CopyButton()}
                </Grow>
              )}
              {!linkCopied && (
                <>
                  {fadeOut && (
                    <Fade
                      in={fadeOut}
                      style={{ transformOrigin: "0 0 0" }}
                      {...(fadeOut ? { timeout: 1000 } : {})}
                    >
                      {CopyButton()}
                    </Fade>
                  )}
                  {!fadeOut && CopyButton()}
                </>
              )}
            </Box>
            <EllipsisIcon
              src={Ellipsis}
              alt="Job options"
              onClick={openDialog}
            />

            {open ? (
              <Box
                onClick={(e: React.MouseEvent<HTMLDivElement>) => {
                  e.stopPropagation();
                  return;
                }}
                ref={ref}
                sx={{
                  position: "absolute",
                  right: "-1rem",
                  top: "8px",
                  // minHeight: "15rem",
                  zIndex: 1,
                  background: "#FFFFFF",
                  minWidth: "25rem",
                  padding: "1.2rem",
                  boxShadow:
                    // eslint-disable-next-line max-len
                    "0px 40px 63px -12px rgb(-1 -1 0 / 30%), 0px 0px 14px -4px rgb(0 0 0 / 24%), 0px 32px 48px -8px rgb(0 0 0 / 20%)",
                  borderRadius: "4px",
                }}
              >
                <Box
                  onClick={(e: React.MouseEvent<HTMLDivElement>) => {
                    e.stopPropagation();
                    return;
                  }}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    onClick={(e: React.MouseEvent<HTMLDivElement>) => {
                      e.stopPropagation();
                      return handleClickEditJob();
                    }}
                    onKeyDown={() => handleClickEditJob()}
                    sx={{
                      background: theme.palette.grey[50],
                      borderRadius: "12px",
                      padding: "12px",
                      marginBottom: "0.2rem",
                      "&:hover": {
                        background: theme.palette.grey[900],
                        "&>p": {
                          color: "#1A1D1F !important",
                        },
                      },
                    }}
                  >
                    <img src={Edit} alt="" style={{ width: "24px" }} />
                    <Text
                      fontSize="15px"
                      lineHeight="24px"
                      fontWeight={600}
                      letterSpacing="-0.01em"
                      color={theme.palette.grey[400]}
                    >
                      Edit Job
                    </Text>
                  </Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    onClick={(e: React.MouseEvent<HTMLDivElement>) => {
                      e.stopPropagation();
                      handleUpdateMyJob({
                        job: jobData,
                      });
                      toggleDialog(false);
                    }}
                    sx={{
                      background: theme.palette.grey[50],
                      borderRadius: "12px",
                      padding: "12px",
                      "&:hover": {
                        background: theme.palette.grey[900],
                        "&>p": {
                          color: "#1A1D1F !important",
                        },
                      },
                    }}
                  >
                    <img src={isMyJob ? Folder : FolderAdd} alt="folder icon" />
                    <Text
                      fontSize="15px"
                      lineHeight="24px"
                      fontWeight={600}
                      letterSpacing="-0.01em"
                      color={theme.palette.grey[400]}
                    >
                      {isMyJob ? "Remove from my jobs" : "Add to my jobs"}
                    </Text>
                  </Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    onClick={(e: React.MouseEvent<HTMLDivElement>) => {
                      e.stopPropagation();
                      const job_status =
                        jobStatus === IJobStatus.ARCHIVE
                          ? IJobStatus.ACTIVE
                          : IJobStatus.ARCHIVE;
                      return handleUpdateJobStatus({
                        job: { ...jobData, status: job_status },
                      });
                    }}
                    sx={{
                      background: theme.palette.grey[50],
                      borderRadius: "12px",
                      padding: "12px",
                      "&:hover": {
                        background: theme.palette.grey[900],
                        "&>p": {
                          color: "#1A1D1F !important",
                        },
                      },
                    }}
                  >
                    <img
                      src={
                        jobStatus === IJobStatus.ARCHIVE
                          ? ArchiveGreen
                          : ArchiveRed
                      }
                      alt=""
                    />
                    <Text
                      fontSize="15px"
                      lineHeight="24px"
                      fontWeight={600}
                      letterSpacing="-0.01em"
                      color={theme.palette.grey[400]}
                    >
                      {jobStatus === IJobStatus.ARCHIVE
                        ? "Unarchive job"
                        : "Archive job"}
                    </Text>
                  </Box>
                </Box>
                <Box
                  sx={{
                    right: "1.2rem",
                    position: "absolute",
                    top: "-17px",
                    borderLeft: "12px solid transparent",
                    borderRight: "12px solid transparent",
                    borderBottom: "19px solid #FFFFFF",
                  }}
                />
              </Box>
            ) : null}
          </Box>
        </Box>
        <Box>
          <JobTitle title={title} job_category={jobCategory} />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "15px",
              alignItems: "center",
            }}
          >
            <Span
              fontWeight={600}
              fontSize="15px"
              color={theme.custom.colors.dark_grey_1}
              sx={{ lineHeight: "1" }}
            >
              {interested_candidates} candidate
              {interested_candidates !== 1 && "s"}
            </Span>
            <Span
              sx={{
                display: "inline-flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                padding: "4px 7px",
                gap: "10px",
                backgroundColor: "#b5e4ca",
                borderRadius: "6px",
                lineHeight: "150%",
              }}
              fontSize="14px"
              color={theme.palette.text.secondary}
            >
              <img src={LightTicks} alt="" />
              <Box sx={{ paddingTop: "1px" }}>
                {jobData?.attached_candidates?.PENDING || 0} to review
              </Box>
            </Span>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <img src={ForwardArrow} alt="" />
          <Span
            sx={{
              display: "inline-flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "5px",
            }}
            color={theme.palette.grey[400]}
          >
            <img src={Building} alt="" />
            {jobData?.company_name}
          </Span>
        </Box>
        {/* <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          position: "relative",
        }}
      >
        <Span color={theme.palette.text.secondary}>{createdAt} ago</Span>
        <EllipsisIcon src={Ellipsis} alt="Job options" onClick={openDialog} /> */}

        {/* </Box> */}
        {/* <EditJobContainer
        onClick={handleDisableBackdropClick}
        ref={ref}
        className={open ? "show" : ""}
      >
        {jobStatus === IJobStatus.ARCHIVE ? (
          <EditTextContainer
            onClick={() =>
              handleUpdateJobStatus({
                job: { ...jobData, status: IJobStatus.ACTIVE },
              })
            }
            onKeyDown={() =>
              handleUpdateJobStatus({
                job: { ...jobData, status: IJobStatus.ACTIVE },
              })
            }
            className="unarchive"
          >
            <img src={Unarchive} alt="" />
          </EditTextContainer>
        ) : (
          <EditTextContainer
            onClick={() =>
              handleUpdateJobStatus({
                job: { ...jobData, status: IJobStatus.ARCHIVE },
              })
            }
            onKeyDown={() =>
              handleUpdateJobStatus({
                job: { ...jobData, status: IJobStatus.ARCHIVE },
              })
            }
            className="archive"
          >
            <img src={Archive} alt="Archive" />
          </EditTextContainer>
        )}
        <EditTextContainer
          onClick={handleClickEditJob}
          onKeyDown={handleClickEditJob}
        >
          <img src={Edit} alt="" />
          <Span color="#6F767E" fontSize="12px" sx={{ lineHeight: "1" }}>
            Edit
          </Span>
        </EditTextContainer>
        <EditTextContainer
          onClick={() => {
            handleUpdateMyJob({
              job: jobData,
            });
            toggleDialog(false);
          }}
        >
          <img src={isMyJob ? Folder : FolderAdd} alt="folder icon" />
          <Span
            color="#6F767E"
            fontSize="12px"
            sx={{ lineHeight: "21px", fontSize: "12px" }}
          >
            {isMyJob ? "Remove from my jobs" : "Add to my jobs"}
          </Span>
        </EditTextContainer>
      </EditJobContainer> */}
      </JobCard>
    </>
  );
};

export default CardWithJob;
