import { createSlice } from "@reduxjs/toolkit";

import { doRemoveSlackUserAction } from "./manageSlackNotification.actions";
import { IState } from "./interface";

const initialState: IState = {
  email: "",
  uid: "",
  slack_access_token: "",
  slack_user_access_token: "",
  role: "",
};

export const RemoveSlackNotificationUserSlice = createSlice({
  name: "clients-removeSlackUsers",
  initialState,
  reducers: {
    // eslint-disable-next-line no-unused-vars
    increment: (state, action) => {
      // state.candidateList = [1, 2];
      // ✅ CORRECT: logs a plain JS copy of the current data
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(doRemoveSlackUserAction.pending, (state: IState) => {
        state.status = "pending";
      })
      .addCase(
        doRemoveSlackUserAction.fulfilled,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (state: IState, action: any) => {
          state.status = "fulfilled";
          state.response = action.payload;
        }
      )
      .addCase(doRemoveSlackUserAction.rejected, (state: IState) => {
        state.status = "rejected";
        state.response = {};
      });
  },
});

export const { increment } = RemoveSlackNotificationUserSlice.actions;
export default RemoveSlackNotificationUserSlice.reducer;
