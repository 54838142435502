import { createAsyncThunk } from "@reduxjs/toolkit";
import { auth as firebaseAuth } from "utils/firebase";
import { IEmail } from "./interface";

export const doForgotPasswordAction = createAsyncThunk(
  "users/forgotPassword",
  async (payload: IEmail) => {
    try {
      if (payload.email) {
        const auth = firebaseAuth.getAuth();
        const response = await firebaseAuth
          .sendPasswordResetEmail(auth, payload.email)
          .then(() => {
            return { success: true };
          })
          .catch((err) => {
            return {
              error: {
                error_code: err.code,
                error_message: err.message,
              },
            };
          });
        return response;
      }
    } catch (err) {
      return { error: err };
    }
  }
);
