import { AsyncThunk, createAsyncThunk } from "@reduxjs/toolkit";
import { IUpdateCandidateInterviewPayload } from "./interface";
import { db, doc, setDoc } from "utils/firebase";

export const doUpdateCandidateInterviewAction: AsyncThunk<
  IUpdateCandidateInterviewPayload,
  IUpdateCandidateInterviewPayload,
  {}
> = createAsyncThunk(
  "candidate/update-interview",
  async (payload, { rejectWithValue }) => {
    try {
      const candidate_id = payload.candidate_id;
      const job_id = payload.job_id;

      const candidateRef = doc(
        db,
        `v2_jobs/${job_id}/connected_candidates/${candidate_id}`
      );
      await setDoc(candidateRef, payload.updated_candidate, { merge: true });

      return payload;
    } catch (error) {
      return rejectWithValue(JSON.stringify(error));
    }
  }
);
