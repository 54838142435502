import { createSlice } from "@reduxjs/toolkit";
import { ICreateCandidatePayload, ICreateCandidateState } from "./interface";
import { doCreateCandidateAction } from "./create.actions";
import { ReduxStatus } from "types/types";

const initialResponse: ICreateCandidatePayload = {
  candidate: {},
};
const initialState: ICreateCandidateState = {
  status: ReduxStatus.empty,
  response: initialResponse,
  error: {},
};

export const createCandidate = createSlice({
  name: "candidate/create",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(doCreateCandidateAction.pending, (state) => {
        state.status = ReduxStatus.pending;
      })
      .addCase(doCreateCandidateAction.fulfilled, (state, action) => {
        state.status = ReduxStatus.fulfilled;
        state.error = {};
        state.response = action.payload;
      })
      .addCase(doCreateCandidateAction.rejected, (state, action) => {
        state.status = ReduxStatus.rejected;
        state.response = initialResponse;
        state.error = action.error;
      });
  },
});

export default createCandidate.reducer;
