import { createSlice } from "@reduxjs/toolkit";
import { ISignInState } from "./interface";
import { doSignInAction } from "./signin.actions";
import { ReduxStatus } from "types/types";
import { initialUser } from "constants/constants";

const initialState: ISignInState = {
  status: ReduxStatus.empty,
  response: initialUser,
  error: {},
};

export const signin = createSlice({
  name: "users/signin",
  initialState,
  reducers: {
    increment: () => {
      // state.candidateList = [1, 2];
      // ✅ CORRECT: logs a plain JS copy of the current data
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(doSignInAction.pending, (state) => {
        state.status = ReduxStatus.pending;
      })
      .addCase(doSignInAction.fulfilled, (state, action) => {
        state.status = ReduxStatus.fulfilled;
        state.error = {};
        state.response = action.payload;
      })
      .addCase(doSignInAction.rejected, (state, action) => {
        state.status = ReduxStatus.rejected;
        state.response = initialUser;
        state.error = action.error;
      });
  },
});

export const { increment } = signin.actions;
export default signin.reducer;
