import React, { FC } from "react";
import Text from "components/shared/text/text";
import SharedTooltip from "components/shared/tooltip/tooltip";

interface IProps {
  title: string;
  job_category?: string;
  className?: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const TitleIcon: any = {
  "Software Engineering": "💻 ",
  "Product Design": "🎨 ",
  Marketing: "📢 ",
  Sales: "☎️ ",
  "Product Management": "📈 ",
  "Customer Success": "🤝 ",
  Other: "✍️ ️",
};

const JobTitle: FC<IProps> = ({ title, job_category = "", className = "" }) => {
  return title.length > 31 ? (
    <SharedTooltip title={title}>
      <Text
        className={className}
        fontSize="24px"
        color=""
        sx={{
          textAlign: "left",
          marginLeft: 0,
          textOverflow: "ellipsis",
          maxWidth: "100%",
          whiteSpace: "nowrap",
          overflow: "hidden",
          "&.new-job": {
            marginBottom: "35px",
          },
        }}
      >
        <span>{TitleIcon[`${job_category}`]}</span>
        {title}
      </Text>
    </SharedTooltip>
  ) : (
    <Text
      className={className}
      fontSize="24px"
      color=""
      sx={{
        textAlign: "left",
        marginLeft: 0,
        textOverflow: "ellipsis",
        maxWidth: "100%",
        whiteSpace: "nowrap",
        overflow: "hidden",
        "&.new-job": {
          marginBottom: "35px",
        },
      }}
    >
      <span>{TitleIcon[`${job_category}`]}</span>
      {title}
    </Text>
  );
};

export default JobTitle;
