import React from "react";
import { styled } from "@mui/material/styles";
import Nest from "assets/images/nest.svg";
import Text from "components/shared/text/text";

const CandidatesArea = styled("div")`
  background: ${({ theme }) => theme.palette.grey[100]};
  padding: 24px;
  margin-top: 8px;
  border-radius: 8px;
  align-items: center;
  font-family: "Inter";
`;

const calendlyLink = "https://calendly.com/ricardo-meeting/30min";

const NoCandidates = () => {
  return (
    <CandidatesArea
      sx={{
        height: "538px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <img src={Nest} alt="nest image" />
      <Text
        sx={{
          marginTop: "2rem",
          fontFamily: "Inter",
          fontStyle: "normal",
          fontWeight: 600,
          fontSize: "32px",
          lineHeight: "40px",
          letterSpacing: "-0.03em",
        }}
      >
        No Candidates yet.
      </Text>
      <Text
        sx={{
          marginTop: "1rem",
          fontFamily: "Inter",
          fontStyle: "normal",
          color: "#6F767e",
          fontWeight: 400,
          fontSize: "14px",
          lineHeight: "150%",
          letterSpacing: "-0.01em",
        }}
      >
        Connect is working to find recommended candidates for you.
      </Text>
      <Text
        sx={{
          marginTop: "1rem",
          fontFamily: "Inter",
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: "14px",
          lineHeight: "150%",
          letterSpacing: "-0.01em",
          textDecorationLine: "underline",
          cursor: "pointer",
        }}
        onClick={() => window.open(calendlyLink, "_blank")}
      >
        Purchase credits to directly receive interested candidates
      </Text>
    </CandidatesArea>
  );
};

export default NoCandidates;
