import * as React from "react";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import { useSelector } from "react-redux";
import { formatDistance } from "date-fns";

import CreateJobCard from "./create-job-card";
import CardWithJob from "./card-with-job";
import { useAppDispatch, RootState } from "redux/store";
import { _uniqBy } from "utils/lodash";
import { IJob, IJobStatus, TJobStatusOption } from "types/types";
import InfiniteScroll from "react-infinite-scroll-component";
import { doGetJobsAction } from "redux/services/job/getJob/get.actions";
import Loader from "assets/images/spinner.svg";
import "./styles.scss";
import { doUpdateJobAction } from "redux/services/job/update/update.actions";
import { updateJobs, restructureJobs } from "redux/reselect";
import Dialog from "components/dialog";
import { toast } from "react-toastify";
import ToastMessage from "components/toast-message";
import { IJobsState } from "./interface";
import clientSearch from "utils/algolia";

const Container = styled(Box)`
  column-gap: 1rem;
  row-gap: 2rem;
  grid-auto-rows: minmax(min-content, max-content);
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(335px, 1fr));
  min-height: 300px;
`;

interface IProps {
  jobsState: IJobsState;
  setJobsState: Function;
}

const JobsContainer = ({ jobsState, setJobsState }: IProps) => {
  const dispatch = useAppDispatch();
  const jobsGetState = useSelector(
    (reduxState: RootState) => reduxState.jobsGet.response
  );
  const jobUpdateState = useSelector(
    (reduxState: RootState) => reduxState.jobUpdate || {}
  );
  const userState = useSelector(
    (reduxState: RootState) => reduxState.user.response
  );
  const companyState = useSelector(
    (reduxState: RootState) => reduxState.getCompany.response
  );

  const updateJob = (updatedJob: IJob) => {
    const newJobList = updateJobs({
      updatedJob,
      jobList: jobsGetState.jobs as IJob[],
    });
    const restructuredList = restructureJobs({
      jobState: newJobList || [],
      jobStatus: jobsGetState.jobStatus as TJobStatusOption,
      uid: userState.uid,
    });

    setJobsState((st: IJobsState) => ({
      ...st,
      job_list: restructuredList,
      openDialog: false,
    }));

    return dispatch(
      doGetJobsAction({
        ...jobsGetState,
        jobs: restructuredList,
      })
    );
  };

  const handleAcceptDialog = async () => {
    await dispatch(
      doUpdateJobAction({
        job_id: jobsState.selectedCard.job_id as string,
        job: {
          status: jobsState.selectedCard.status,
        },
      })
    );

    updateJob(jobsState.selectedCard);
    setJobsState((st: IJobsState) => ({
      ...st,
      openDialog: false,
      selectedCard: {},
    }));
  };

  const handleUpdateJobStatus = async ({ job }: { job: IJob }) => {
    if (!job?.status || !job?.job_id || !userState.uid) {
      toast(<ToastMessage title="Status" body="Missing status" />, {
        type: "error",
        position: "top-right",
      });
      return;
    }
    setJobsState((st: IJobsState) => ({
      ...st,
      openDialog: true,
      selectedCard: job,
    }));
  };

  const handleUpdateMyJob = async ({ job }: { job: IJob }) => {
    if (!job?.job_id || !userState.uid) {
      toast(<ToastMessage title="Info" body="Missing information" />, {
        type: "error",
        position: "top-right",
      });
      return;
    }

    const isMyJob = job?.recruiters_attached?.includes(userState.uid);
    const toastMsg = isMyJob ? "Removed from my jobs" : "Added to my jobs";
    let recruiters_attached = job.recruiters_attached || [];

    if (isMyJob) {
      recruiters_attached = recruiters_attached.filter(
        (uid) => uid !== userState.uid
      );
    } else {
      recruiters_attached = [...recruiters_attached, userState.uid];
    }

    const updatedJob: IJob = {
      ...job,
      recruiters_attached,
    };

    await dispatch(
      doUpdateJobAction({
        job_id: job.job_id as string,
        job: {
          recruiters_attached,
        },
      })
    );

    updateJob(updatedJob);

    toast(<ToastMessage title="My Jobs" body={toastMsg} />, {
      type: "success",
      position: "top-right",
    });
  };

  const renderCard = () => {
    if (Array.isArray(jobsGetState.jobs)) {
      const now = new Date();
      return jobsGetState.jobs.map((job: IJob) => {
        let date_diff = formatDistance(new Date(job.created_at), now);
        if (date_diff.split(" ")[0] === "about") {
          date_diff = date_diff.split(" ").slice(1, date_diff.length).join(" ");
        }
        return (
          <CardWithJob
            jobData={job}
            key={job.job_id}
            id={job.job_id}
            title={job.job_title || "-"}
            company={companyState.name || ""}
            jobCategory={job.job_function || ""}
            jobStatus={job.status}
            createdAt={job.created_at ? `${date_diff}` : ""}
            uid={userState.uid || ""}
            handleUpdateJobStatus={handleUpdateJobStatus}
            handleUpdateMyJob={handleUpdateMyJob}
          />
        );
      });
    }
    return null;
  };

  const fetchJobs = async (initialFetch = false) => {
    const searchIndex = clientSearch.initIndex("v2_jobs");
    let filters = "";

    if (!jobsGetState?.jobStatus || jobsGetState?.jobStatus === "MY_JOBS") {
      filters += `recruiters_attached:'${userState?.uid}'`;
    } else {
      if (
        jobsGetState?.jobStatus === IJobStatus.ACTIVE ||
        jobsGetState?.jobStatus === IJobStatus.ARCHIVE
      ) {
        filters += `status:'${jobsGetState?.jobStatus}'`;
      }
    }

    if (
      jobsGetState.company_id &&
      jobsGetState?.company_id !== "ALL_COMPANIES"
    ) {
      if (
        !jobsGetState?.jobStatus ||
        (jobsGetState?.jobStatus && jobsGetState.jobStatus !== "ALL_JOBS")
      ) {
        filters += ` AND `;
      }
      filters += `company_id:'${jobsGetState?.company_id}'`;
    }

    if (
      jobsGetState?.selected_job_function &&
      jobsGetState?.selected_job_function !== "All_job_functions"
    ) {
      const selected_job_function = jobsGetState.selected_job_function
        .split("_")
        .join(" ");

      if (
        !jobsGetState?.jobStatus ||
        (jobsGetState?.jobStatus && jobsGetState.jobStatus !== "ALL_JOBS") ||
        (jobsGetState?.company_id &&
          jobsGetState?.company_id !== "ALL_COMPANIES")
      ) {
        filters += ` AND `;
      }
      filters += `job_function:'${selected_job_function}'`;
    }

    if (jobsGetState?.period?.start && jobsGetState?.period?.end) {
      const start = Math.round(
        new Date(jobsGetState.period?.start as string).getTime() / 1000
      );
      const end = Math.round(
        new Date(jobsGetState.period?.end as string).getTime() / 1000
      );

      if (
        !jobsGetState?.jobStatus ||
        (jobsGetState?.jobStatus && jobsGetState.jobStatus !== "ALL_JOBS") ||
        (jobsGetState?.company_id &&
          jobsGetState?.company_id !== "ALL_COMPANIES") ||
        (jobsGetState?.selected_job_function &&
          jobsGetState?.selected_job_function !== "All_job_functions")
      ) {
        filters += ` AND `;
      }

      filters += `created_at._seconds:${start} TO ${end}`;
    }

    const searchQuery = jobsGetState?.searchQuery
      ? jobsGetState?.searchQuery
      : "";

    try {
      if (initialFetch) {
        await clientSearch.clearCache();
      }

      const pageToFetch = initialFetch ? 0 : jobsGetState.page;
      const results = await searchIndex.search(searchQuery, {
        filters,
        page: pageToFetch,
      });

      const jobList: IJob[] = [];
      const result_jobs = results.hits as IJob[];

      result_jobs.forEach((jobData) => {
        jobList.push({
          seniority_level: jobData.seniority_level,
          job_function: jobData.job_function,
          custom_job_function: jobData.custom_job_function,
          job_description: jobData.job_description,
          linked_ats_job_details: jobData.linked_ats_job_details,
          recruiter_notes: jobData.recruiter_notes,
          job_description_link: jobData.job_description_link,
          job_title: jobData.job_title,
          skills_must_have: jobData.skills_must_have,
          skills_nice_to_have: jobData.skills_nice_to_have,
          skills_not_to_have: jobData.skills_not_to_have,
          job_exciting_things: jobData.job_exciting_things,
          salary_range: jobData.salary_range,
          selected_currency: jobData.selected_currency,
          checkbox_pay_unknown: jobData.checkbox_pay_unknown,
          checkbox_share_salary_range: jobData.checkbox_share_salary_range,
          company_id: jobData.company_id,
          company_name: jobData.company_name,
          created_by: jobData.created_by,
          status: jobData.status,
          job_id: jobData.objectID,
          created_at: jobData.created_at
            ? new Date(jobData.created_at._seconds * 1000).toISOString()
            : new Date().toISOString(),
          attached_candidates: {
            total: jobData.attached_candidates?.total,
            PENDING: jobData.attached_candidates?.PENDING,
            SHORTLISTED: jobData.attached_candidates?.SHORTLISTED,
            REJECTED: jobData.attached_candidates?.REJECTED,
            archived: jobData.attached_candidates?.archived,
          },
          recruiters_attached: jobData.recruiters_attached,
        });
      });

      const list = results.page === 0 ? [] : [...jobsGetState.jobs];
      if (jobsGetState?.job_added) {
        list.unshift(jobsGetState?.job_added);
      }

      const new_list = list.concat(jobList);
      let unique_list = _uniqBy(new_list, "job_id");

      if (jobsGetState?.job_updated) {
        const updatedJob = jobsGetState?.job_updated;
        const isInJobList = unique_list.some(
          (job) => job.job_id === updatedJob.job_id
        );
        if (isInJobList) {
          unique_list = updateJobs({
            updatedJob,
            jobList: unique_list,
          });
        }
      }

      const restructuredList = restructureJobs({
        jobState: unique_list || [],
        jobStatus: jobsGetState.jobStatus || "MY_JOBS",
        uid: userState.uid,
      });
      const hasMore = results.page < results.nbPages - 1;

      setJobsState((st: IJobsState) => ({
        ...st,
        page: results?.page + 1,
        totalPages: results.nbPages,
        totalJobs: results.nbHits,
        hasMore,
        job_list: restructuredList,
      }));

      return dispatch(
        doGetJobsAction({
          ...jobsGetState,
          page: results?.page + 1,
          totalPages: results.nbPages,
          totalJobs: results.nbHits,
          hasMore,
          jobs: restructuredList,
          job_added: undefined,
          job_updated: undefined,
        })
      );
    } catch (error) {
      toast(
        <ToastMessage
          title="Jobs"
          body="An error occurred while fetching jobs"
        />,
        {
          type: "error",
          position: "top-right",
        }
      );
    }
  };

  React.useEffect(() => {
    fetchJobs(true);
  }, [
    jobsGetState?.selected_job_function,
    jobsGetState?.jobStatus,
    jobsGetState?.period?.start,
    jobsGetState?.period?.end,
    jobsGetState?.company_id,
    jobsGetState?.searchQuery,
  ]);

  return (
    <Box
      id="scrollableDiv"
      sx={[
        { overflow: "auto" },
        (theme) => ({
          [theme.breakpoints.down("xl")]: {
            height: "66vh",
          },
          [theme.breakpoints.up("xl")]: {
            height: "75vh",
          },
        }),
      ]}
    >
      <InfiniteScroll
        dataLength={jobsGetState?.jobs?.length || 0}
        next={fetchJobs}
        hasMore={jobsGetState?.hasMore}
        scrollableTarget="scrollableDiv"
        loader={
          (jobsGetState.hasMore || true) && (
            <div className="jobs-loader__container">
              <div className="jobs-loader-item">
                <img
                  className="jobs-loader__spinner"
                  src={Loader}
                  alt="spinner"
                />
                <div className="jobs-loader__text">
                  {!jobsGetState?.jobs?.length ? "Loading..." : "Load more"}
                </div>
              </div>
            </div>
          )
        }
      >
        <Container>
          <CreateJobCard />
          {renderCard()}
        </Container>
      </InfiniteScroll>
      <Dialog
        openDialog={jobsState.openDialog}
        isLoading={jobUpdateState.status === "pending"}
        handleCloseDialog={() => {
          setJobsState((st: IJobsState) => ({ ...st, openDialog: false }));
        }}
        handleAcceptDialog={handleAcceptDialog}
        body={`Are you sure you want to
              ${
                jobsState.selectedCard.status === IJobStatus.ACTIVE
                  ? "unarchive"
                  : "archive"
              } this Job Opening?
            `}
      />
    </Box>
  );
};

export default JobsContainer;
