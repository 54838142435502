import React, { FC, forwardRef } from "react";
import Checkbox, { CheckboxProps } from "@mui/material/Checkbox";
import { useTheme } from "@mui/material/styles";
import { FormGroup, FormControlLabel } from "@mui/material";

type Props = CheckboxProps & {
  label?: string;
};
const CheckBox: FC<Props> = forwardRef(({ label, ...props }, ref) => {
  const theme = useTheme();
  return (
    <FormGroup>
      <FormControlLabel
        sx={{
          "& .MuiTypography-root": {
            color: "#33383F",
            fontSize: "14px",
            fontWeight: "400",
            fontFamily: "inter",
          },
        }}
        control={
          <Checkbox
            disableRipple
            sx={{
              color: "rgba(111, 118, 126, 0.4)",
              "&.Mui-checked": {
                color: theme.palette.secondary.main,
              },
            }}
            {...props}
            ref={ref}
          />
        }
        label={label}
      />
    </FormGroup>
  );
});
CheckBox.displayName = "CheckBox";
export default CheckBox;
