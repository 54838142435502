/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { EditorState } from "draft-js";
import React from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./styles.scss";

interface IProps {
  editorState: EditorState;
  onEditorStateChange: (editorState: EditorState) => void;
  readOnly?: boolean;
}

// allow image upload from local data URI
// https://github.com/jpuri/react-draft-wysiwyg/blob/master/stories/ImageDataURI/index.js

// Allow image upload to url
// https://github.com/jpuri/react-draft-wysiwyg/blob/master/stories/ImageUpload/index.js

// Localizations
// https://github.com/jpuri/react-draft-wysiwyg/blob/master/stories/I18n/index.js

// Focus callbacks
// https://github.com/jpuri/react-draft-wysiwyg/blob/master/stories/FocusBlurCallbacks/index.js

// Embedable
// https://github.com/jpuri/react-draft-wysiwyg/blob/master/stories/Embeddable/index.js

const SharedEditor: React.FC<IProps> = ({
  editorState,
  readOnly = false,
  onEditorStateChange,
}) => {
  // const editorRef = React.useRef<Editor>(null);
  return (
    <Editor
      // ref={editorRef}
      wrapperClassName="mod-wrapper"
      editorClassName="mod-editor"
      toolbarClassName="mod-toolbar"
      editorState={editorState}
      onEditorStateChange={onEditorStateChange}
      readOnly={readOnly}
      // spellCheck
      toolbar={
        {
          // inline: { inDropdown: true },
          // list: { inDropdown: true },
          // textAlign: { inDropdown: true },
          // link: { inDropdown: true },
          // history: { inDropdown: true },
          // image: {
          //   uploadCallback: uploadImageCallBack,
          //   alt: { present: true, mandatory: true },
          // },
        }
      }
      // use this mentions to fetch users from the company.
      // mention={{
      //   separator: " ",
      //   trigger: "@",
      //   suggestions: [
      //     { text: "APPLE", value: "apple", url: "apple" },
      //     { text: "BANANA", value: "banana", url: "banana" },
      //     { text: "CHERRY", value: "cherry", url: "cherry" },
      //     { text: "DURIAN", value: "durian", url: "durian" },
      //     { text: "EGGFRUIT", value: "eggfruit", url: "eggfruit" },
      //     { text: "FIG", value: "fig", url: "fig" },
      //     { text: "GRAPEFRUIT", value: "grapefruit", url: "grapefruit" },
      //     { text: "HONEYDEW", value: "honeydew", url: "honeydew" },
      //   ],
      // }}
    />
  );
};

export default SharedEditor;
