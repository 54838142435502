import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

interface TextProps {
  fontWeight?: number;
  fontSize?: string;
  lineHeight?: string;
  letterSpacing?: string;
  color?: string;
}

const Text = styled(Typography)<TextProps>`
  font-family: Inter;
  font-style: normal;
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : 600)};
  font-size: ${({ fontSize }) => (fontSize ? fontSize : "18px")};
  line-height: ${({ lineHeight }) => (lineHeight ? lineHeight : "32px")};
  text-align: center;
  letter-spacing: ${({ letterSpacing }) =>
    letterSpacing ? letterSpacing : "-0.02em"};
  margin-left: 10px;
  color: ${({ theme, color }) => {
    return color ? color : theme.palette.text.secondary;
  }};
`;

export default Text;
