import { AnyAction, createSlice } from "@reduxjs/toolkit";
import { IState } from "./interface";
import { doInviteMemberAction } from "./invite.actions";

const initialState: IState = {
  status: "",
  response: {},
  error: {},
};

export const inviteMember = createSlice({
  name: "send-company-invite",
  initialState,
  reducers: {
    increment: () => {
      // state.candidateList = [1, 2];
      // ✅ CORRECT: logs a plain JS copy of the current data
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(doInviteMemberAction.pending, (state: IState) => {
        state.status = "pending";
      })
      .addCase(
        doInviteMemberAction.fulfilled,
        (state: IState, action: AnyAction) => {
          state.status = "fulfilled";
          state.error = {};
          state.response = action.payload;
        }
      )
      .addCase(
        doInviteMemberAction.rejected,
        (state: IState, action: AnyAction) => {
          state.status = "rejected";
          state.response = {};
          state.error = action.error;
        }
      );
  },
});

export const { increment } = inviteMember.actions;
export default inviteMember.reducer;
