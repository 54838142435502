import LoadingButton from "@mui/lab/LoadingButton";
import { styled } from "@mui/material/styles";

type IProps = {
  backgroundColor?: string;
  handleClick?: () => void;
};

const SelectButton = styled(LoadingButton)<IProps>`
  background: #efefef;
  color: #1a1d1f;
  border: 2px solid #e8e8e8;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 18px;
  border-radius: 12px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  text-transform: capitalize;
  margin-left: 10px;
  &.selected {
    background-color: #111315;
    color: #fcfcfc;
    border: 2px solid rgba(17, 19, 21, 0.5);
  }
`;

export default SelectButton;
