import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IUpdateJobPayload, IUpdateJobState } from "./interface";
import { doUpdateJobAction } from "./update.actions";
import { ReduxStatus } from "types/types";

const initialResponse: IUpdateJobPayload = {
  job_id: "",
  job: {},
};

const initialState: IUpdateJobState = {
  status: ReduxStatus.empty,
  response: initialResponse,
  error: {},
};

export const updateJob = createSlice({
  name: "job/update",
  initialState,
  reducers: {
    increment: () => {
      // state.candidateList = [1, 2];
      // ✅ CORRECT: logs a plain JS copy of the current data
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(doUpdateJobAction.pending, (state: IUpdateJobState) => {
        state.status = ReduxStatus.pending;
      })
      .addCase(
        doUpdateJobAction.fulfilled,
        (state: IUpdateJobState, action: PayloadAction<IUpdateJobPayload>) => {
          state.status = ReduxStatus.fulfilled;
          state.error = {};
          state.response = action.payload;
        }
      )
      .addCase(
        doUpdateJobAction.rejected,
        (state: IUpdateJobState, action: PayloadAction<unknown>) => {
          state.status = ReduxStatus.rejected;
          state.response = initialResponse;
          state.error = action.payload;
        }
      );
  },
});

export const { increment } = updateJob.actions;
export default updateJob.reducer;
