/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import Select from "react-select";

const customStyles = {
  container: (provided: any) => ({
    ...provided,
    width: "100%",
  }),
  control: (provided: any) => ({
    ...provided,
    minHeight: "47px",
    borderWidth: 2,
    borderRadius: 10,
    borderColor: "#f2f2f2",
    boxShadow: "none",
    "&:hover": {
      borderColor: "#ccc",
    },
    "&:focus": {
      boxShadow: "none",
    },
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    color: "#6F767E",
  }),
  indicatorSeparator: (provided: any) => ({
    ...provided,
    display: "none",
  }),
  singleValue: (provided: any) => ({
    ...provided,
    fontFamily: "Inter",
    fontWeight: 600,
    fontSize: 18,
    color: "#6F767E",
    letterSpacing: "-0.02em",
  }),
  menu: (provided: any) => ({
    ...provided,
    padding: 12,
    borderRadius: 8,
    boxShadow: "none",
    backgroundColor: "#FCFCFC",
    border: "2px solid #f2f2f2",
  }),
  option: (provided: any, { isFocused, isSelected }: any) => ({
    ...provided,
    marginBottom: 10,
    padding: "8px 12px",
    fontWeight: 600,
    fontSize: 15,
    letterSpacing: "-0.01em",
    backgroundColor: isFocused ? "#EFEFEF" : isSelected ? "#EFEFEF" : "#FCFCFC",
    borderRadius: isFocused ? 4 : isSelected ? 4 : 0,
    color: isFocused ? "#1A1D1F" : isSelected ? "#1A1D1F" : "#6F767E",
    "&:hover": {
      // backgroundColor: "#EFEFEF",
      borderRadius: 4,
      color: "#1A1D1F",
      background: "none",
      cursor: "pointer",
    },
  }),
};

const SelectComponent = (props: any) => {
  return (
    <Select onChange={props.handleChange} styles={customStyles} {...props} />
  );
};

export default SelectComponent;
