import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

interface IProps {}

const HR = styled(Box)<IProps>`
  width: 100%;
  border-radius: 2px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.grey[300]};
`;

export default HR;
