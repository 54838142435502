import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import {
  topics,
  dataCollectionMethods,
  protectionRights,
  dataUsage,
  dataRecipient,
  dataManagement,
  cookieTypes,
  manageCookies,
} from "./content";

const PrivacyPolicy = () => {
  const ContentArea = styled("div")`
    width: 75%;
  `;

  const Header = styled("div")`
    text-align: center;
  `;

  const Contact = styled("div")`
    margin-bottom: 8px;
  `;

  return (
    <Box
      sx={{
        backgroundColor: (theme) => theme.palette.grey[300],
        display: "flex",
        justifyContent: "center",
        paddingTop: "70px",
      }}
    >
      <ContentArea>
        <Header>
          <Typography variant="h2" gutterBottom component="div">
            ACELR8 Ltd <br />
            Privacy Policy
          </Typography>
          <Typography
            variant="h5"
            gutterBottom
            component="div"
            sx={{
              margin: "0 auto 40px auto",
              maxWidth: "60%",
              fontSize: "22px",
            }}
          >
            ACELR8 Ltd is ACELR8 Ltd. This privacy policy will explain how our
            organization uses the personal data we collect from you when you use
            our website.
          </Typography>
        </Header>

        <article>
          <Typography
            variant="h5"
            gutterBottom
            component="div"
            sx={{ fontWeight: 800 }}
          >
            Topics:
          </Typography>
          <ul>
            {topics.map((topic, index) => (
              <li key={index}>{topic}</li>
            ))}
          </ul>
        </article>
        <article>
          <Typography
            variant="h5"
            gutterBottom
            component="div"
            sx={{ fontWeight: 800 }}
          >
            How do we collect your data?
          </Typography>
          <p>
            You directly provide ACELR8 Ltd with most of the data we collect. We
            collect data and process data when you:
          </p>
          <ul>
            {dataCollectionMethods.map((topic, index) => (
              <li key={index}>{topic}</li>
            ))}
          </ul>
          <p>
            ACELR8 Ltd may also receive some of your data indirectly – when
            you’re visiting our profiles on external sites like social networks
            - from the following sources: Google, Linkedin, Twitter, Facebook
          </p>
        </article>
        <article>
          <Typography
            variant="h5"
            gutterBottom
            component="div"
            sx={{ fontWeight: 800 }}
          >
            How will we use your data?
          </Typography>
          <p>{dataUsage}</p>
        </article>
        <article>
          <Typography
            variant="h5"
            gutterBottom
            component="div"
            sx={{ fontWeight: 800 }}
          >
            Who receives your data?
          </Typography>
          <p>{dataRecipient}</p>
        </article>
        <article>
          <Typography
            variant="h5"
            gutterBottom
            component="div"
            sx={{ fontWeight: 800 }}
          >
            What happens to your data if you apply for a job?
          </Typography>
          <p>{dataManagement}</p>
        </article>
        <article>
          <Typography
            variant="h5"
            gutterBottom
            component="div"
            sx={{ fontWeight: 800 }}
          >
            What are your data protection rights?
          </Typography>
          <div>
            ACELR8 Ltd would like to make sure you are fully aware of all of
            your data protection rights. Every user is entitled to the
            following:
          </div>
          <ul>
            {protectionRights.map((right, index) => (
              <li key={index}>{right}</li>
            ))}
          </ul>
        </article>
        <article>
          <Typography
            variant="h5"
            gutterBottom
            component="div"
            sx={{ fontWeight: 800 }}
          >
            How do we use cookies?
          </Typography>
          <p>
            ACELR8 Ltd uses cookies and similar technologies. Cookies are text
            files placed on your computer to collect browser information and
            visitor behavior information. When you visit our websites, we may
            collect information from you automatically through cookies or
            similar technology in a range of ways to improve your experience on
            our website, including:
          </p>
          <ul>
            <li>Understanding how you use our website</li>
            <li>Understanding where you work</li>
          </ul>
        </article>
        <article>
          <Typography
            variant="h5"
            gutterBottom
            component="div"
            sx={{ fontWeight: 800 }}
          >
            What types of cookies do we use?
          </Typography>
          <p>{cookieTypes}</p>
        </article>
        <article>
          <Typography
            variant="h5"
            gutterBottom
            component="div"
            sx={{ fontWeight: 800 }}
          >
            How to manage cookies and similar technologies?
          </Typography>
          <p>{manageCookies}</p>
        </article>
        <article>
          <Typography
            variant="h5"
            gutterBottom
            component="div"
            sx={{ fontWeight: 800 }}
          >
            How to contact us
          </Typography>
          <div>
            <p>
              If you have any questions about ACELR8 Ltd’s privacy policy, the
              data we hold on you, or you would like to exercise one of your
              data protection rights, please do not hesitate to contact us.
            </p>
            <Contact>
              Email us at:{" "}
              <a target="_blank" href="catia@acelr8.com" rel="noreferrer">
                catia@acelr8.com
              </a>
            </Contact>
            <Contact>
              Call us: <a href="tel:+442071938069">+44 2071 938069</a>
            </Contact>
            <Contact sx={{ marginBottom: "24px" }}>
              Or write to us at: Dieffenbachstraße 36, 10967 Berlin
            </Contact>
          </div>
        </article>
      </ContentArea>
    </Box>
  );
};

export default PrivacyPolicy;
