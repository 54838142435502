import * as React from "react";
import Drawer from "layouts/drawer";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import CompaniesHeader from "components/companies/companies-header";
import { ICompaniesState } from "components/companies/interface";
import CompaniesContainer from "components/companies/companies-container";

const Container = styled(Box)`
  display: flex;
  padding: 24px;
  border-radius: 8px;
  flex-direction: column;
  background-color: ${({ theme }) => theme.palette.grey[100]};
  margin-top: 40px;
`;

const initialCompaniesState: ICompaniesState = {
  companies_list: [],
  companyStatus: "ALL_COMPANIES",
  hasMore: true,
  page: 0,
  totalCompanies: 0,
  totalPages: 0,
  searchQuery: "",
  period: {
    start: null,
    end: null,
  },
};

const Companies = () => {
  const [companiesState, setCompaniesState] = React.useState<ICompaniesState>(
    initialCompaniesState
  );

  return (
    <Drawer menu="Companies">
      <Container
        sx={[
          (theme) => ({
            [theme.breakpoints.down("md")]: {
              height: "calc(65vh - 5px)",
            },
            [theme.breakpoints.up("xl")]: {
              height: "calc(86vh - 5px)",
            },
          }),
        ]}
      >
        <CompaniesHeader
          companiesState={companiesState}
          setCompaniesState={setCompaniesState}
        />
        <CompaniesContainer
          companiesState={companiesState}
          setCompaniesState={setCompaniesState}
        />
      </Container>
    </Drawer>
  );
};

export default Companies;
