import { AnyAction, createSlice } from "@reduxjs/toolkit";

import { doForgotPasswordAction } from "./forgot-password.actions";
import { IState } from "./interface";

const initialState: IState = {
  status: "",
  response: {},
  error: {},
};

export const authSlice = createSlice({
  name: "users/forgotPassword",
  initialState,
  reducers: {
    increment: () => {
      // state.candidateList = [1, 2];
      // ✅ CORRECT: logs a plain JS copy of the current data
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(doForgotPasswordAction.pending, (state: IState) => {
        state.status = "pending";
      })
      .addCase(
        doForgotPasswordAction.fulfilled,
        (state: IState, action: AnyAction) => {
          state.status = "fulfilled";
          state.response = action.payload;
        }
      )
      .addCase(
        doForgotPasswordAction.rejected,
        (state: IState, action: AnyAction) => {
          state.status = "rejected";
          state.response = {};
          state.error = action.error;
        }
      );
  },
});

export const { increment } = authSlice.actions;
export default authSlice.reducer;
