import { AsyncThunk, createAsyncThunk } from "@reduxjs/toolkit";
import { IUpdateCandidatePayload } from "./interface";
import { db, doc, setDoc } from "utils/firebase";

export const doUpdateCandidateAction: AsyncThunk<
  IUpdateCandidatePayload,
  IUpdateCandidatePayload,
  {}
> = createAsyncThunk("candidate/update", async (payload) => {
  const candidate_id = payload.candidate_id;
  const job_id = payload.job_id;

  const candidateRef = doc(db, `v2_candidates/${candidate_id}`);
  await setDoc(candidateRef, payload.candidate, { merge: true });

  if (payload.candidate.recruiter_notes) {
    const connectedCandidateRef = doc(
      db,
      `v2_jobs/${job_id}/connected_candidates/${candidate_id}`
    );
    await setDoc(
      connectedCandidateRef,
      { recruiter_notes: payload.candidate.recruiter_notes },
      { merge: true }
    );
  }

  return payload;
});
