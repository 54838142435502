import * as React from "react";
import PrivateLayout from "layouts/drawer";
import Approval from "components/pending-approval/Approval";

const PendingApproval = () => {
  return (
    <PrivateLayout menu="Pending Approval">
      <Approval />
    </PrivateLayout>
  );
};

export default PendingApproval;
