import React, { useState, ReactNode } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import { TabList, Tabs, Tab, TabPanel } from "react-tabs";
import { ITheme } from "theme";

import Faqs from "./faqs-source";

import Text from "components/shared/text/text";
import SimpleCardArea from "components/shared/card/simple-card-area";

import Arrow from "assets/images/faq-arrow.svg";
import DarkArrow from "assets/images/faq-dark-arrow.svg";

interface IProps {
  title: string;
  category?: string;
  description: ReactNode;
}

const FaqList = () => {
  const theme: ITheme = useTheme();
  const [faqs] = useState(Faqs);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <Tabs>
      <Grid container columns={12}>
        <Grid item xs={4}>
          <Box>
            <TabList>
              {faqs.map((faq: IProps) => (
                <Tab key={faq.title} onClick={scrollToTop}>
                  <SimpleCardArea className="active">
                    <Text
                      lineHeight="1"
                      sx={{
                        textAlign: "left",
                        marginLeft: 0,
                      }}
                      fontSize="18px"
                      color={theme.palette.grey[600]}
                    >
                      {faq.title}
                    </Text>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <img src={Arrow} alt="" className="light-arrow" />
                      <img src={DarkArrow} alt="" className="dark-arrow" />
                    </Box>
                  </SimpleCardArea>
                </Tab>
              ))}
            </TabList>
          </Box>
        </Grid>
        <Grid item xs={8}>
          {faqs.map((faq: IProps) => (
            <TabPanel key={faq.title}>
              <SimpleCardArea sx={{ cursor: "auto" }}>
                <Text
                  lineHeight="1"
                  sx={{
                    textAlign: "left",
                    marginBottom: "20px",
                    marginLeft: 0,
                  }}
                  fontSize="24px"
                  color={theme.palette.grey[600]}
                >
                  {faq.title}
                </Text>
                {faq.description}
              </SimpleCardArea>
            </TabPanel>
          ))}
        </Grid>
      </Grid>
    </Tabs>
  );
};

export default FaqList;
