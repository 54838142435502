import React from "react";
import { styled } from "@mui/material/styles";
import Avatar from "@mui/material/Avatar";
import { Box } from "@mui/material";
import {
  ICandidateInterview,
  IConnectedCandidate,
  IInterviewStatus,
  TCandidateUpdate,
} from "types/types";
import { formatCandidateDate } from "utils/helpers";
import Archive from "assets/images/archive.svg";
import Unarchive from "assets/images/unarchive.svg";
import Dialog from "components/dialog";
import { useAppDispatch } from "redux/store";
import { doUpdateCandidateInterviewAction } from "redux/services/candidates/update-interview-status/update.actions";
import { _first, _get } from "utils/lodash";

interface IProps {
  candidate: IConnectedCandidate;
  onClick: () => void;
  updated_by: TCandidateUpdate;
}

const Main = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const AvatarDescription = styled("div")`
  padding-left: 12px;
`;

const Text = styled("span")`
  font-weight: 600;
`;

const ArchiveContainer = styled(Box)`
  display: flex;
  align-items: center;
  &:hover {
    color: ${({ theme }) => theme.palette.grey[500]};
  },
`;

const AvatarContent = ({ candidate, updated_by, onClick }: IProps) => {
  const [openDialog, setOpenDialog] = React.useState(false);
  const handleOpenDialog = () => setOpenDialog(true);
  const handleCloseDialog = () => setOpenDialog(false);

  const dispatch = useAppDispatch();

  const handleAcceptDialog = () => {
    handleCloseDialog();

    const interview_status =
      candidate?.interview_status === IInterviewStatus.ARCHIVED
        ? IInterviewStatus.PENDING
        : IInterviewStatus.ARCHIVED;

    const updated_candidate: ICandidateInterview = {
      interview_status,
      reason: "",
      updated_by,
    };

    dispatch(
      doUpdateCandidateInterviewAction({
        job_id: candidate.job_id,
        candidate_id: candidate.candidate_id,
        updated_candidate,
      })
    );
  };

  const experiences = _first(_get(candidate, "candidate_experiences")) || {};
  const jobTitle = experiences.jobTitle || candidate.jobTitle;
  const companyName = experiences.companyName || candidate.currentCompany;
  const location = experiences.location || candidate.location;

  return (
    <Main>
      <Box
        onClick={onClick}
        sx={{
          display: "flex",
          marginTop: "9px",
        }}
      >
        <Avatar
          alt="Remy Sharp"
          src={candidate?.candidate_avatar_url}
          sx={{ width: 80, height: 80 }}
        />
        <AvatarDescription>
          <Text
            sx={{
              fontFamily: "Inter",
              fontSize: "15px",
              fontWeight: 700,
              lineHeight: "24px",
              letterSpacing: "-0.01em",
              textAlign: "left",
            }}
          >
            {candidate?.firstName} {candidate?.lastName}
          </Text>
          <div></div>
          <Text
            sx={{
              fontFamily: "Inter",
              fontSize: "13px",
              color: "#9A9FA5",
              fontWeight: 500,
              lineHeight: "16px",
              letterSpacing: "-1%",
              textAlign: "left",
            }}
          >
            {jobTitle || ""}
            {(
              <>
                {companyName ? " @ " : ""}
                {companyName}
              </>
            ) || ""}
          </Text>
          <div></div>
          <Text
            sx={{
              fontFamily: "Inter",
              fontSize: "13px",
              color: "#9A9FA5",
              fontWeight: 400,
              lineHeight: "16px",
              letterSpacing: "-1%",
              textAlign: "left",
            }}
          >
            {location || ""}
          </Text>
          <Text></Text>
        </AvatarDescription>
      </Box>
      <Box
        sx={{
          marginBottom: "18px",
          display: "flex",
        }}
      >
        <Box
          sx={{
            fontWeight: "normal",
            fontSize: "13px",
            color: "#9A9FA5",
          }}
        >
          {candidate?.interview_status === IInterviewStatus.ARCHIVED ? (
            <ArchiveContainer onClick={() => handleOpenDialog()}>
              Un-archive{" "}
              <img
                style={{
                  marginLeft: "4px",
                  marginRight: "4px",
                }}
                height="13.33px"
                width="13.33px"
                src={Unarchive}
                alt="Un-archive"
              />
            </ArchiveContainer>
          ) : (
            <ArchiveContainer onClick={() => setOpenDialog(true)}>
              Archive{" "}
              <img
                style={{
                  marginLeft: "4px",
                  marginRight: "4px",
                }}
                height="13.33px"
                width="13.33px"
                src={Archive}
                alt="Archive"
              />
            </ArchiveContainer>
          )}
        </Box>
        <Box
          sx={{
            fontWeight: "normal",
            fontSize: "12px",
            color: "#9A9FA5",
            marginLeft: "12px",
          }}
        >
          Added {formatCandidateDate(candidate?.created_at)}
        </Box>
      </Box>
      <Dialog
        openDialog={openDialog}
        isLoading={false}
        handleCloseDialog={handleCloseDialog}
        handleAcceptDialog={handleAcceptDialog}
        body={`Are you sure you want to
              ${
                candidate?.interview_status === IInterviewStatus.ARCHIVED
                  ? "unarchive"
                  : "archive"
              } ${candidate?.firstName} ${candidate?.lastName}?
            `}
      />
    </Main>
  );
};

export default AvatarContent;
