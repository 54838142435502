import { AsyncThunk, createAsyncThunk } from "@reduxjs/toolkit";
import { IPayload } from "./interface";
import { db, doc, getDoc } from "utils/firebase";
import { IUser } from "types/types";

export const doActionFetchUserAction: AsyncThunk<
  IUser | unknown,
  IPayload,
  {}
> = createAsyncThunk("users/fetch", async (payload: IPayload) => {
  if (payload.user) {
    return payload.user;
  }

  try {
    const clientRef = doc(db, `v2_recruiters/${payload.uid}`);
    const clientSnapshot = await getDoc(clientRef);

    const {
      firstName,
      lastName,
      email,
      role,
      uid,
      company_id,
      company_name,
      next_onboarding_stage,
      original_avatar_url,
      thumbnail_avatar_url,
      notifications,
      is_active,
    } = clientSnapshot.data() as IUser;

    return {
      firstName,
      lastName,
      email,
      role,
      uid,
      company_id,
      company_name,
      next_onboarding_stage,
      original_avatar_url,
      thumbnail_avatar_url,
      notifications,
      is_active,
    };
  } catch (error: unknown) {
    return error;
  }
});
