/* eslint-disable @typescript-eslint/no-explicit-any */
const styles = {
  container: {
    // border: "1px solid green",
    minWidth: "50%",
  },
  reactSelect: {
    control: (styles: any) => ({
      ...styles,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "#F4F4F4",
      border: styles.isFocused ? "2px solid green" : "2px solid transparent",
      // border: "2px solid transparent",
      borderRadius: "12px",
      minWidth: "6rem",
      boxShadow: styles.isFocused ? 0 : 0,
      cursor: "pointer",
      minHeight: "45px",
      "&:hover": {
        // border: "2px solid yellow",
      },
    }),
    valueContainer: (styles: any) => ({
      ...styles,
      flex: "none",
      padding: "0px",
    }),
    indicatorSeparator: (styles: any) => ({
      ...styles,
      display: "none",
    }),
    indicatorsContainer: (styles: any) => ({
      ...styles,
      padding: "0px",
      "& .react-select__indicator": {
        padding: "0px !important",
      },
    }),
    singleValue: (styles: any) => ({
      ...styles,
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "15px",
      lineHeight: "24px",
      color: "#6F767E",
    }),
    menu: (styles: any) => ({
      ...styles,
      marginTop: "-1px",
      boxShadow:
        "0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)",
      borderRadius: "4px",
    }),
    multiValue: (styles: any) => ({
      ...styles,
      background: "yellow",
    }),
    option: (styles: any) => {
      return {
        ...styles,
        cursor: "pointer",
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "15px",
        lineHeight: "24px",
        color: "#6F767E",
        "&:hover": {
          // backgroundColor: "maroon",
        },
        // "&:active": {
        //   backgroundColor: "pink",
        // },
      };
    },
  },
};

export default styles;
