/* eslint-disable comma-dangle */

import React from "react";
import PanelText from "./panel-text";

const FaqSource = [
  {
    title: "How does Connect work?",
    description: (
      <React.Fragment>
        <PanelText
          text="Connect helps fill your hiring pipelines by providing you with qualified and ready-to-interview
          candidates. With Connect, you can kick off new jobs by sharing requirements and the ideal candidate profile,
          then receive handpicked candidate profiles.
          "
        />
        <PanelText
          text="You can also choose to purchase ready-to-interview candidate credits and receive candidates
          that are not only sourced, reached out, but also already expressed their interest in having a first
          conversation about the role.
          "
        />
        <PanelText
          text="You can integrate your ATS to seamlessly import the candidates you'd like to interview,
          and engage with them them further in your hiring process. You can integrate Slack to receive
          updates directly when new candidates are added to your jobs.
          "
        />
      </React.Fragment>
    ),
  },
  {
    title: "How can I receive candidates on Connect?",
    description: (
      <React.Fragment>
        <PanelText
          text="If you created a company on Connect and have already added your open jobs,
        then you will receive 3 free uncontacted candidate profiles within 1-2 business days."
        />
        <PanelText
          text="If you purchased ready-to-interview candidate credits, our dedicated
        recruiter team will schedule a kick-off call with you, conduct dedicated searches for your jobs,
        then upload the interested candidates who are ready to have a first interview with you under each job.
        If you purchased credits but have not been contacted about a kick-off call, please reach out to our team
        using the customer support chat at the bottom right of your screen or to Cátia Barbosa at catia@acelr8.com."
        />
      </React.Fragment>
    ),
  },
  {
    title: "How does Connect find candidates?",
    description: (
      <React.Fragment>
        <PanelText
          text="Based on the job requirements and ideal candidate profile you have described,
          our dedicated recruiters will go through a variety of networks to find the candidates
          you are looking for, including Connect's own talent pool as well as social media websites.
          "
        />
        <PanelText
          text="If you have purchased ready-to-interview candidate credits,
          our recruiters will not only find these candidates but also reach out to them,
          pitch your company and open position, and upload the candidates who are interested
          in having a first interview to Connect.
          "
        />
      </React.Fragment>
    ),
  },
  {
    title: `Many recruiters don't mention the company's name when reaching out to candidates.
      Is that the case with Connect?`,
    description: (
      <React.Fragment>
        <PanelText
          text="No. For the ready-to-interview candidates that we deliver, our dedicated recruiter team
        always makes sure to pitch your company directly and won't hide the name of your company."
        />
      </React.Fragment>
    ),
  },
  {
    title:
      "My colleague also needs to review candidates. How can I invite them?",
    description: (
      <React.Fragment>
        <PanelText
          text="You can go to “My Company” on the navigation bar on the left hand side,
          and scroll down to “Teams & Access”. You can then input your colleague's work email,
          click on “Send”, and an invite will be sent to them. Then your colleague can create a
          Connect account and directly join the same company and review candidates under each job.
          "
        />
        <PanelText
          text="If an invite email was already sent but your colleague could not find the email,
          you can click on “Copy invite link” and simply paste the link to them.
          "
        />
      </React.Fragment>
    ),
  },
  {
    title:
      "I received some candidates but they weren't what I'm looking for. How do I update my requirements?",
    description: (
      <React.Fragment>
        <PanelText
          text="It's completely normal to tweak requirements and ideal candidate profiles as we explore our needs
          and the market in every hiring process. On Connect, you can go to “Connect” on the navigation bar on the
          left hand side, then find the job whose requirements you'd like to update. Click on the “…” button on the
          top right corner of the job card. Then click on “Edit”. You can then update the fields accordingly, and once
          you're done, don't forget to click on “Finish” on the last page to save your updates. Our team will be
          notified as soon as you update the job requirements and ideal candidate profile.
          "
        />
      </React.Fragment>
    ),
  },
  {
    title: "What happens after I choose to interview a candidate?",
    description: (
      <React.Fragment>
        <PanelText
          text="That's great news! If you have purchased ready-to-interview candidate credits, our dedicated recruiters
          will communicate with you about scheduling the next interview steps, whether that's through the recruiters
          sending a scheduling link to the candidates or introduce you to the candidate to schedule directly.
          "
        />
        <PanelText
          text="If you haven't purchased ready-to-interview candidates, you can reach out to the uncontacted
        candidates directly and invite them to have a first interview."
        />
      </React.Fragment>
    ),
  },
];

export default FaqSource;
