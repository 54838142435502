import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IGetCompanyPayload, IState } from "./interface";
import { doGetCompaniesAction } from "./get.actions";
import { ReduxStatus } from "types/types";

const initialResponse: IGetCompanyPayload = {
  companies: [],
  companyStatus: "ALL_COMPANIES",
  hasMore: true,
  page: 0,
  totalCompanies: 0,
  totalPages: 0,
  searchQuery: "",
  period: {
    start: null,
    end: null,
  },
  companyAdded: undefined,
};

const initialState: IState = {
  status: ReduxStatus.empty,
  response: initialResponse,
  error: {},
};

export const getCompanies = createSlice({
  name: "companies/get",
  initialState,
  reducers: {
    increment: () => {},
  },
  extraReducers: (builder) => {
    builder
      .addCase(doGetCompaniesAction.pending, (state: IState) => {
        state.status = ReduxStatus.pending;
      })
      .addCase(
        doGetCompaniesAction.fulfilled,
        (state: IState, action: PayloadAction<IGetCompanyPayload>) => {
          state.status = ReduxStatus.fulfilled;
          state.error = {};
          state.response = action.payload;
        }
      )
      .addCase(
        doGetCompaniesAction.rejected,
        (state: IState, action: PayloadAction<unknown>) => {
          state.status = ReduxStatus.rejected;
          state.response = initialResponse;
          state.error = action.payload;
        }
      );
  },
});

export const { increment } = getCompanies.actions;
export default getCompanies.reducer;
