import * as React from "react";
import Box from "@mui/material/Box";
import { styled, useTheme } from "@mui/material/styles";
import SelectComponent from "../shared/select/select-component";
import DateRangePicker from "../shared/daterangepicker";
import CalendarTodayIconOutlined from "@mui/icons-material/CalendarTodayOutlined";
import SelectButton from "../job/select-button";
import { ITheme } from "theme";
import { ICompaniesState, TypeCompanyStatus } from "./interface";
import SearchIcon from "assets/images/search-light-icon.svg";
import Input from "components/shared/input/input";
import { debounce } from "lodash";
import { RootState, useAppDispatch } from "redux/store";
import { useSelector } from "react-redux";
import { doGetCompaniesAction } from "redux/services/companies/get/get.actions";

const CompanyOptions = [
  { value: "ALL_COMPANIES", label: "All Companies" },
  { value: "MY_COMPANIES", label: "My Companies" },
];

const HeaderContainer = styled(Box)`
  margin-bottom: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
interface IProps {
  companiesState: ICompaniesState;
  setCompaniesState: Function;
}

const CompaniesHeader = ({ setCompaniesState }: IProps) => {
  const theme: ITheme = useTheme();
  const [searchQuery, setSearchQuery] = React.useState("");
  const [startDate, setStartDate] = React.useState<Date | null>(new Date());
  const [endDate, setEndDate] = React.useState<Date | null>(null);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const dispatch = useAppDispatch();
  const companiesGetState = useSelector(
    (reduxState: RootState) => reduxState.getCompanies.response
  );

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const button_id = open ? "calendar-popover" : undefined;
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onDateRangeChange = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    start?.setHours(0, 0, 0, 0);
    end?.setHours(23, 59, 59, 999);

    setStartDate(start);
    setEndDate(end);

    if (
      start?.toISOString() === companiesGetState.period?.start &&
      end?.toISOString() === companiesGetState.period?.end
    ) {
      handleClose();
      return;
    }

    if (end) {
      handleClose();
      setCompaniesState((st: ICompaniesState) => ({
        ...st,
        page: 0,
        hasMore: true,
        company_list: [],
        period: {
          start,
          end,
        },
      }));

      return dispatch(
        doGetCompaniesAction({
          ...companiesGetState,
          page: 0,
          hasMore: true,
          companies: [],
          period: {
            start: start?.toISOString(),
            end: end.toISOString(),
          },
        })
      );
    }
  };

  const clearDates = async () => {
    if (endDate === null) {
      handleClose();
      return;
    }

    setStartDate(null);
    setEndDate(null);
    setCompaniesState((st: ICompaniesState) => ({
      ...st,
      page: 0,
      hasMore: true,
      company_list: [],
      period: {
        start: null,
        end: null,
      },
    }));

    await dispatch(
      doGetCompaniesAction({
        ...companiesGetState,
        page: 0,
        hasMore: true,
        companies: [],
        period: {
          start: null,
          end: null,
        },
      })
    );
    handleClose();
  };

  const handleFilterByStatus = async (options: {
    label: string;
    value: string;
  }) => {
    if (options.value === companiesGetState.companyStatus) {
      return;
    }

    setCompaniesState((st: ICompaniesState) => ({
      ...st,
      page: 0,
      hasMore: true,
      company_list: [],
      companyStatus: options.value,
    }));

    return dispatch(
      doGetCompaniesAction({
        ...companiesGetState,
        page: 0,
        hasMore: true,
        companies: [],
        companyStatus: options.value as TypeCompanyStatus,
      })
    );
  };

  const setQuery = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCompaniesState((st: ICompaniesState) => ({
      ...st,
      page: 0,
      hasMore: true,
      company_list: [],
      searchQuery: event.target.value,
    }));

    return dispatch(
      doGetCompaniesAction({
        ...companiesGetState,
        page: 0,
        hasMore: true,
        companies: [],
        searchQuery: event.target.value,
      })
    );
  };

  const handleInputChange = React.useCallback(
    debounce((e) => setQuery(e), 1000),
    [
      companiesGetState.companyStatus,
      companiesGetState?.period?.start,
      companiesGetState?.period?.end,
    ]
  );

  const handleQueryChange = (event: any) => {
    setSearchQuery(event.target.value);
    handleInputChange(event);
  };

  const defaultStatus = companiesGetState.companyStatus
    ? CompanyOptions.find((op) => op.value === companiesGetState.companyStatus)
    : CompanyOptions[0];

  React.useEffect(() => {
    setSearchQuery(companiesGetState.searchQuery || "");
    const start = companiesGetState.period?.start as string;
    const end = companiesGetState.period?.end as string;
    if (start && end) {
      setStartDate(new Date(start));
      setEndDate(new Date(end));
    }
  }, []);

  return (
    <HeaderContainer>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            width: "16px",
            height: "32px",
            margin: "0px 16px 0px 0px",
            background: theme?.custom.colors.green,
            borderRadius: "4px",
          }}
        />
        <Box
          sx={{ width: "200px", display: "inline-flex", marginLeft: "15px" }}
        >
          <SelectComponent
            handleChange={handleFilterByStatus}
            options={CompanyOptions}
            defaultValue={defaultStatus}
          />
        </Box>
        <Box
          sx={{
            marginLeft: "16px",
            borderRadius: "4px",
          }}
        >
          <Input
            iconStart={<img src={SearchIcon} alt="" />}
            fullWidth
            type="text"
            id="searchQuery"
            register={() => {}}
            sx={{
              mx: "auto",
              width: "360px",
              textAlign: "left",
            }}
            placeholder="Search companies"
            variant="filled"
            onChange={handleQueryChange}
            value={searchQuery}
          />
        </Box>
      </Box>
      <Box>
        <SelectButton
          sx={{
            background: "#fcfcfc",
          }}
          onClick={handleOpen}
          aria-describedby={button_id}
        >
          {startDate && endDate
            ? `${new Date(startDate).toLocaleDateString()} - ${new Date(
                endDate
              ).toLocaleDateString()}`
            : "Date"}
          <CalendarTodayIconOutlined
            sx={{
              ml: "5px",
              height: "15px",
              width: "15px",
              left: "1.5px",
              top: "1.5px",
              color: " #6f767e",
            }}
          />
        </SelectButton>
      </Box>
      <DateRangePicker
        handleClose={handleClose}
        open={open}
        onChange={onDateRangeChange}
        anchorEl={anchorEl}
        startDate={startDate}
        endDate={endDate}
        clearDates={clearDates}
      />
    </HeaderContainer>
  );
};

export default CompaniesHeader;
