import { IJobStatus } from "types/types";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

interface CardProps {
  jobStatus?: IJobStatus;
}

const JobCard = styled(Box)<CardProps>`
  border: 3px solid ${({ theme }) => theme.palette.grey[800]};
  cursor: pointer;
  height: 229px;
  display: flex;
  padding: 20px;
  transition: all 0.2s ease;
  border-radius: 12px;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${({ theme, jobStatus }) => {
    if (jobStatus === IJobStatus.ARCHIVE) {
      return theme.palette.grey[300];
    }
    return theme.palette.grey[50];
  }};
  position: relative;
  &:focus,
  &:hover {
    border: 3px solid #d8d8d8;
  }
  &.new-job {
    padding-top: 5rem;
    background-color: #efefef;
  }
`;

export default JobCard;
