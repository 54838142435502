import { AsyncThunk, createAsyncThunk } from "@reduxjs/toolkit";
import { IGetCandidatesPayload } from "./interface";
import { db, collection, getDocs } from "utils/firebase";
import { IConnectedCandidate } from "types/types";

export const doGetCandidatesAction: AsyncThunk<
  IGetCandidatesPayload,
  IGetCandidatesPayload,
  {}
> = createAsyncThunk("candidates/get", async (payload, { rejectWithValue }) => {
  if (payload.candidates) {
    return payload;
  }

  try {
    const candidatesRef = collection(
      db,
      `v2_jobs/${payload.job_id}/connected_candidates`
    );
    const candidatesSnapshot = await getDocs(candidatesRef);
    const candidates = candidatesSnapshot.docs.map((doc) => {
      let candidate = doc.data() as IConnectedCandidate;
      candidate = {
        ...candidate,
        created_at: candidate.created_at?.seconds * 1000,
        updated_at: candidate.updated_at?.seconds * 1000,
      };
      return candidate;
    });

    const newPayload: IGetCandidatesPayload = { ...payload, candidates };

    return newPayload;
  } catch (error) {
    return rejectWithValue(JSON.stringify(error));
  }
});
