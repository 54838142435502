// import { styled } from "@mui/material/styles";
import React, { FC, ReactNode, useState } from "react";
import SideMenu from "components/navigation/side-menu";
import TopMenu from "components/navigation/top-menu";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material";
import { ITheme } from "theme";
import { ROUTE_ACTIVE_JOBS, ROUTE_COMPANIES } from "routes/route-items";

interface IProps {
  children: ReactNode;
  menu?: string;
}

const PrivateLayout: FC<IProps> = React.memo(({ children, menu }) => {
  const theme: ITheme = useTheme();
  const [expandDrawer, setExpandDrawer] = useState(() => {
    const stickyValue = window.localStorage.getItem("expand_drawer");
    return stickyValue !== null
      ? JSON.parse(stickyValue)
      : window.localStorage.setItem("expand_drawer", "false");
  });

  const handleDrawerExpansion = () => {
    if (expandDrawer == true) {
      setExpandDrawer(false);
      window.localStorage.setItem("expand_drawer", "false");
    } else {
      setExpandDrawer(true);
      window.localStorage.setItem("expand_drawer", "true");
    }
  };
  // const LayoutContainer = styled("div")`
  //   min-height: 100vh;
  //   width: 100%;
  //   display: flex;
  //   background-color: ${({ theme }) => theme.palette.grey[900]};
  //   font-family: Inter;
  // `;

  const hasScrollableDiv =
    location.pathname === `/${ROUTE_ACTIVE_JOBS}` ||
    location.pathname === `/${ROUTE_COMPANIES}`;

  return (
    <Box
      sx={{
        ...(hasScrollableDiv
          ? {
              maxHeight: "100vh",
            }
          : {
              minHeight: "100vh",
            }),
        width: "100%",
        display: "flex",
        backgroundColor: theme.palette.grey[900],
      }}
    >
      <TopMenu drawerWidth={!expandDrawer ? 103 : 265} menu={menu} />
      <SideMenu
        drawerWidth={!expandDrawer ? 103 : 265}
        resizeDrawer={handleDrawerExpansion}
      />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 10,
          ...(hasScrollableDiv
            ? {
                paddingTop: "85px",
              }
            : {
                paddingTop: "96px",
              }),
        }}
      >
        {children}
      </Box>
    </Box>
  );
});

PrivateLayout.displayName = "PrivateLayout";
export default PrivateLayout;
