import BackgroundImg from "assets/images/background.png";
import Logo from "assets/images/logo.png";

const styles = {
  layout_container: {
    minHeight: "100vh",
    width: "100%",
  },
  layout_app_title: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "18px",
    lineHeight: "32px",
    textAlign: "center",
    letterSpacing: "-0.02em",
  },
  layout_side: {
    backgroundImage: `url(${BackgroundImg})`,
    backgroundPosition: "initial",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  layout_logo: {
    backgroundImage: `url(${Logo})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    height: "48px",
    width: "48px",
  },
};

export default styles;
