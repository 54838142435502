import { AsyncThunk, createAsyncThunk } from "@reduxjs/toolkit";
import { ICompanyUpdate, IUpdateCompanyPayload } from "./interface";
import { db, doc, serverTimestamp, setDoc } from "utils/firebase";

export const doUpdateCompanyAction: AsyncThunk<
  ICompanyUpdate,
  IUpdateCompanyPayload,
  {}
> = createAsyncThunk("company/update", async (payload) => {
  const companyRef = doc(db, `v2_company/${payload.company_id}`);
  const companyData = { ...payload.company, updated_at: serverTimestamp() };
  await setDoc(companyRef, companyData, { merge: true });

  return payload.company;
});
