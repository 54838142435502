import React, { MouseEventHandler, useState } from "react";
import Box from "@mui/material/Box";
import { styled } from "@mui/system";
import Input from "components/shared/input/input";
import Button from "@mui/material/Button";
import Union from "assets/images/Union-Black.svg";
import Form from "components/shared/form/form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { JobCandidateProfileSchema } from "utils/form-schema";
import SharedButton from "components/shared/button/button";
import BackArrow from "assets/images/arrow_backward.svg";
import { _get as get } from "utils/lodash";
import { boxContainer, FormLabel } from "./shared";
import { Asterisk } from "components/shared/asterisk";
import SharedTooltip from "../shared/tooltip/tooltip";
import InfoIcon from "@mui/icons-material/Info";
import { useTheme } from "@mui/styles";
import { ITheme } from "theme";

const FormContainer = styled(Box)`
  margin-bottom: 32px;
`;

const AddIcon = styled("img")`
  margin-right: 15px;
`;

const ModifiedTextButton = styled(Button)`
  font-family: "Inter";
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: #33383f;
  text-transform: none;
`;
/* eslint-disable */
interface IProps {
  handleSetState: Function;
  handleBack: MouseEventHandler;
  // eslint-disable-next-line
  handleSaveProfile: (data: any) => void;
  state: {
    step_2: {
      skills_must_have: { [key: string]: string };
      skills_nice_to_have: { [key: string]: string };
      skills_not_to_have: { [key: string]: string };
    };
  };
}

interface IState {
  "must-have": number[];
  "nice-to-have": number[];
  "failed-to-have": number[];
}

const initialState = {
  "must-have": [1, 2, 3],
  "nice-to-have": [1],
  "failed-to-have": [1],
};

const PageTwo: React.FC<IProps> = ({
  handleSaveProfile,
  handleBack,
  handleSetState,
  state,
}) => {
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(JobCandidateProfileSchema),
  });

  const [moreInputs, setMoreInputs] = useState<IState>(initialState);

  const addMore = (title: string) => {
    setMoreInputs((prevState) => {
      const prev = get(prevState, title, []);
      return {
        ...prevState,
        [title]: prev.concat(prev[prev.length - 1] + 1),
      };
    });
  };
  const theme: ITheme = useTheme();

  React.useEffect(() => {
    Object.keys(state.step_2.skills_must_have).map((el: string) => {
      setValue(`${el}_skills_must_have`, state.step_2.skills_must_have[el]);
      setMoreInputs((prevState) => {
        const prev = get(prevState, "must-have", []);
        if (prev[prev.length - 1] < Number(el) + 1) {
          return {
            ...prevState,
            "must-have": prev.concat(Number(el) + 1),
          };
        } else {
          return {
            ...prevState,
          };
        }
      });
    });

    Object.keys(state.step_2.skills_nice_to_have).map((el: string) => {
      setValue(
        `${el}_skills_nice_to_have`,
        state.step_2.skills_nice_to_have[el]
      );
      setMoreInputs((prevState) => {
        const prev = get(prevState, "nice-to-have", []);
        if (prev[prev.length - 1] < Number(el) + 1) {
          return {
            ...prevState,
            "nice-to-have": prev.concat(Number(el) + 1),
          };
        } else {
          return {
            ...prevState,
          };
        }
      });
    });

    Object.keys(state.step_2.skills_not_to_have).map((el: string) => {
      setValue(`${el}_skills_not_to_have`, state.step_2.skills_not_to_have[el]);
      setMoreInputs((prevState) => {
        const prev = get(prevState, "failed-to-have", []);
        if (prev[prev.length - 1] < Number(el) + 1) {
          return {
            ...prevState,
            "failed-to-have": prev.concat(Number(el) + 1),
          };
        } else {
          return {
            ...prevState,
          };
        }
      });
    });
  }, [state.step_2]);

  return (
    <Box>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(handleSaveProfile)(e);
        }}
      >
        <FormContainer>
          <Box sx={boxContainer}>
            <FormLabel error={get(errors, "0_skills_must_have.message", null)}>
              The “must-have” skills or experiences in an ideal candidate:
            </FormLabel>
            <Asterisk />
            <SharedTooltip title="Describe 3-5 technical skills, organizational skills or specific work experiences that are most important to the job.">
              <InfoIcon
                sx={{
                  color: `${
                    get(errors, "0_skills_must_have.message", null)
                      ? theme.palette.error.main
                      : theme.palette.text.primary
                  }`,
                  height: "19.33px",
                  ml: "5px",
                }}
              />
            </SharedTooltip>
          </Box>
          <Box>
            {get(moreInputs, "must-have", []).map((_: never, index: number) => {
              return (
                <Input
                  id={`${index}_skills_must_have`}
                  key={`${index}_skills_must_have`}
                  name={`${index}_skills_must_have`}
                  register={register}
                  onChange={() => {}}
                  errors={get(errors, `${index}_skills_must_have.message`, null)}
                  variant="filled"
                  placeholder="e.g Can oversee asteroid extraction missions"
                  errorSx={{
                    top: "5px"
                  }}
                  sx={{
                    marginBottom: "10px",
                  }}
                />
              );
            })}
            <Box>
              <ModifiedTextButton
                variant="text"
                onClick={() => addMore("must-have")}
              >
                <AddIcon alt="Add icon" src={Union} /> <span>Add more</span>
              </ModifiedTextButton>
            </Box>
          </Box>
        </FormContainer>
        <FormContainer>
          <Box sx={boxContainer}>
            <FormLabel>
              The “nice-to-have” skills or experiences in an ideal candidate:
            </FormLabel>
            <SharedTooltip title="Describe 1-2 technical skills, organizational skills or experiences that will set a great candidate apart.">
              <InfoIcon
                sx={{
                  color: theme.palette.text.primary,
                  height: "19.33px",
                  ml: "5px",
                }}
              />
            </SharedTooltip>
          </Box>
          <Box>
            {get(moreInputs, "nice-to-have", []).map(
              (_: never, index: number) => {
                return (
                  <Input
                    name={`${index}_skills_nice_to_have`}
                    id={`${index}_skills_nice_to_have`}
                    key={`${index}_skills_nice_to_have`}
                    register={register}
                    variant="filled"
                    placeholder="e.g Can prepare mission reports"
                    sx={{
                      marginBottom: "10px",
                    }}
                  />
                );
              }
            )}
            <Box>
              <ModifiedTextButton
                variant="text"
                onClick={() => addMore("nice-to-have")}
              >
                <AddIcon alt="Add icon" src={Union} /> Add more
              </ModifiedTextButton>
            </Box>
          </Box>
        </FormContainer>
        <FormContainer>
          <Box sx={boxContainer}>
            <FormLabel>
              Candidates may not be a good fit if they are looking for:
            </FormLabel>
            <SharedTooltip title="If needed, describe 1-2 skills, experiences or motivations in candidates that might be less relevant for the job.">
              <InfoIcon
                sx={{
                  color: theme.palette.text.primary,
                  height: "19.33px",
                  ml: "5px",
                }}
              />
            </SharedTooltip>
          </Box>
          <Box>
            {get(moreInputs, "failed-to-have", []).map(
              (_: never, index: number) => {
                return (
                  <Input
                    name={`${index}_skills_not_to_have`}
                    id={`${index}_skills_not_to_have`}
                    key={`${index}_skills_not_to_have`}
                    register={register}
                    variant="filled"
                    placeholder="e.g If they expect to work within a large (5+ teammates) team"
                    sx={{
                      marginBottom: "10px",
                    }}
                  />
                );
              }
            )}
            <Box>
              <ModifiedTextButton
                variant="text"
                onClick={() => addMore("failed-to-have")}
              >
                <AddIcon alt="Add icon" src={Union} /> Add more
              </ModifiedTextButton>
            </Box>
          </Box>
        </FormContainer>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            pt: 2,
            alignItems: "center",
          }}
        >
          <SharedButton
            variant="outlined"
            onClick={handleBack}
            sx={{
              width: "auto",
              "&.Mui-disabled": {
                display: "none",
              },
            }}
          >
            <img src={BackArrow} alt="back arrow" />
            <Box sx={{ marginLeft: "6px", textTransform: "initial" }}>Back</Box>
          </SharedButton>
          <Box
            display="flex"
            justifyContent="flex-end"
            sx={{ flex: "1 1 auto" }}
          >
            <SharedButton
              variant="contained"
              type="submit"
              sx={{ width: "35%" }}
            >
              <Box sx={{ marginLeft: "6px", textTransform: "initial" }}>
                Continue
              </Box>
            </SharedButton>
          </Box>
        </Box>
      </Form>
    </Box>
  );
};

export default PageTwo;
