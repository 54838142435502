import { createSlice } from "@reduxjs/toolkit";
import { IUpdateCandidatePayload, IUpdateCandidateState } from "./interface";
import { doUpdateCandidateAction } from "./update-candidate.actions";
import { ReduxStatus } from "types/types";

const initialResponse: IUpdateCandidatePayload = {
  job_id: "",
  candidate_id: "",
  candidate: {},
};
const initialState: IUpdateCandidateState = {
  status: ReduxStatus.empty,
  response: initialResponse,
  error: {},
};

export const updateCandidate = createSlice({
  name: "candidate/update",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(doUpdateCandidateAction.pending, (state) => {
        state.status = ReduxStatus.pending;
      })
      .addCase(doUpdateCandidateAction.fulfilled, (state, action) => {
        state.status = ReduxStatus.fulfilled;
        state.error = {};
        state.response = action.payload;
      })
      .addCase(doUpdateCandidateAction.rejected, (state, action) => {
        state.status = ReduxStatus.rejected;
        state.response = initialResponse;
        state.error = action.error;
      });
  },
});

export default updateCandidate.reducer;
