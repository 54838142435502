import { ROUTE_ACTIVE_JOBS } from "routes/route-items";
import { IUser, IRoleType } from "types/types";
import { config } from "config";

/* eslint-disable comma-dangle */
export const IndustryTypes: string[] = [
  "Gaming",
  "Health",
  "Travel",
  "Legal",
  "Security",
  "Fintech",
  "Wellness Beauty",
  "Music",
  "Real Estate",
  "Fashion",
  "Sports",
  "Food",
  "Media",
  "Dating",
  "Telecom",
  "Education",
  "Energy",
  "Kids",
  "Hosting",
  "Home Living",
  "Event Tech",
  "Robotics",
  "Jobs Recruitment",
  "Transportation",
  "Semiconductors",
  "Marketing",
  "Enterprise Software",
];

export const seniority_level = {
  Junior: {
    title: "Junior",
    selected: false,
  },
  "Mid-level": {
    title: "Mid-level",
    selected: false,
  },
  Senior: {
    title: "Senior",
    selected: false,
  },
  Lead: {
    title: "Lead",
    selected: false,
  },
  "Head of": {
    title: "Head of",
    selected: false,
  },
};

export const job_function = {
  "Software Engineering": {
    title: "Software Engineering",
    selected: false,
  },
  Marketing: {
    title: "Marketing",
    selected: false,
  },
  "Product Design": {
    title: "Product Design",
    selected: false,
  },
  "Product Management": {
    title: "Product Management",
    selected: false,
  },
  "Customer Success": {
    title: "Customer Success",
    selected: false,
  },
  Sales: {
    title: "Sales",
    selected: false,
  },
  Other: {
    title: "Other",
    selected: false,
  },
};

export const clientMail = config.REACT_APP_CLIENT_EMAIL || "catia@acelr8.com";

// eslint-disable-next-line
export const emailRegex =
  /^([\w-.]+@(?!gmail.com)(?!yahoo.com)(?!hotmail.com)(?!yahoo.co.in)(?!aol.com)(?!abc.com)(?!xyz.com)(?!pqr.com)(?!rediffmail.com)(?!live.com)(?!outlook.com)(?!me.com)(?!msn.com)(?!ymail.com)([\w-]+\.)+[\w-]{2,8})?$/;

export const TOTAL_INTEGRATIONS = 1;

export const initialUser: IUser = {
  firstName: "",
  lastName: "",
  email: "",
  created_at: null,
  uid: "",
  role: IRoleType.RECRUITER,
  company_name: "",
  company_id: "",
  original_avatar_url: "",
  thumbnail_avatar_url: "",
  accepted_terms: false,
  next_onboarding_stage: ROUTE_ACTIVE_JOBS,
  is_active: false,
  authed_user_id: "",
  slack_access_token: "",
  slack_app_id: "",
  slack_user_access_token: "",
  integrations: {
    Slack: {
      selected: false,
    },
    ATS: {
      selected: false,
    },
  },
  notifications: {
    email: {},
    slack: {},
  },
};
