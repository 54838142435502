import React from "react";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import HR from "../shared/horizontal-rule";
import AvatarContent from "./avatar-content";
import CandidateStatus from "./candidate-status";
import InterviewNotes from "./interview-reasons";
import {
  IConnectedCandidate,
  IInterviewStatus,
  TCandidateUpdate,
} from "types/types";

interface IProps {
  candidate: IConnectedCandidate;
  updated_by: TCandidateUpdate;
  onClick: () => void;
}

interface ICandidateRowProps {
  candidate: IConnectedCandidate;
}

const CandidateRow = styled(Box)<ICandidateRowProps>`
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
  border-radius: 8px;
  &:hover {
    background: #f4f4f4;
  }
  background-color: ${({ theme, candidate }) => {
    if (candidate?.interview_status === IInterviewStatus.ARCHIVED) {
      return theme.palette.grey[300];
    }
    return theme.palette.grey[50];
  }};
`;

const CandidateColumn = styled(Box)`
  padding: 12px;
`;

const Candidate = ({ candidate, updated_by, onClick }: IProps) => {
  return (
    <Box>
      <CandidateRow candidate={candidate}>
        <CandidateColumn
          sx={{
            width: "30%",
            cursor: "pointer",
          }}
        >
          <AvatarContent
            onClick={onClick}
            candidate={candidate}
            updated_by={updated_by}
          ></AvatarContent>
        </CandidateColumn>
        <CandidateColumn
          onClick={onClick}
          sx={{
            width: "40%",
            cursor: "pointer",
          }}
        >
          <InterviewNotes candidate={candidate} />
        </CandidateColumn>
        <CandidateColumn
          sx={{
            width: "30%",
          }}
        >
          <CandidateStatus candidate={candidate} updated_by={updated_by} />
        </CandidateColumn>
      </CandidateRow>
      <HR></HR>
    </Box>
  );
};

export default Candidate;
